import { useLazyQuery } from '@apollo/client';
import { PARTICIPANTS_QUERY } from '@fe-monorepo/data-access';

import { GetParticipantInput, GetParticipantsParams, GetParticipantsResponse } from './type';

export const useGetParticipants = () => {
  const [getParticipantsGql, { data: getParticipantsData, error, loading: isLoading }] = useLazyQuery<
    GetParticipantsResponse,
    GetParticipantsParams
  >(PARTICIPANTS_QUERY.getAll, { errorPolicy: 'all' });

  const getParticipants = async (details: GetParticipantInput) => {
    const response = await getParticipantsGql({ variables: { details } });
    return response;
  };

  return {
    getParticipants,
    getParticipantsData: getParticipantsData?.tmsParticipantGetParticipants,
    isLoading,
    error,
  };
};