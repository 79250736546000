import { useTranslate } from '@fe-monorepo/hooks';
import React from 'react';

import { parseDescription } from '../helpers';

export default function VideoDescription({ video, showComments, showMore, setShowMore, hashtags }: any) {
  const { title, description, taggedUsers } = video ?? {};
  const parsedDescription = parseDescription(description, taggedUsers, hashtags);
  const { translate } = useTranslate();

  const renderDescription = () => {
    if (typeof parsedDescription === 'string') {
      // Directly handle string case for show more/less
      return (
        <>
          {showMore ? parsedDescription : parsedDescription.substring(0, 100)}
          {parsedDescription.length > 100 && (
            <button className="text-b show-more-content" onClick={() => setShowMore((prev: any) => !prev)}>
              {showMore ? ` ${translate('quickplay.label-show-less')}` : ` ${translate('quickplay.label-show-more')}`}
            </button>
          )}
        </>
      );
    } else if (Array.isArray(parsedDescription)) {
      // Separate handling for array case (string + React elements)
      const stringPart = parsedDescription.filter(item => typeof item === 'string').join(' ');
      const reactElements = parsedDescription.filter(item => React.isValidElement(item));

      return (
        <>
          {stringPart && (
            <>
              {showMore ? stringPart : stringPart.substring(0, 100)}
              {stringPart.length > 100 && (
                <button className="text-b show-more-content" onClick={() => setShowMore((prev: any) => !prev)}>
                  {showMore ? ` ${translate('quickplay.label-show-less')}` : ` ${translate('quickplay.label-show-more')}`}
                </button>
              )}
            </>
          )}
          {reactElements.length > 0 && reactElements.map((item, index) => <React.Fragment key={index}>{item}</React.Fragment>)}
        </>
      );
    }

    return ''; // Handle empty or undefined parsedDescription
  };

  return (
    <div className={`post_desc ${showComments ? 'pr_6' : ''}`}>
      <h5 className="title">{title}</h5>
      <p className="desc">{renderDescription()}</p>
    </div>
  );
}
