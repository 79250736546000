import { useLazyQuery } from '@apollo/client';
import { COMMUNITY_FOLLOW_QUERIES } from '@fe-monorepo/data-access';

import { GetFollowInfoParams, GetFollowersInfoResponse, GetFollowingInfoResponse, LookupResponse } from './types';

export const useGetFollowInfo = () => {
  const [getFollowersInfoGql, { data: getFollowersResponse }] = useLazyQuery<GetFollowersInfoResponse>(
    COMMUNITY_FOLLOW_QUERIES.getFollowers,
    {
      errorPolicy: 'all',
    },
  );

  const [getFollowingsInfoGql, { data: getFollowingsResponse }] = useLazyQuery<GetFollowingInfoResponse>(
    COMMUNITY_FOLLOW_QUERIES.getFollowings,
    {
      errorPolicy: 'all',
    },
  );

  const [lookupGQL, { data: lookupResponse }] = useLazyQuery<LookupResponse>(COMMUNITY_FOLLOW_QUERIES.lookup, {
    errorPolicy: 'all',
  });

  const getFollowers = async (infoFollowersResult: GetFollowInfoParams) => {
    const { data } = await getFollowersInfoGql({
      variables: {
        details: infoFollowersResult,
      },
    });
    return data?.getFollowers;
  };

  const getFollowings = async (infoFollowingResult: GetFollowInfoParams) => {
    const { data } = await getFollowingsInfoGql({
      variables: {
        details: infoFollowingResult,
      },
    });
    return data?.getFollowings;
  };

  const lookup = async (lookupResult: GetFollowInfoParams) => {
    const { data } = await lookupGQL({
      variables: {
        details: lookupResult,
      },
    });
    return data?.lookup;
  };

  return {
    getFollowers,
    getFollowings,
    lookup,
    getFollowersData: getFollowersResponse?.getFollowers,
    getFollowingsData: getFollowingsResponse?.getFollowings,
    lookupData: lookupResponse?.lookup,
    getFollowersErrorMessage: getFollowersResponse?.getFollowers?.error_msg,
    getFollowingsErrorMessage: getFollowingsResponse?.getFollowings?.error_msg,
  };
};
