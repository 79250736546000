import { safelyParseJSON } from '@fe-monorepo/helper';

const getFirebaseConfig = () => {
  return safelyParseJSON(process.env.NX_FIREBASE_WEB_CONFIG ?? '');
};

export const getEnvironment = () => {
  const baseUrl = process.env.NX_BFF_BASE_URL ?? '';
  const apiKey = process.env.NX_API_WEB_KEY ?? '';
  const websiteBaseURL = process.env.NX_WEBSITE_BASE_URL ?? '';
  const envCode = process.env.NX_ENV ?? '';
  const appEnvironment = process.env.NX_APP_ENVIRONMENT;
  const firebaseConfig = getFirebaseConfig();
  const mixPanelToken = process.env.NX_MIXPANEL_TOKEN ?? '';
  const convivaAppTracker = {
    // need to check app name for fe-web
    appname: 'STCPLAY_ANDROID',
    customerKey: process.env.NX_CONVIVA_CUSTOMER_KEY ?? '',
  };
  const tournamentsURL = process.env.NX_TOURNAMENTS_URL ?? '';
  const quickplayAPIKey = process.env.NX_QUICKPLAY_API_KEY ?? '';
  const quickplaySecretKey = process.env.NX_QUICKPLAY_SECRET_KEY ?? '';
  const quickplayBaseURL = process.env.NX_QUICKPLAY_BASE_URL ?? '';
  const googleConfig = process.env.NX_GOOGLE_CONFIG ?? {};
  const brazeWebAPIKey = process.env.NX_BRAZE_WEB_API_KEY;
  const brazeWebBaseURL = process.env.NX_BRAZE_WEB_BASE_URL;
  const brazeWebSafariPushID = process.env.NX_BRAZE_WEB_SAFARI_WEBSITE_PUSH_ID;
  const gtmID = process.env.NX_GTM_ID;
  const zegoCloudConfig = safelyParseJSON(process.env.NX_ZEGO_CLOUD_CONFIG ?? '');
  const platform = process.env.NX_PLATFORM ?? 'web';

  return {
    baseUrl,
    apiKey,
    envCode,
    firebaseConfig,
    websiteBaseURL,
    mixPanelToken,
    convivaAppTracker,
    tournamentsURL,
    quickplayAPIKey,
    quickplaySecretKey,
    quickplayBaseURL,
    googleConfig,
    brazeWebAPIKey,
    brazeWebBaseURL,
    brazeWebSafariPushID,
    gtmID,
    zegoCloudConfig,
    platform,
    appEnvironment,
  };
};
