import { Genders, changeUser, getUser, logCustomEvent } from '@braze/web-sdk';
import { genderMapping } from '@fe-monorepo/helper';

const brazeHelper = {
  trackPageView: (pageName: string, pageId: string, urlPath: string, referrer: string): void => {
    logCustomEvent('Page View', {
      'Page Name': pageName,
      'Page ID': pageId,
      'URL Path': urlPath,
      Referrer: referrer,
    });
  },

  logCustomBrazeEvent: (eventName: string, eventProperties: Record<string, any>): void => {
    logCustomEvent(eventName, eventProperties);
  },

  setIdentity: (userId: string): void => {
    changeUser(userId);
  },

  setUserProperties: (data: {
    email?: string;
    phone?: string;
    birthdate?: string;
    language?: string;
    genderCode?: string;
    countryCode?: string;
    customAttributes?: Record<string, any>;
  }): void => {
    const user = getUser();

    if (user) {
      if (data.email) {
        user.setEmail(data.email);
      }

      if (data.phone) {
        user.setPhoneNumber(`+${data.phone}`);
      }

      if (data.birthdate) {
        const birthdate = new Date(data.birthdate);
        const year = birthdate.getUTCFullYear();
        const month = birthdate.getUTCMonth() + 1;
        const day = birthdate.getUTCDate();

        user.setDateOfBirth(year, month, day);
      }

      if (data.language) {
        user.setLanguage(data.language);
      }

      if (data.genderCode && genderMapping[data.genderCode]) {
        user.setGender(genderMapping[data.genderCode] as Genders);
      }

      if (data.countryCode) {
        user.setCountry(data.countryCode);
      }

      if (data.customAttributes) {
        Object.entries(data.customAttributes).forEach(([key, value]) => {
          user.setCustomUserAttribute(key, value);
        });
      }
    }
  },
};

export default brazeHelper;
