import { formatNumberWithCommasAndDecimal } from '@fe-monorepo/helper';
import { CartItem, InvoiceProduct } from '@fe-monorepo/models';
import parse from 'html-react-parser';
import i18next, { t } from 'i18next';
import { useMemo } from 'react';

import { AppRoutes } from '../app/app.routes.enum';
import { UserVideo } from './types/bitTypes';
import { OptionList, getOption } from './types/faqTypes';

export const formatNumber = (num: number, precision = 1, dir?: 'ltr' | 'rtl') => {
  const toFixedNoRounding = (numberStr: string, precision: number) => {
    if (numberStr.indexOf('.') > -1) {
      return numberStr.slice(0, numberStr.indexOf('.') + precision + 1);
    } else {
      const extraString = ''.padEnd(precision, '0');
      return numberStr + (extraString.length ? '.' + extraString : '');
    }
  };
  const map = [
    { suffix: 'T', threshold: 1e12 },
    { suffix: 'B', threshold: 1e9 },
    { suffix: t('common_number_millions'), threshold: 1e6 },
    { suffix: t('common_number_thousands'), threshold: 1e3 },
    { suffix: '', threshold: 1 },
  ];
  if (num === 1000 && dir === 'rtl') {
    return t('common_number_thousand');
  }
  const found = map.find(x => Math.abs(num) >= x.threshold);
  if (found) {
    const number = num / found.threshold;
    const formatted = toFixedNoRounding('' + number, precision) + found.suffix;
    return formatted;
  }
  return num;
};

export const getLocalizedBitDetails = (language: 'ar' | 'en', userVideo: UserVideo) => {
  let title;
  let description;
  switch (language) {
    case 'ar':
      title = userVideo?.localisedTitle?.ar ?? userVideo.title;
      description = userVideo?.localisedDescription?.ar ?? userVideo.description;
      break;
    case 'en':
      title = userVideo?.localisedTitle?.en ?? userVideo.title;
      description = userVideo?.localisedDescription?.en ?? userVideo.description;
      break;
    default:
      title = userVideo.title;
      description = userVideo.description;
      break;
  }
  return { title, description };
};

export const formatKeyWithLanguage = (attr: string, lang: string): string => `${attr}_${lang}`;

export const getProductDiscountAndPrices = (selling_price: number, actual_cost: number) => {
  const hasDiscount = useMemo(() => selling_price < actual_cost, [selling_price, actual_cost]);
  const lowest_selling_price = formatNumberWithCommasAndDecimal(selling_price);
  const lowest_actual_cost = formatNumberWithCommasAndDecimal(actual_cost);
  return {
    discount: hasDiscount,
    selling_price: lowest_selling_price,
    actual_cost: lowest_actual_cost,
  };
};

export const mapProductDetailToCart = (data: CartItem, language: 'en' | 'ar') => {
  const product: InvoiceProduct = {
    order_product_id: 1,
    product_code: '',
    variant_id: 1,
    product_name: '',
    description: '',
    media: [],
    weight: 1,
    sku: '',
    SKU: '',
    name_en: '',
    name_ar: '',
    taxable: 0,
    is_digital: 0,
    is_active: 1,
    in_stock: 1,
    brand: {
      name_ar: '',
      name_en: '',
      collection_code: '',
    },
    seller: '',
    qty: 0,
    unit_price: 0,
    discount_price: 0,
    subtotal: 0,
    total_tax: 0,
    grand_total: 0,
    is_delivered: 0,
    unit_price_grand_total: 0,
    unit_price_total_tax: 0,
    lowest_selling_price: 0,
    lowest_actual_cost: 0,
  };
  const discount = data?.lowest_selling_price < data?.lowest_actual_cost;
  for (const x in product) {
    switch (x) {
      case 'product_name':
        product[x] = data[`name_${language}`];
        break;
      case 'description':
        product[x] = data[`description_${language}`];
        break;
      case 'seller':
        product[x] = data?.brand['collection_code'];
        break;
      case 'discount_price':
        if (discount) {
          product[x] = (data?.lowest_actual_cost - data?.lowest_selling_price) * data.qty;
        }
        break;
      case 'unit_price':
        product[x] = data.lowest_actual_cost;
        break;
      case 'variant_id':
        product[x] = parseInt(data['variant_id']);
        break;
      case 'subtotal':
        product[x] = (discount ? data.lowest_selling_price : data.lowest_actual_cost) * data.qty;
        break;
      case 'SKU':
      case 'sku':
        product[x] = data.sku;
        break;
      case 'product_code':
        product[x] = data.product_code;
        break;
      case 'media':
        product[x] = data.media;
        break;
      case 'weight':
        product[x] = data.weight;
        break;
      case 'qty':
        product[x] = data.qty;
        break;
      case 'brand':
        product[x] = data.brand;
        break;
      case 'is_digital':
        product[x] = data.is_digital;
        break;
      case 'grand_total':
        product[x] = (discount ? data.lowest_selling_price : data.lowest_actual_cost) * data.qty;
        break;
      case 'unit_price_grand_total':
        product[x] = data.lowest_actual_cost * data.qty;
        break;
      case 'lowest_selling_price':
        product[x] = data.lowest_selling_price;
        break;
      case 'lowest_actual_cost':
        product[x] = data.lowest_actual_cost;
        break;
    }
  }
  return product;
};

export function generateRandomId(len: number) {
  let result = '';
  if (result) return result;
  let chars = '12345qwertyuiopasdfgh67890jklmnbvcxzMNBVCZXASDQWERTYHGFUIOLKJP',
    maxPos = chars.length,
    i;
  len = len || 5;
  for (i = 0; i < len; i++) {
    result += chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return result;
}

export function getUrlParams(url = window.location.href) {
  const urlStr = url.split('?')[1];
  return new URLSearchParams(urlStr);
}

export function getTimestampInSeconds() {
  return Math.floor(Date.now() / 1000);
}

export const validateSameDigitalProducts = (product: InvoiceProduct[], product_code: string, variant_id: string) => {
  const same_digital_products = product.find(
    item => item.is_digital === 1 && item.product_code === product_code && item.variant_id === parseInt(variant_id),
  );
  let existingProduct = false;
  if (same_digital_products) {
    existingProduct = true;
  }
  return existingProduct;
};

export const getCountOfDigitalProducts = (product: InvoiceProduct[]) => {
  const digital_products = product.filter(item => item.is_digital === 1);
  return digital_products.length;
};

// used to parse a string element
// stringElem param - string with an element to be parsed
// optionList - array of options that replaces the element with the route
// paramReplacement - replaces the matching URL param with the desired value
export const anchorParse = (stringElem: string, optionList: OptionList[], paramReplacement?: OptionList[], anchorList?: OptionList[]) => {
  return parse(stringElem, getOption(optionList, paramReplacement, anchorList));
};

export const safelyParseJSONString = (input: string) => {
  let output;
  try {
    output = JSON.parse(input);
  } catch (e) {
    output = {};
  }
  return output;
};

const CUSTOM_PAGE: any = {
  'sign-up': AppRoutes.authSignUp,
  'sign-in': AppRoutes.authSignIn,
  'my-profile': AppRoutes.myprofile,
  notification: AppRoutes.notifications,
  settings: AppRoutes.settings,
  bits: AppRoutes.bits,
  games: AppRoutes.games,
  matchmaking: AppRoutes.matchmaking,
  shop: AppRoutes.shop,
  wallet: AppRoutes.walletSettings,
  ewc: AppRoutes.ewc,
  geng: AppRoutes.genGLandingPage,
  connect: AppRoutes.messages,
  help: AppRoutes.help,
  terms: AppRoutes.helpTemrsCondition,
  'privacy-policy': AppRoutes.helpPrivacyPolicy,
};

export const getURLForRedirection = (type: string, value: string, isLoggedIn: boolean, user?: any, code?: string, name?: string) => {
  let url: any = '';
  let options = {};
  function cleanUrl(url: string) {
    if (url.endsWith('%22')) {
      return url.slice(0, -3);
    }
    return url;
  }
  switch (type) {
    case 'tournament':
      url = `${AppRoutes.tournaments}/${value}`;
      break;
    case 'collection':
      url = `${AppRoutes.shop}/c-${value}`;
      break;
    case 'product':
      url = `${AppRoutes.shop}/${code}/${value}`;
      break;
    case 'bit':
      url = `${AppRoutes.bitsPlay}/content/${value}`;
      break;
    case 'custom':
      try {
        const parsedValue = JSON.parse(value);
        if (parsedValue?.custom_type === 'page') {
          const page_name = parsedValue?.page_name;
          if (page_name === 'sign-up' || page_name === 'sign-in') {
            if (isLoggedIn) {
              url = CUSTOM_PAGE['my-profile']; // Redirect to "My Profile" if logged in
            } else {
              url = CUSTOM_PAGE[page_name]; // Redirect to "Sign Up" or "Sign In"
            }
          } else if (page_name === 'offers') {
            url = `${AppRoutes.shop}/m-${page_name}`; // Handle offers page redirection
          } else {
            const page_url: any = CUSTOM_PAGE[page_name];
            if (page_url) {
              url = page_url;
            } else if (page_name === 'tournaments') {
              window.location.replace(`https://tournament.stcplay.gg/${i18next.language === 'ar' ? 'ar' : 'en_US'}/`);
            } else if (page_name === 'view-profile' && user?.username) {
              url = `${AppRoutes.users}/${user?.username}`;
            }
          }
        } else if (parsedValue?.custom_type === 'streaming') {
          url = '/video';
          options = { state: { mediaUrl: cleanUrl(parsedValue?.url), contentTitle: name ?? 'banner', isFullScreen: true } };
        }
      } catch (error) {
        console.error('Error parsing custom value:', error);
      }
      break;
    case 'external':
      window.open(value);
      break;
  }
  return { url, options };
};

// Detect the platform
export const detectPlatform = () => {
  const userAgent = navigator.userAgent;
  // iOS detection
  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return 'ios';
  }
  // Android detection
  if (/android/i.test(userAgent)) {
    return 'android';
  }
  return 'web'; // Default to web if platform is not detected
};
