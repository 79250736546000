import { getEnvironment } from '@fe-web/constant/environment';
import mixpanel, { OverridedMixpanel } from 'mixpanel-browser';

const { mixPanelToken = '', appEnvironment } = getEnvironment();

const IS_DEBUG = ['development', 'localhost', 'staging'].includes(appEnvironment ?? '');

export let mixpanelInstance: OverridedMixpanel;

if (mixPanelToken) {
  mixpanel.init(mixPanelToken, { debug: IS_DEBUG, persistence: 'localStorage' });
  mixpanelInstance = mixpanel;
}

const mixpanelHelper = {
  trackPageView: (pageName: string, pageId: string, urlPath: string, referrer: string): void => {
    mixpanel.track('Page View', {
      'Page Name': pageName,
      'Page ID': pageId,
      'URL Path': urlPath,
      Referrer: referrer,
    });
  },

  trackEvent: (eventName: string, eventData: Record<string, any>): void => {
    mixpanel.track(eventName, eventData);
  },

  setIdentity: (userId: string): void => {
    mixpanel.identify(userId);
  },

  setUserProperties: (properties: Record<string, any>): void => {
    mixpanel.people.set(properties);
  },

  reset: (): void => {
    mixpanel.reset();
  },
};

export default mixpanelHelper;
