const platform = process.env.NX_PLATFORM;

const webStorage = {
  getItem: async (key: any) => {
    return Promise.resolve(localStorage.getItem(key));
  },
  setItem: async (key: any, value: any) => {
    return Promise.resolve(localStorage.setItem(key, value));
  },
  removeItem: async (key: any) => {
    return Promise.resolve(localStorage.removeItem(key));
  }
};


export const storage = platform === 'web' ? webStorage : require('@react-native-async-storage/async-storage').default;;
