import { IMAGES } from '@fe-monorepo/assets';
import {
  ASSETS_LINK,
  EventObject,
  IMAGE_ALT_LABELS,
  IconNames,
  MCI_REGULATIONS,
  MixPanelCustomEvents,
  SOCIAL_MEDIA_LINKS,
  TxKeyPath,
  VAT_CERT,
  convertToTimezone,
  getAssetsLink,
} from '@fe-monorepo/helper';
import { useShopInfo, useSubscribe, useTranslate, useUserProfile } from '@fe-monorepo/hooks';
import { MenuItem } from '@fe-monorepo/models';
import { RootState } from '@fe-monorepo/store';
import AppStoreButton from '@fe-web/Atoms/Buttons/AppDownload/AppStoreButton';
import Modal from '@fe-web/Templates/Modal/Modal';
import { FOOTER_HIDE_SECTION } from '@fe-web/constant/constants';
import { getEnvironment } from '@fe-web/constant/environment';
import mixpanelHelper from '@fe-web/helpers/mixpanelHelper';
import useHeaderState from '@fe-web/hooks/useHeaderState';
import useMobileDetect from '@fe-web/hooks/useMobileDetect';
import useOutsideClick from '@fe-web/hooks/useOutsideClick';
import usePageLayout from '@fe-web/hooks/usePageLayout';
import { useCallback, useEffect, useRef, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Document, Page, pdfjs } from 'react-pdf';
import { PageCallback } from 'react-pdf/dist/cjs/shared/types';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

import { webAnalytics } from '../../../../../helper/webAnalytics';
import { AppRoutes } from '../../../app.routes.enum';
import Button from '../../Atoms/Buttons/ButtonFooter';
import ResponsiveIcon from '../../Atoms/Icon/ResponsiveIcon';
import InputField from '../../Molecules/InputFields/InputFieldFooter';
import Container from '../../Templates/Container';
import './Footer.scss';
import FooterGreet from './FooterGreet';

const Footer = () => {
  const { subscribeToNewsLetter, isSuccessful, setStatus } = useSubscribe();
  const [email, setEmail] = useState<string>('');
  const [isClear, setIsClear] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>('');
  const navigate = useNavigate();
  const { QrCode } = IMAGES;
  const { envCode } = getEnvironment();
  const { translate } = useTranslate();
  const isMobile = useMobileDetect();
  const { isLoggedIn, user } = useUserProfile();
  const { dir } = useHeaderState();
  const emailText = translate('footer_email') ?? 'Email';
  const isHeaderDark = useSelector((state: RootState) => state.app.headerColorChange);
  const translatedText = translate('validation_invalideEmail') ?? '';
  const [headerData, setHeaderData] = useState<MenuItem>();
  const ShopInfo = useShopInfo();
  const { language } = usePageLayout();
  const currentYear = convertToTimezone(new Date().toString()).year();

  const emailValidation = (value: string) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(value);
  };

  useEffect(() => {
    ShopInfo?.setIsLoading(true);
    ShopInfo?.getAll({ menu_code: ShopInfo?.SHOP_MENU_CODE.SHOP_PAGE });
  }, []);

  useEffect(() => {
    if (ShopInfo?.info) {
      let tempSubCategoryList: MenuItem[] = [];
      ShopInfo?.info?.data?.menu_items.forEach(menu => {
        const newList = menu.menu_items.filter(item => item.menu_items.length > 0);
        tempSubCategoryList = [...tempSubCategoryList, ...newList];
      });

      let tempCategory: MenuItem = ShopInfo?.info?.data?.menu_items?.slice()?.filter(item => {
        return item?.menu_code === 'navigation_menu';
      })[0];

      tempCategory = { ...tempCategory, menu_items: tempSubCategoryList };

      setHeaderData(tempCategory);
    }
  }, [ShopInfo?.info]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!emailValidation(email)) {
      setErrorMessage(translatedText);
      return;
    }
    const params = {
      email,
    };

    const newsletterEvent: EventObject = {
      name: 'subscribed_newsletter',
      properties: {},
    };

    webAnalytics(newsletterEvent);
    subscribeToNewsLetter(params);

    mixpanelHelper.trackEvent(MixPanelCustomEvents.BannersButtonCTA, {
      'CTA Name': 'Subscribe',
      Referrer: window.location.href,
      'CTA Source': 'Footer',
      Username: isLoggedIn ? user?.username : 'Guest',
    });

    setErrorMessage('');
    setEmail('');
  };

  //create a useEffect to clear the error message after 3 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setErrorMessage('');
    }, 3000);
    return () => clearTimeout(timer);
  }, [errorMessage]);

  const handleClear = () => {
    setIsClear(true);
    setTimeout(() => {
      setIsClear(false);
    }, 1000);
  };

  const footerInputFieldStyle = {
    containerStyle: 'mb-20 4xl:mb-36 5xl:mb-54 8xl:mb-100',
    labelStyle: 'placeholder:text-white',
    inputStyle: `
      my-8 4xl:my-12 5xl:my-20 8xl:my-44
      h-24 4xl:h-40 5xl:h-64 8xl:h-120
      text-black100
      font-regular
      text-white
      text-bodyLarge 4xl:text-title 5xl:text-mobileDynamic 8xl:text-eightKSubtitle
      placeholder:text-bodyLarge placeholder:4xl:text-title placeholder:5xl:text-mobileDynamic placeholder:8xl:text-eightKSubtitle`,
    underlineStyle: 'h-[1px] w-full 4xl:h-[1.77px] 5xl:h-[2.66px] 8xl:h-[5.33px]',
  };

  const socialMedia = (
    <div className="flex flex-row gap-[0.9375rem] items-center SD:justify-start justify-between w-full">
      <ResponsiveIcon
        className="cursor-pointer fill-white animate__animated animate__fadeIn"
        name={IconNames.facebook}
        baseWidth={50}
        baseHeight={50}
        onClick={() => window.open(SOCIAL_MEDIA_LINKS.facebook, '_blank')}
      />
      <ResponsiveIcon
        className="cursor-pointer fill-white animate__animated animate__fadeIn"
        name={IconNames.twitterX}
        baseWidth={50}
        baseHeight={50}
        onClick={() => window.open(SOCIAL_MEDIA_LINKS.twitter, '_blank')}
      />
      <ResponsiveIcon
        className="cursor-pointer fill-white animate__animated animate__fadeIn"
        name={IconNames.instagram2}
        baseWidth={50}
        baseHeight={50}
        onClick={() => window.open(SOCIAL_MEDIA_LINKS.instagram, '_blank')}
      />
      <ResponsiveIcon
        className="cursor-pointer fill-white animate__animated animate__fadeIn"
        name={IconNames.linkedin}
        baseWidth={50}
        baseHeight={50}
        onClick={() => window.open(SOCIAL_MEDIA_LINKS.linkedin, '_blank')}
      />
    </div>
  );

  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const ref = useRef<HTMLDivElement>(null);
  const [scale, setScale] = useState<number>(1);
  const prefs = useSelector((state: RootState) => state.app);

  const onPageLoadSuccess = useCallback(
    (page: PageCallback) => {
      const newScale = (ref?.current?.clientWidth || 0) / page.originalWidth;
      if (scale !== newScale) {
        setScale(newScale);
      }
    },
    [scale, ref?.current?.clientWidth],
  );

  pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.js', import.meta.url).toString();

  const elementRef = useOutsideClick(() => {
    setModalOpen(false);
  });

  const viewPDF = () => {
    const replacedString = `${getAssetsLink(envCode)}${ASSETS_LINK.stcPlayVATCertificate}`;
    return replacedString;
  };

  return (
    <footer className="w-full cmp-footer overflow-clip">
      {!FOOTER_HIDE_SECTION.includes(window.location.pathname) && !window.location.pathname.includes('payment') && (
        <section className="-mb-[4px] sm:-mb-[1rem] md:-mb-[1rem] lg:-mb-[1rem] xl:-mb-[1rem] 2xl:-mb-[1rem] h-max bg-static-secondary SD:py-[3rem] 4xl:py-[71.11px] 6xl:pb-[10rem] 8xl:py-[213.33px]">
          <Container className="bg-static-secondary">
            <div className="flex SD:grid SD:grid-cols-2 w-full bg-static-secondary gap-[1.5rem] pt-[1rem]">
              <div className="hidden xl:flex gap-[1.5rem] w-full col-span-1">
                <div className="qr-code h-[8.063rem] w-[8.063rem] p-[0.375rem] rounded-[0.125rem]">
                  <LazyLoadImage
                    src={QrCode.toString()}
                    alt={translate(IMAGE_ALT_LABELS.qr_code as TxKeyPath) ?? ''}
                    className="w-full h-full"
                  />
                </div>
                {/* Width for the Get the stc app container here  */}
                <div className="flex flex-col gap-[1rem] h-full w-[27rem]">
                  <div className="flex-col SD:flex">
                    <div className="text-white font-medium text-fs-subtitle h-full tracking-[-0.015rem] mb-[0.25rem] self-start">
                      {translate('footer_stc_play_app')}
                    </div>
                    <div className="text-white font-regular text-fs-body-small h-full mb-[1.2rem]">
                      {translate('footer_hunt_for_the_best_deals_available_only_on_our_mobile_app')}
                    </div>
                  </div>
                  <div
                    className="w-full flex
                      flex-col
                      gap-[56px]
                      bg-static
                      sm:gap-[56px]
                      xs:gap-[56px]
                      xsMax:gap-[56px]
                      md:gap-[56px]
                      sm:flex-col
                      xs:flex-col
                      xsMax:flex-col
                      md:flex-col
                      lg:flex-row
                      2xl:flex-row
                      3xl:flex-row
                      4xl:flex-row
                      5xl:flex-row
                      6xl:flex-row
                      8xl:flex-row
                      lg:justify-between
                      2xl:justify-between
                      3xl:justify-between
                      4xl:justify-between
                      5xl:justify-between
                      6xl:justify-between
                      8xl:justify-between
                      8xl:gap-[298px]
                      4xl:gap-[99.55px]"
                  >
                    <div className="hidden lg:flex xl:flex gap-[1rem]">
                      <AppStoreButton store="Apple" />
                      <AppStoreButton store="Google" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full col-span-1 mdMaxxS:mt-[2rem]">
                {isMobile && (
                  <span className="mb-[1rem]">
                    <ResponsiveIcon
                      className="cursor-pointer fill-white"
                      name={IconNames.stcPlay}
                      baseWidth={147}
                      baseHeight={40}
                      onClick={() => navigate(AppRoutes.home)}
                      srAltText={(translate('SEO.page_image_alt.home') as TxKeyPath) ?? ''}
                    />
                  </span>
                )}
                <h1 className="text-white font-medium text-fs-subtitle tracking-[-0.015rem] mb-[0.25rem] mdMaxxS:mt-[2rem]">
                  {translate('footer_coming_soon')}
                </h1>
                <p className="text-white/70 SD:text-white text-fs-body-small font-regular mb-[1.5rem]">
                  {translate('footer_join_our_newsletter_get_100_pts_for_shopping_later')}
                </p>

                <form
                  className="flex flex-col SD:gap-[1.5rem] sm:flex-col md:flex-col lg:flex-col xl:flex-row gap-0 justify-between top-0 right-0 left-0"
                  onSubmit={handleSubmit}
                >
                  <div className="mt-0 xl:mt-[0.5rem] sm:h-[4.375rem] w-full SD:w-[75%]">
                    <InputField
                      label={emailText}
                      isSecret={false}
                      hasFocused={false}
                      errorMessage={errorMessage}
                      isUserAllowedToType={(value: string) => true}
                      retrieveValue={(value: any) => setEmail(value)}
                      style={footerInputFieldStyle}
                      clearInput={() => isClear}
                      isFooter={true}
                    />
                  </div>
                  <div className="mt-[0] SD:mt-[2rem]" onClick={() => handleClear()}>
                    <Button
                      text={translate('action_subscribe')}
                      style={`w-full sm:w-full not-italic font-medium leading-6 px-[1rem] py-[0.5rem] font-medium gap-[8px] text-white100 border-[1px] border-transparent rounded-sm ${
                        isHeaderDark
                          ? dir === 'ltr'
                            ? 'after:bg-sunset before:bg-white100'
                            : 'before:bg-sunset after:bg-white100'
                          : `${dir === 'ltr' ? 'after:bg-sunset before:bg-white100' : 'before:bg-sunset after:bg-white100'}
                        hover:-border[1px] hover:border-sunset`
                      } hover:text-sunsetText rounded-sm`}
                      disabled={false}
                    />
                  </div>
                </form>
                <Modal open={isSuccessful}>
                  <FooterGreet setStatus={setStatus} />
                </Modal>
              </div>
            </div>
          </Container>
        </section>
      )}
      {/* border-b border-solid border-white/20 */}
      {!FOOTER_HIDE_SECTION.includes(window.location.pathname) && (
        <section className="sm:pt-0 md:pt-[2.5rem] lg:pt-[2.5rem] xl:pt-[2.5rem] 4xl:pt-0 bg-static-secondary SD:pt-[40px] pb-[40px] 4xl:py-[71.11px] 8xl:py-[213.33px] border-b border-solid border-white/20">
          <div className="border-transparent border-b border-solid SD:border-white/20 mb-[3rem] xl:mx-[7.5rem] pt-0 sm:pt-[2.8rem]" />
          <Container className="bg-static-secondary">
            {/* SECTION 2 Gap here */}
            <div className="grid grid-cols-2 SD:grid-cols-6 gap-y-[2.5rem] gap-x-[1rem] xl:gap-[1.5rem] mdMaxxS:mb-[2.5rem]">
              {!isMobile && (
                <div className="col-span-3">
                  <div className="flex flex-col gap-[1rem]">
                    <ResponsiveIcon
                      className="cursor-pointer fill-white animate__animated animate__fadeIn"
                      name={IconNames.stcPlay}
                      baseWidth={147}
                      baseHeight={40}
                      onClick={() => navigate(AppRoutes.home)}
                      srAltText={(translate('SEO.page_image_alt.home') as TxKeyPath) ?? ''}
                    />
                    <div>{socialMedia}</div>
                  </div>
                </div>
              )}
              {/* PLAY SECTION */}
              <div className="w-[11.25rem] SD:w-full SD:col-span-1">
                <div className="text-white text-fs-body-large not-italic font-medium mb-[1rem]">{translate('tabTitle_play')}</div>
                <div
                  className={`text-white/70 text-fs-body-small not-italic font-regular mb-[0.5rem] hover:cursor-pointer underline-${dir}-animation after:bottom-[-0rem] after:bg-white100 w-fit hover:text-white100`}
                >
                  <Link to={AppRoutes.games}>{translate('pageTitle_games')}</Link>
                </div>
                <div
                  className={`text-white/70 text-fs-body-small not-italic font-regular mb-[0.5rem] hover:cursor-pointer underline-${dir}-animation after:bottom-[-0rem] after:bg-white100 w-fit hover:text-white100`}
                >
                  {/* ; */}
                  <div
                    onClick={() => {
                      window.location.replace(`https://tournament.stcplay.gg/${prefs?.language === 'ar' ? 'ar' : 'en_US'}/`);
                    }}
                  >
                    {translate('tabTitle_tournaments')}
                  </div>
                </div>
                <div
                  className={`text-white/70 text-fs-body-small not-italic font-regular mb-[0.5rem] hover:cursor-pointer underline-${dir}-animation after:bottom-[-0rem] after:bg-white100 w-fit hover:text-white100`}
                >
                  <Link to={AppRoutes.matchmaking}>{translate('tabTitle_matchmaking')}</Link>
                </div>
              </div>
              {/* SHOP SECTION */}
              <div className="w-[11.25rem]">
                <div className="text-white text-fs-body-large not-italic font-medium leading-6 mb-[1rem]">
                  {translate('pageTitle_shop')}
                </div>
                {headerData?.menu_items?.slice(0, 5).map(item => (
                  <div
                    key={item.menu_code}
                    className={`text-white/70 text-fs-body-small not-italic font-regular mb-[0.5rem] hover:cursor-pointer underline-${dir}-animation after:bottom-[-0rem] after:bg-white100 w-fit hover:text-white100`}
                  >
                    <Link to={`${AppRoutes.shop}/m-${item.menu_code}`}>{item && item[`name_${language}`]}</Link>
                  </div>
                ))}
              </div>
              {/* HELP SECTION */}
              <div className="w-[11.25rem]">
                <div className="text-white text-fs-body-large not-italic font-medium leading-6 mb-[1rem]">{translate('footer_help')}</div>
                <div
                  className={`text-white/70 text-fs-body-small not-italic font-regular mb-[0.5rem] hover:cursor-pointer underline-${dir}-animation after:bottom-[-0rem] after:bg-white100 w-fit hover:text-white100`}
                >
                  <Link to={AppRoutes.help}>{translate('footer_faq')}</Link>
                </div>
                <div
                  className={`text-white/70 text-fs-body-small not-italic font-regular mt-16 md:mt-8 5xl:mt-[30.8px] 8xl:mt-[66px] rtl:flex-row-reverse hover:cursor-pointer underline-${dir}-animation after:bottom-[-0rem] after:bg-white100 w-fit hover:text-white100`}
                >
                  <Link to={isLoggedIn ? AppRoutes.myorders : AppRoutes.authSignIn}>{translate('footer_order_status')}</Link>
                </div>
                <div
                  className={`text-white/70 text-fs-body-small not-italic font-regular mt-16 md:mt-8 5xl:mt-[30.8px] 8xl:mt-[66px] rtl:flex-row-reverse hover:cursor-pointer underline-${dir}-animation after:bottom-[-0rem] after:bg-white100 w-fit hover:text-white100`}
                >
                  <Link to={AppRoutes.helpShoppingDelivery}>{translate('footer_shipping_delivery')}</Link>
                </div>
                <div
                  className={`text-white/70 text-fs-body-small not-italic font-regular mt-16 md:mt-8 5xl:mt-[30.8px] 8xl:mt-[66px] rtl:flex-row-reverse hover:cursor-pointer underline-${dir}-animation after:bottom-[-0rem] after:bg-white100 w-fit hover:text-white100`}
                >
                  <Link to={AppRoutes.helpExchangeReturns}>{translate('exchange_returns')}</Link>
                </div>
                <div
                  className={`text-white/70 text-fs-body-small not-italic font-regular mt-16 md:mt-8 5xl:mt-[30.8px] 8xl:mt-[66px] rtl:flex-row-reverse hover:cursor-pointer underline-${dir}-animation after:bottom-[-0rem] after:bg-white100 w-fit hover:text-white100`}
                >
                  <Link to={AppRoutes.contactUS}>{translate('common_contactUs')}</Link>
                </div>
              </div>
              {/* HELP SECTION END */}
            </div>
            {/* LINK TO APPLE STORE AND GOOGLE PLAY STORE STARTS */}
            {isMobile && socialMedia}
            {isMobile && (
              <div className="mt-[2.5rem]">
                <div className="text-white font-medium text-fs-subtitle h-full tracking-[-0.015rem] mb-[0.25rem] self-start">
                  {translate('footer_stc_play_app')}
                </div>
                <div className="text-white font-regular text-fs-body-small h-full mb-[1.2rem]">
                  {translate('footer_hunt_for_the_best_deals_available_only_on_our_mobile_app')}
                </div>
              </div>
            )}
            <div className="mt-[2.5rem] flex justify-start SD:justify-center SD:flex HD:hidden gap-[1rem]">
              <AppStoreButton store="Apple" />
              <AppStoreButton store="Google" />
            </div>
            {/* LINK TO APPLE STORE AND GOOGLE PLAY STORE ENDS */}
          </Container>
        </section>
      )}
      <section className="bg-static-secondary">
        <Container className="bg-static-secondary">
          <div
            className="flex py-[34px] w-full
              gap-[2.5rem]
              sm:flex-col
              xs:flex-col
              xsMax:flex-col
              md:flex-row
              lg:flex-row
              2xl:flex-row
              3xl:flex-row
              4xl:flex-row
              5xl:flex-row
              6xl:flex-row
              7xl:flex-row
              md:justify-between
              lg:justify-between
              2xl:justify-between
              3xl:justify-between
              4xl:justify-between
              5xl:justify-between
              6xl:justify-between
              8xl:justify-between
              4xl:py-[60.44px]
              8xl:py-[181.33px]"
          >
            <div className="flex gap-[24px] 4xl:gap-[42.66px] 8xl:gap-[128px]">
              <Link className="cursor-pointer text-white/70 text-fs-caption font-regular" to={AppRoutes.help}>
                {translate('settingPage_help')}
              </Link>
              <Link className="cursor-pointer text-white/70 text-fs-caption font-regular" to={AppRoutes.helpPrivacyPolicy}>
                {translate('menu_privacy')}
              </Link>
              <Link className="cursor-pointer text-white/70 text-fs-caption font-regular" to={AppRoutes.helpTemrsCondition}>
                {translate('terms_and_condition')}
              </Link>
            </div>
            <div className="flex flex-col SD:flex-row gap-[1.5rem]">
              <div className="flex flex-row gap-[1.5rem]">
                <div className="text-white/70 text-fs-caption font-regular">
                  {translate('common_cr_no', { number: MCI_REGULATIONS.CR })}
                </div>
                <div className="text-white/70 text-fs-caption font-regular">
                  {translate('footer_pages.vat_commerce_reg.vat')} {VAT_CERT.VAT}
                </div>
                <Modal open={modalOpen}>
                  <div
                    ref={newRef => (elementRef.current = newRef)}
                    className={`absolute top-[2rem] overflow-y-auto bg-white rounded-[.5rem] mb-[2rem] ${
                      isLoading ? '' : 'h-[90%] w-[38vw] max-w-[100vw]'
                    }`}
                  >
                    <div ref={ref}>
                      {!isLoading && (
                        <ResponsiveIcon
                          name={IconNames.close}
                          baseWidth={15}
                          baseHeight={15}
                          className="flex fill-secondary justify-end pr-[1rem] pt-[1rem] z-20 hover:cursor-pointer"
                          onClick={() => setModalOpen(!modalOpen)}
                        />
                      )}
                      <Document file={viewPDF()} onLoadError={() => setModalOpen(!modalOpen)} onLoadSuccess={() => setIsLoading(false)}>
                        <Page
                          pageNumber={1}
                          renderTextLayer={false}
                          renderAnnotationLayer={false}
                          scale={scale}
                          onLoadSuccess={onPageLoadSuccess}
                        />
                      </Document>
                    </div>
                  </div>
                </Modal>
              </div>
              <div className="text-white/70 text-fs-caption font-regular">{translate('common_copyright', { year: currentYear })}</div>
            </div>
          </div>
        </Container>
      </section>
    </footer>
  );
};

export default Footer;
