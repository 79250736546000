import { IMAGES } from '@fe-monorepo/assets';
import { IconNames } from '@fe-monorepo/helper';
import { useTranslate } from '@fe-monorepo/hooks';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import { LazyLoadImage } from 'react-lazy-load-image-component';

interface FooterContentProps {
  setStatus: (value: boolean) => void;
}

const FooterGreet = ({ setStatus }: FooterContentProps) => {
  const { translate } = useTranslate();

  const handleClose = () => {
    setStatus(false);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-[9999]">
      <div className="fixed inset-0 bg-black modal-overlay opacity-30"></div>
      <div className="modal-container bg-moonlight rounded shadow-lg z-50 relative mdMaxxSD:mx-[1rem]">
        <div className="modal-content py-[1rem] text-left px-[2.25rem] pb-[4rem]">
          <div className="flex justify-end items-center pb-[2rem]">
            <ResponsiveIcon
              name={IconNames.closeRegular}
              baseHeight={2.25}
              baseWidth={2.25}
              onClick={handleClose}
              iconClasses="w-[1.5rem] h-[1.5rem]"
              className="cursor-pointer modal-close #171619"
            />
          </div>
          <div className="flex items-center justify-center w-full">
            <div>
              <LazyLoadImage src={IMAGES.FooterGreet.toString()} alt="Need to Sign In" className="w-[16.25rem] h-[4.783rem]" />
            </div>
          </div>
          <h1 className="mt-[2rem] text-fs-big-title not-italic font-medium leading-[3rem] tracking-[-0.075rem] text-center mb-[1.5rem]">
            {translate('greet_subscribe')}
          </h1>
          <p className="not-italic leading-6 text-center font-regular text-static-primary">{translate('greet_one')}</p>
          <p className="not-italic leading-6 text-center font-regular text-static-primary">{translate('greet_two')}</p>
        </div>
      </div>
    </div>
  );
};

export default FooterGreet;
