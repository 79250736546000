import { useTranslate } from '@fe-monorepo/hooks';
import { AppRoutes } from 'apps/fe-web/src/app/app.routes.enum';
import { useNavigate } from 'react-router-dom';

import { CONTENT_TYPE, EVENTS } from '../../../constants';
import useAnalytics from '../../../hooks/useAnalytics';
import { ReactComponent as RepostIcon } from '../Icons/RepostIcon.svg';
import VideoDescription from './Description';
import VerticalPlayerProducts from './Products';

export default function UserInfo({
  showFollowButton,
  setShowMore,
  handleFollow,
  isFollowing,
  products,
  repost,
  showComments,
  showMore,
  video,
}: any) {
  const { translate } = useTranslate();
  const userData = JSON.parse(localStorage.getItem('userData') || '{}');

  const { user } = video ?? {};
  const { userId, fullName, profileImageUrl, userName } = user ?? {};

  const { trackEvent } = useAnalytics();
  const navigate = useNavigate();

  const reDirectToOriginalVideo = () => {
    trackEvent({
      event: EVENTS.VIEW_IMPRESSION,
      content: video,
    });
    navigate(`${AppRoutes.bitsPlay}/content/${video.originalVideoId}?contentType=${CONTENT_TYPE.ORIGINAL_VIDEO}`);
  };

  return (
    <div className="user_info">
      {repost && (
        <div className="repost_text">
          <button onClick={reDirectToOriginalVideo}>
            <span>
              <RepostIcon />
            </span>
            {translate('quickplay.label-text-reposted')}
            <span className="dash">-</span>
            {translate('quickplay.label-original-video')}
          </button>
        </div>
      )}
      <div className="user_info_responsive">
        <div className={`top_info ${showComments ? 'pr_6' : ''}`}>
          <a href={`/users/${user.userName}`}>
            <div className="img_name">
              <img
                src={profileImageUrl ? profileImageUrl : 'https://bits-assets.stag.stcplay.gg/Profile.png'}
                alt={`${fullName} profile pic`}
              />
              <div className="user_profile">
                <h4 className="user_name">{userName}</h4>
                <h6 className="full_name">{fullName}</h6>
              </div>
            </div>
          </a>
          {showFollowButton && (
            <>
              {userData?.userId !== userId && !isFollowing && (
                <>
                  <div className="custom-dot">.</div>
                  <button className="follow_btn" onClick={() => handleFollow(video, user.userId, true)}>
                    {translate('quickplay.follow-btn')}
                  </button>
                </>
              )}
              {userData?.userId !== userId && isFollowing && (
                <>
                  <div className="custom-dot">.</div>
                  <button className="follow_btn" onClick={() => handleFollow(video, user.userId, false)}>
                    {translate('quickplay.unfollow-btn')}
                  </button>
                </>
              )}
            </>
          )}
        </div>

        <VideoDescription video={video} showComments={showComments} showMore={showMore} setShowMore={setShowMore} />
      </div>
      {products?.length > 0 && <VerticalPlayerProducts show={true} products={products || []} />}
    </div>
  );
}
