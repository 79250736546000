import { useMutation } from '@apollo/client';
import { REMOVE_DEVICE_MUTATION } from '@fe-monorepo/data-access';

import { RemoveDeviceParams, RemoveDeviceResponse } from './type';

export const useRemoveDevice = () => {
  const [removeDeviceParams, { data: removeDeviceParamsResponse, error, loading: isLoading }] = useMutation<RemoveDeviceResponse>(
    REMOVE_DEVICE_MUTATION.removeDevice,
    {
      errorPolicy: 'all',
    },
  );

  const removeDevice = async (params: RemoveDeviceParams) => {
    const { data } = await removeDeviceParams({ variables: { ...params } });
    return data?.removeDevice;
  };

  return {
    removeDevice,
    isLoading,
    error,
  };
};