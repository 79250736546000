import { gql } from '@apollo/client';

export const INVOICE_MUTATION = {
  createInvoice: gql`
    mutation createInvoice($details: OrderInvoiceInput) {
      createInvoice(details: $details) {
        error_msg
        is_successful
        error_code
        data {
          invoice_number
        }
      }
    }
  `,

  clearInvoice: gql`
    mutation clearInvoice($details: ClearInvoiceInput) {
      clearInvoice(details: $details) {
        error_msg
        is_successful
        error_code
      }
    }
  `,
  guestCartTotal: gql`
    mutation guestCartTotal($details: GuestCartTotalInput) {
      guestCartTotal(details: $details) {
        error_msg
        is_successful
        error_code
        data {
          amount_total
          amount_discount
          amount_sub_total
          amount_tax
          total_amount
          amount_grand_total
          products {
            order_product_id
            product_code
            variant_id
            product_name
            description
            media
            weight
            SKU
            sku
            taxable
            is_digital
            is_active
            in_stock
            brand {
              name_en
              name_ar
              collection_code
            }
            seller
            qty
            unit_price
            unit_price_total_tax
            unit_price_grand_total
            discount_price
            subtotal
            total_tax
            grand_total
            is_delivered
          }
        }
      }
    }
  `,
};
