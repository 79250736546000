import { IconNames } from '@fe-monorepo/helper';
import { useTranslate } from '@fe-monorepo/hooks';
import { RootState } from '@fe-monorepo/store';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import { useNotificationProvider } from '@fe-web/hooks/useNotification';
import useOutsideClick from '@fe-web/hooks/useOutsideClick';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Popover } from 'react-tiny-popover';

import { AppRoutes } from '../../../../app.routes.enum';

interface SideBarHeaderProps {
  isNotificationEmpty: boolean;
  showFullViewIcon: boolean;
  isRTL: boolean;
  openFilter?: () => void;
}

const SidebarNotificationHeader = ({ showFullViewIcon, openFilter }: SideBarHeaderProps) => {
  const { translate } = useTranslate();
  const { notificationLength, markReadAllNotification, removeAllNotification } = useNotificationProvider();
  const navigate = useNavigate();

  // redirect to notification dedicated page
  const onMaximizeNotificationClick = () => {
    navigate(AppRoutes.notifications);
  };

  // Marks all notification as read
  const markAllAsRead = () => {
    markReadAllNotification();
  };

  // removes all notification
  const clearAllNotification = () => {
    removeAllNotification();
  };
  const [isOpen, setIsOpen] = useState(false);
  const popOverRef = useOutsideClick(() => {
    setIsOpen(false);
  });
  const isRTL = useSelector((state: RootState) => state.app.isRTL);

  return (
    <div className="flex flex-row justify-between">
      <p className="text-fs-body-large text-secondary font-medium">
        {translate('common_notification_count', {
          value: notificationLength && notificationLength > 0 ? `(${notificationLength})` : ``,
        })}
      </p>
      <div className="flex flex-row gap-[1rem] hover:cursor-pointer">
        {showFullViewIcon && (
          <ResponsiveIcon
            name={IconNames.icon_chat_maximize}
            baseHeight={20}
            baseWidth={20}
            iconClasses="fill-secondary hover:fill-zinc-400 dark:hover:fill-zinc-200"
            onClick={onMaximizeNotificationClick}
            className="self-center"
          />
        )}
        <Popover
          isOpen={isOpen}
          ref={newRef => (popOverRef.current = newRef)}
          positions={['right', 'bottom']}
          padding={10}
          reposition={false}
          onClickOutside={() => setIsOpen(false)}
          containerClassName="bg-primary border-[0.0625rem] border-secondary/[0.2] rounded-[0.25rem] w-[12.5rem] z-max"
          containerStyle={{
            top: '2rem',
            left: isRTL ? '0' : '-15rem',
          }}
          content={() => (
            <div>
              {showFullViewIcon && (
                <div
                  onClick={onMaximizeNotificationClick}
                  className="text-secondary py-[0.5rem] px-[1rem] cursor-pointer hover:text-sunset hover:bg-sunset/10 transition-all"
                >
                  <p className="font-regular text-fs-body">{translate('connect.more_options.open_full_window')}</p>
                </div>
              )}
              <div
                onClick={markAllAsRead}
                className="text-secondary py-[0.5rem] px-[1rem] cursor-pointer hover:text-sunset hover:bg-sunset/10 transition-all"
              >
                <p className="font-regular text-fs-body">{translate('notification.mark_all_as_read')}</p>
              </div>
              <div
                onClick={clearAllNotification}
                className="text-secondary py-[0.5rem] px-[1rem] cursor-pointer hover:text-sunset hover:bg-sunset/10 transition-all"
              >
                <p className="font-regular text-fs-body">{translate('common_notification_clearAll')}</p>
              </div>
              <div
                onClick={() => openFilter?.()}
                className="text-secondary py-[0.5rem] px-[1rem] cursor-pointer hover:text-sunset hover:bg-sunset/10 transition-all"
              >
                <p className="font-regular text-fs-body">{translate('common_notification_filter')}</p>
              </div>
            </div>
          )}
        >
          <div
            className="flex flex-row gap-[0.25rem] max-w-full"
            onClick={e => {
              e.stopPropagation();
              setIsOpen(prevOpen => !prevOpen);
            }}
          >
            <ResponsiveIcon
              name={IconNames.optionsRegular}
              baseHeight={24}
              baseWidth={24}
              iconClasses="hover:fill-zinc-400 dark:hover:fill-zinc-200 fill-zinc-600-400 rotate-90"
            />
          </div>
        </Popover>
      </div>
    </div>
  );
};

export default SidebarNotificationHeader;
