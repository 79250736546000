import { useMutation } from '@apollo/client';
import { CANCEL_MUTATION, CLOSEREG_MUTATION, OPENREG_MUTATION, START_MUTATION } from '@fe-monorepo/data-access';
import { useCallback, useEffect, useState } from 'react';

import {
  CancelTournamentParamsModel,
  CloseRegTournamentParamsModel,
  MutationResponse,
  OpenRegTournamentParamsModel,
  StartTournamentParamsModel,
} from './type';

//START TOURNAMENT
const usePlayStart = () => {
  const [isStartSuccessful, setStartStatus] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [startErrorMessage, setStartErrorMessage] = useState<string>('');

  const [startTournament, { data: startTournamentResponse, error: errorStartTournament }] = useMutation(START_MUTATION.startTournament, {
    errorPolicy: 'all',
  });

  useEffect(() => {
    if (startTournamentResponse) {
      const result: MutationResponse = startTournamentResponse.startTournament;
      setStartStatus(result.is_successful);
      setStartErrorMessage(result.error_msg || '');
    }
    setIsLoading(false);
  }, [startTournamentResponse]);

  useEffect(() => {
    if (errorStartTournament) {
      setError(errorStartTournament.message);
    }
    setIsLoading(false);
  }, [errorStartTournament]);

  const beginTournament = useCallback(async (params: StartTournamentParamsModel) => {
    setIsLoading(true);
    await startTournament({ variables: { details: params } });
  }, [startTournament]);

  return {
    isLoading,
    error,
    beginTournament,
    isStartSuccessful,
    startErrorMessage,
  };
};

//CANCEL TOURNAMENT
const usePlayCancel = () => {
  const [isCancelSuccessful, setCancelStatus] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [cancelErrorMessage, setCancelErrorMessage] = useState<string>('');

  const [cancelTournament, { data: cancelTournamentResponse, error: errorCancelTournament }] = useMutation(
    CANCEL_MUTATION.cancelTournament,
    {
      errorPolicy: 'all',
    }
  );

  useEffect(() => {
    if (cancelTournamentResponse) {
      const result: MutationResponse = cancelTournamentResponse.cancelTournament;
      setCancelStatus(result.is_successful);
      setCancelErrorMessage(result.error_msg || '');
    }
    setIsLoading(false);
  }, [cancelTournamentResponse]);

  useEffect(() => {
    if (errorCancelTournament) {
      setError(errorCancelTournament.message);
    }
    setIsLoading(false);
  }, [errorCancelTournament]);

  const cancelTheTournament = useCallback(async (params: CancelTournamentParamsModel) => {
    setIsLoading(true);
    await cancelTournament({ variables: { details: params } });
  }, [cancelTournament]);

  return {
    isLoading,
    error,
    cancelTheTournament,
    isCancelSuccessful,
    cancelErrorMessage,
  };
};

// OPEN REGISTRATION
const usePlayOpenReg = () => {
  const [isOpenRegSuccessful, setOpenRegStatus] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [openRegErrorMessage, setOpenRegErrorMessage] = useState<string>('');

  const [openRegTournament, { data: openRegTournamentResponse, error: errorOpenRegTournament }] = useMutation(OPENREG_MUTATION.openRegTournament, {
    errorPolicy: 'all',
  });

  useEffect(() => {
    if (openRegTournamentResponse) {
      const result: MutationResponse = openRegTournamentResponse.openRegTournament;
      setOpenRegStatus(result.is_successful);
      setOpenRegErrorMessage(result.error_msg ?? '');
    }
    setIsLoading(false);
  }, [openRegTournamentResponse]);

  useEffect(() => {
    if (errorOpenRegTournament) {
      setError(errorOpenRegTournament.message);
    }
    setIsLoading(false);
  }, [errorOpenRegTournament]);

  const openRegTheTournament = useCallback(async (params: OpenRegTournamentParamsModel) => {
    setIsLoading(true);
    await openRegTournament({ variables: { details: params } });
  }, [openRegTournament]);

  return {
    isLoading,
    error,
    openRegTheTournament,
    isOpenRegSuccessful,
    openRegErrorMessage,
  };
};

// CLOSE REG

const usePlayCloseReg = () => {
  const [isCloseRegSuccessful, setCloseRegStatus] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [closeRegErrorMessage, setCloseRegErrorMessage] = useState<string>('');

  const [closeRegTournament, { data: closeRegTournamentResponse, error: errorCloseRegTournament }] = useMutation(CLOSEREG_MUTATION.closeRegTournament, {
    errorPolicy: 'all',
  });

  useEffect(() => {
    if (closeRegTournamentResponse) {
      const result: MutationResponse = closeRegTournamentResponse.closeRegTournament;
      setCloseRegStatus(result.is_successful);
      setCloseRegErrorMessage(result.error_msg ?? '');
    }
    setIsLoading(false);
  }, [closeRegTournamentResponse]);

  useEffect(() => {
    if (errorCloseRegTournament) {
      setError(errorCloseRegTournament.message);
    }
    setIsLoading(false);
  }, [errorCloseRegTournament]);

  const closeRegTheTournament = useCallback(async (params: CloseRegTournamentParamsModel) => {
    setIsLoading(true);
    await closeRegTournament({ variables: { details: params } });
  }, [closeRegTournament]);

  return {
    isLoading,
    error,
    closeRegTheTournament,
    isCloseRegSuccessful,
    closeRegErrorMessage,
  };
};

export { usePlayCancel, usePlayCloseReg, usePlayOpenReg, usePlayStart };
