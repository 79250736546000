import { useAccount } from '@fe-monorepo/hooks';
import {
  PageErrorTypes,
  clearCart,
  clearDeliveryAddress,
  clearInvoice,
  clearPreferences,
  clearUser,
  setIsGuestUser,
  setPersona,
  setValidateAccount,
  useAppDispatch,
} from '@fe-monorepo/store';
import convivaHelper from '@fe-web/helpers/convivaHelper';
import mixpanelHelper from '@fe-web/helpers/mixpanelHelper';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { AppRoutes } from '../app.routes.enum';
import { useAppProvider } from './AppContext';

interface SessionManagerProps {
  children: React.ReactNode;
}

const SessionManager = (props: SessionManagerProps) => {
  const { children } = props;
  const dispatch = useAppDispatch();
  const navigation = useNavigate();
  const { pathname } = useLocation();
  const { pageError, setPageError } = useAppProvider();
  const { setLogout } = useAccount();

  useEffect(() => {
    if (pageError === PageErrorTypes.SESSION_EXPIRED) {
      setPageError?.(undefined);
      dispatch(clearCart());
      dispatch(clearInvoice());
      dispatch(clearDeliveryAddress());
      setLogout(true);
      dispatch(clearUser());
      dispatch(setValidateAccount(false));
      dispatch(clearPreferences());
      dispatch(setIsGuestUser(false));
      dispatch(setPersona('guest'));
      mixpanelHelper.reset();
      convivaHelper.setIdentity('GUEST');
      localStorage.removeItem('selectedTimezone');
      localStorage.removeItem('selectedCurrency');
      localStorage.removeItem('selectedCountry');
      navigation(`${AppRoutes.authSignIn}?sessionExpired=true&redirect_url=${pathname}`, { replace: true });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageError]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default SessionManager;
