import { IMAGES } from '@fe-monorepo/assets';
import { IMAGE_ALT_LABELS, IconNames, PAGE_DESCRIPTION, PAGE_KEYWORDS, PAGE_TITLE, TxKeyPath } from '@fe-monorepo/helper';
import { ContentType, useAccount, useBlock, useMediaUpload, useReport, useToastMessage, useTranslate } from '@fe-monorepo/hooks';
import { ChatType } from '@fe-monorepo/models';
import { RootState } from '@fe-monorepo/store';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import Image from '@fe-web/Atoms/Media/Image';
import HoverText from '@fe-web/Atoms/Text/HoverText';
import { toastWrapper } from '@fe-web/Atoms/Toast';
import ToastMessage from '@fe-web/Atoms/ToastMessage';
import FileInput from '@fe-web/Molecules/InputFields/FileInput';
import CustomHelmet from '@fe-web/Organisms/CustomHelmet/CustomHelmet';
import ReportModal from '@fe-web/Organisms/ReportModal/ReportModal';
import { IMG_RES, MAX_IMG_CHAR, MAX_IMG_SIZE } from '@fe-web/constant/constants';
import { useConnectConversationProvider, useConnectProvider } from '@fe-web/hooks/useConnect';
import { useNotificationProvider } from '@fe-web/hooks/useNotification';
import _ from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ZIMConversationNotificationStatus } from 'zego-zim-web';

import { AppRoutes } from '../../../../app.routes.enum';
import GroupPreferenceFullView from '../../components/GroupPreference/GroupPreferenceFullView';
import MoreOption from '../../components/MoreOption/MoreOption';
import OptionsActionModal from '../../components/OptionsActionModal/OptionsActionModal';
import MessageContent from './MessageContent';

interface Props {
  onClose?: () => void;
  isMinimized?: boolean;
  goToMinimizedPreference?: () => void;
  inviteUsers?: () => void;
  setIsInviteUser?: (value: boolean) => void;
  handleChatMinimized?: () => void;
  isChatMinimized?: any;
  setIsChatWindowModalOpen?: (chatWindowModalOpen: boolean) => void;
  inviteFromMiniSideBar?: boolean;
}

interface ChatInputProps {
  handleSendMessage?: (message: string) => void;
  onChatInputFocused?: () => void;
  isMaxedOut?: boolean;
  isMinimized?: boolean;
}

const ChatInput = (props: ChatInputProps) => {
  const { onChatInputFocused } = props;
  const { translate } = useTranslate();
  const [message, setMessage] = useState('');
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [imageInput, setImageInput] = useState<File>();
  const [imageRender, setImageRender] = useState<string | ArrayBuffer | null | undefined>();
  const { errorToastMessage } = useToastMessage();
  const imageUpload = useMediaUpload();
  const [isLoading, setIsLoading] = useState<boolean>();
  const [isCharLimitExceeded, setIsCharLimitExceeded] = useState<boolean>();
  const { onSendMediaMessage, onSendMessage } = useConnectConversationProvider();

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newMessage = event.target.value;
    setMessage(newMessage);
    if (newMessage.length > 500) {
      setIsCharLimitExceeded(true);
    } else if (isCharLimitExceeded) {
      setIsCharLimitExceeded(false);
    }
  };

  const handleClickSendMessage = async () => {
    if (imageInput) {
      save(imageInput, imageRender as string);
      return;
    }
    props.handleSendMessage ? props.handleSendMessage(message) : await onSendMessage?.({ message: message, type: 1 });
    setMessage('');
  };

  const onFocusChatInput = () => {
    // This is the unread message trigger
    onChatInputFocused?.();
  };

  const save = (imageFile: File, path: string) => {
    imageUpload.getGeneratedLinks([{ file: imageFile, path: path }], Date.now().toString(), ContentType.connect);
  };

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'inherit';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [message]);

  useEffect(() => {
    const sendMediaMessage = async () => {
      if (imageUpload.mediaLinks.length > 0) {
        setIsLoading(true);
        const fileDownloadUrl = _.last(imageUpload.mediaLinks);
        let message = {
          type: 11,
          fileDownloadUrl,
        };
        await onSendMediaMessage?.(message);
        setImageInput(undefined);
        setImageRender(undefined);
        setIsLoading(false);
      }
    };
    sendMediaMessage();
  }, [imageUpload.mediaLinks]);

  type ErrorKey = 'error_max_file_size_description' | 'error_max_file_size_title' | 'error_max_file_name_description';

  const showErrorToast = (descriptionKey?: ErrorKey, messageKey?: ErrorKey) => {
    errorToastMessage({
      message: (
        <ToastMessage type="error">
          {messageKey && <span>{translate(messageKey)}</span>}
          {descriptionKey && <span className="font-regular">{translate(descriptionKey)}</span>}
        </ToastMessage>
      ),
      className: 'flex gap-[0.5rem] w-[36.75rem] max-w-full',
      useDefaultClassNames: false,
    });
  };

  return (
    <div className="pl-[1.25rem] pr-[1rem] pb-[1rem] sticky bottom-0 w-full h-auto items-end gap-[.75rem] flex">
      <div className="relative">
        <FileInput
          uploadContainerStyle="flex"
          onDropAccepted={(files: Array<File>) => {
            if (files?.[0]) {
              const file = files[0];
              setImageInput(file);
              const size = file?.size;
              const fileName = file?.name;
              const nameWithoutExtension = fileName?.split('.').slice(0, -1).join('.');

              if (!size || size / IMG_RES > MAX_IMG_SIZE) {
                showErrorToast('error_max_file_size_description', 'error_max_file_size_title');
                return;
              } else if (nameWithoutExtension.length > MAX_IMG_CHAR) {
                showErrorToast('error_max_file_name_description');
                return;
              } else {
                setImageInput(file);
              }

              const reader = new FileReader();
              reader.onload = event => setImageRender(event?.target?.result);
              reader.readAsDataURL(file);
            }
          }}
          customInput={
            <button>
              <ResponsiveIcon
                name={IconNames.gallery}
                baseWidth={20}
                baseHeight={20}
                className={`${props.isMinimized ? 'py-[0.75rem]' : 'py-[0.5rem]'}`}
                iconClasses={`${
                  props.isMinimized ? 'w-[1rem] h-[1rem]' : 'w-[1.25rem] h-[1.25rem]'
                } fill-neutral-400-zinc-500 hover:fill-secondary`}
              />
            </button>
          }
          type="image"
        />
      </div>
      <div className="min-h-[2.375rem] py-[0.5rem] px-[0.75rem] bg-zinc-100-neutral-800 rounded gap-3 flex w-full flex-col gap-[0.75rem]">
        <div className="flex justify-start items-end gap-[0.75rem]">
          <div className="flex-1 flex flex-col gap-[0.75rem]">
            {imageRender && !isLoading && !imageUpload.isLoading ? (
              <div className="relative w-[2.5rem] h-[2.5rem]">
                <Image img={imageRender} imgStyle="w-full h-full object-cover" divStyle="w-full h-full rounded-[0.25rem] overflow-hidden" />
                <button
                  className="absolute top-[-5px] right-[-8px] p-[0.25rem] rounded-full bg-zinc-800-gray-200"
                  onClick={() => {
                    setImageInput(undefined);
                    setImageRender(undefined);
                  }}
                >
                  <ResponsiveIcon name={IconNames.close1} baseWidth={7} baseHeight={7} className="fill-secondary" />
                </button>
              </div>
            ) : (
              <textarea
                ref={textareaRef}
                className={`${
                  !message ? 'max-h-[1.0938rem]' : ''
                } max-h-[4.375rem] min-h-[1.0938rem] text-secondary text-fs-body-small font-regular leading-tight bg-transparent outline-none w-[100%] resize-none`}
                placeholder={translate('start_typing') ?? undefined}
                rows={1}
                style={{ overflow: 'hidden', overflowWrap: 'break-word' }}
                value={message}
                onChange={handleInputChange}
                onFocus={onFocusChatInput}
                disabled={!!imageInput}
                onKeyDown={e => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                    handleClickSendMessage();
                  }
                }}
              />
            )}
            {isCharLimitExceeded && (
              <div className="inline-flex gap-[0.5rem] text-yellow font-regular text-fs-caption">
                <ResponsiveIcon name={IconNames.error} baseWidth={16} baseHeight={16} iconClasses="fill-yellow" />
                <span>{translate('error_character_limit_500')}</span>
              </div>
            )}
          </div>
          <button
            onClick={handleClickSendMessage}
            className="relative disabled:fill-gray fill-sunset"
            disabled={props.isMaxedOut || isLoading || imageUpload.isLoading || isCharLimitExceeded || !(imageInput || message)}
          >
            <ResponsiveIcon name={IconNames.sendMessage} baseWidth={20} baseHeight={20} className="rtl:rotate-180" />
          </button>
        </div>
      </div>
    </div>
  );
};

const CannotChat = () => {
  const { translate } = useTranslate();
  return (
    <div className="p-[1rem] border-t-[0.0625rem]">
      <div className="text-center">
        <div className="text-zinc-600-400 text-fs-caption font-medium">{translate('chat.read_only')}</div>
        <span className="text-zinc-600-400 text-fs-caption font-regular">{translate('chat.not_participant')}</span>
      </div>
    </div>
  );
};

const BlockingChat = () => {
  const { translate } = useTranslate();
  const { crmCommunityUnblock } = useBlock();
  const { setIsBlocking, conversationID, displayName } = useConnectConversationProvider();

  const toggleUnblock = () => {
    if (conversationID) {
      crmCommunityUnblock({ username: conversationID }).then(res => {
        if (res?.crmCommunityUnblock.is_successful) {
          toastWrapper('success', translate('msg_success_user_unblocked', { username: displayName }) ?? '');
          setIsBlocking && setIsBlocking(false);
        } else toastWrapper('error', translate('msg_failed_generic_try_again') ?? '');
      });
    }
  };

  return (
    <div className="p-[1rem] border-t-[0.0625rem]">
      <div className="flex flex-col items-center text-center">
        <div className="text-zinc-600-400 text-fs-caption font-medium">{translate('connect.user_blocking')}</div>
        <span className="text-zinc-600-400 text-fs-caption font-regular">{translate('connect.user_blocking_message')}</span>
        <HoverText
          className="font-regular text-sunset text-fs-body flex mt-[0.625rem] tracking-[-0.0125rem] right-0"
          hover={{ color: 'text-sunset' }}
          alwaysShowUnderline={false}
          underlineStyle="bg-sunset h-[1px] 2K:h-[1.77px] 4K:h-[2.66px] 8K:h-[5.33px] bottom-2"
          text={translate('action_unblock') ?? ''}
          onClick={() => toggleUnblock()}
        />
      </div>
    </div>
  );
};

const BlockedChat = () => {
  const { translate } = useTranslate();
  return (
    <div className="p-[1rem] border-t-[0.0625rem]">
      <div className="text-center">
        <div className="text-zinc-600-400 text-fs-caption font-medium">{translate('connect.user_blocked')}</div>
        <span className="text-zinc-600-400 text-fs-caption font-regular">{translate('connect.user_blocked_message')}</span>
      </div>
    </div>
  );
};

const NoMessage = ({
  onClose,
  isMinimized = false,
  goToMinimizedPreference,
  inviteUsers,
  setIsInviteUser,
  handleChatMinimized,
  isChatMinimized,
  setIsChatWindowModalOpen,
  inviteFromMiniSideBar,
}: Props) => {
  const { translate } = useTranslate();
  const [isChatEmpty, setIsChatEmpty] = useState(false); //modify for integration of clear chat
  const [isActionOptionOpen, setIsActionOptionOpen] = useState(false); //open clear chat modal
  const [optionActionType, setOptionActionType] = useState<'clear' | 'block' | 'delete' | 'leave' | 'dismiss' | 'report'>('clear');
  const [showReportModal, setShowReportModal] = useState<boolean>(false);
  const [openGroupPreferences, setOpenGroupPreferences] = useState<boolean>(false);
  const { createTheReport } = useReport();
  const { successToastMessage, errorToastMessage } = useToastMessage();
  const navigate = useNavigate();
  const { crmCommunityUnblock } = useBlock();
  const {
    conversationID,
    conversationType,
    displayName,
    avatarUrl,
    userAdmin,
    isBlocked,
    isBlocking,
    setIsBlocking,
    messageList,
    isDismissed,
    onSendMessageReceipt,
  } = useConnectConversationProvider();
  const { getUserInfoByUsername } = useAccount();
  const {
    queryGroupMemberInfo,
    openChat,
    queryConversationNotificationStatus,
    queryConversation,
    queryGroupMemberCount,
    clearConversationUnreadMessageCount,
    isConversationListLoaded,
  } = useConnectProvider();
  const { setIsModalOpen } = useNotificationProvider();
  const userState = useSelector((state: RootState) => state.user.userContext);
  const [isUserMember, setIsUserMember] = useState<boolean>(true);
  const [muteStatus, setMuteStatus] = useState<ZIMConversationNotificationStatus | undefined>();
  const [groupCount, setGroupCount] = useState<number>(0);
  const username = useSelector((state: RootState) => state.user.userContext.username);

  useEffect(() => {
    const modalCheck = isActionOptionOpen || showReportModal;
    setIsModalOpen?.(modalCheck);
  }, [isActionOptionOpen, showReportModal]);

  const handleAction = () => {
    //MODIFY FOR INTEGRATION
    setIsActionOptionOpen(false);
  };

  const handleSubmitReport = (code: string, content: string) => {
    createTheReport({
      flag_code: code,
      reference_content_id: displayName ?? '', //Change conversation ID here on API Integration.
      reference_content_type: 'chat',
      flag_content: content,
      reported_username: conversationID ?? '',
    }).then(res => {
      res?.createReport.is_successful
        ? successToastMessage({
            message: <ToastMessage type="success">{translate('msg_success_user_report', { username: displayName })}</ToastMessage>,
          })
        : errorToastMessage({
            message: <ToastMessage type="error">{translate('msg_failed_generic_try_again')}</ToastMessage>,
          });
    });
    setShowReportModal(false);
    setIsChatWindowModalOpen?.(false);
  };

  const handleClickMoreOption = async (groupID: string, chatType: number) => {
    if (chatType === 2) {
      let isUserAdmin = queryGroupMemberInfo && (await queryGroupMemberInfo(userState.username, groupID));
      setIsUserMember(isUserAdmin ? true : false);
    }
  };

  useEffect(() => {
    if (conversationID && conversationType === 2 && isConversationListLoaded) {
      queryGroupMemberInfo?.(userState.username, conversationID).then(res => {
        setIsUserMember(res !== undefined ? true : false);
      });
      queryConversation?.(conversationID, conversationType).then(res => {
        if (res?.conversation) {
          setMuteStatus(res?.conversation.notificationStatus);
        }
      });
    }
  }, [isConversationListLoaded]);

  useEffect(() => {
    if (conversationID) {
      queryGroupMemberCount &&
        queryGroupMemberCount(conversationID).then(response => {
          if (response && displayName && displayName.length >= 8) {
            const count = response.count - displayName?.substring(0, 8).split(',').length;
            setGroupCount(count);
          }
        });
    }
  }, [displayName]);

  useEffect(() => {
    if (inviteFromMiniSideBar) {
      inviteUsers?.();
    }
  }, [inviteFromMiniSideBar]);

  const handleOpenGroupPreferences = (isMinimized: boolean) => {
    if (!isMinimized) {
      setOpenGroupPreferences(true);
    } else {
      goToMinimizedPreference && goToMinimizedPreference();
    }
  };

  const handleInviteUsers = () => {
    if (isMinimized) {
      inviteUsers && inviteUsers();
    } else {
      setIsInviteUser && setIsInviteUser(true);
    }
  };

  const goToProfile = (userID: string) => {
    if (conversationType !== 2) {
      navigate(AppRoutes.users + `/${userID}`);
    }
  };

  const changeChatSize = () => {
    if (isMinimized) {
      navigate(AppRoutes.messages + `/${conversationID}/${conversationType}`);
    } else {
      openChat && openChat({ type: ChatType.EXISTING, conversationID, conversationType });
      navigate(AppRoutes.home);
    }
  };

  const onCloseChat = async () => {
    if (isMinimized) {
      onClose && onClose();
    } else {
      navigate(AppRoutes.messages);
    }
    const unreadMessagesList = messageList?.filter(item => item.receiptStatus === 1 && item.senderUserID !== username);
    conversationID && clearConversationUnreadMessageCount?.(conversationID, conversationType || 0);
    unreadMessagesList && onSendMessageReceipt?.(unreadMessagesList);
  };

  const handleClickMute = (status: ZIMConversationNotificationStatus, conversationID: string, conversationType: number) => {
    if (conversationID) {
      queryConversationNotificationStatus?.(status, conversationID, conversationType)?.then(res => {
        if (res && res.conversationID) {
          setMuteStatus(status);
        }
      });
    }
  };

  const changeChatBubble = () => {
    handleChatMinimized && handleChatMinimized();
  };

  const unblock = (username: string) => {
    crmCommunityUnblock({ username: username }).then(res => {
      if (res?.crmCommunityUnblock.is_successful) {
        toastWrapper('success', translate('msg_success_user_unblocked', { username: displayName }) ?? '');
        setIsBlocking?.(false);
      } else {
        toastWrapper('error', translate('msg_failed_generic_try_again') ?? '');
      }
    });
  };

  const chatRender = useMemo(() => {
    return (
      isConversationListLoaded && (
        <>
          {isBlocking ? (
            <BlockingChat />
          ) : isBlocked ? (
            <BlockedChat />
          ) : (conversationType !== 2 || isUserMember) && !isDismissed ? (
            <ChatInput isMinimized={isMinimized} />
          ) : (
            <CannotChat />
          )}
        </>
      )
    );
  }, [isConversationListLoaded, isBlocking, isBlocked, conversationType, isUserMember, isDismissed]);

  useEffect(() => {
    getUserInfoByUsername({ username: displayName });
  }, [displayName]);

  return (
    <>
      {!isMinimized && (
        <CustomHelmet
          pageTitle={`${translate(PAGE_TITLE.connect as TxKeyPath) ?? ''} ${
            displayName && displayName.length > 0 ? `- ${displayName}` : ''
          }`}
          metaDescription={translate(PAGE_DESCRIPTION.connect as TxKeyPath) ?? ''}
          metaKeyWords={translate(PAGE_KEYWORDS.connect as TxKeyPath) ?? ''}
        />
      )}
      <OptionsActionModal
        isOpen={isActionOptionOpen}
        isClose={() => {
          setIsActionOptionOpen(false);
          setIsChatWindowModalOpen?.(false);
        }}
        type={optionActionType}
        handleAction={handleAction}
        isGroupChat={conversationType === 2}
        userName={displayName}
        setIsChatEmpty={() => setIsChatEmpty(true)}
        conversationID={conversationID}
        isMinimized={isMinimized}
        setIsBlocking={() => setIsBlocking?.(true)}
        userID={conversationID}
      />
      <GroupPreferenceFullView isGroupPreferenceOpen={openGroupPreferences} isGroupPreferenceClose={() => setOpenGroupPreferences(false)} />
      <div
        className={`max-h-[4.25rem] min-h-[4rem] ${isMinimized && isChatMinimized[conversationID ?? ''] ? 'p-[.5rem]' : 'p-[1rem]'} ${
          isMinimized
            ? 'border-b-[0.0625rem] border-neutral-300-zinc-700'
            : 'border-t-[.1rem] border-r-[.1rem] border-l-[.1rem] rounded-t-[.5rem] border-secondary/25'
        }`}
      >
        <div className="sticky top-0 h-[5%] px-4 pt-4 flex-col justify-start items-start gap-3 inline-flex w-full">
          <div className="self-stretch justify-between items-start inline-flex">
            <div className="grow shrink basis-0 h-9 justify-start items-center gap-[0.5rem] flex">
              <div className="relative hover:cursor-pointer" onClick={() => goToProfile(conversationID ?? '')}>
                {conversationType === 2 ? (
                  <div className="flex items-center justify-center h-40 w-40 object-cover bg-lightBlue/[0.2] border border-gray-200-zinc-700 rounded-full overflow-hidden 2K:h-80 2K:w-80 4K:h-100 4K:w-100 8K:h-200 8K:w-200">
                    {avatarUrl ? (
                      <Image
                        img={avatarUrl}
                        alt={translate(IMAGE_ALT_LABELS.chat_window_avatar as TxKeyPath) ?? ''}
                        divStyle={
                          'h-40 w-40 bg-black/[0.2] border border-gray-200-zinc-700 rounded-full overflow-hidden 2K:h-80 2K:w-80 4K:h-140 4K:w-140 8K:h-200 8K:w-200'
                        }
                        imgStyle="h-40 w-40 2K:h-80 2K:w-80 4K:h-140 4K:w-140 8K:h-200 8K:w-200 object-cover"
                      />
                    ) : (
                      <ResponsiveIcon
                        name={IconNames.icon_chat_group}
                        baseWidth={25}
                        baseHeight={25}
                        iconClasses="fill-lightBlue"
                        className="flex items-center justify-center h-40 w-40 2K:h-80 2K:w-80 4K:h-140 4K:w-140 8K:h-170 8K:w-170 object-cover"
                      />
                    )}
                  </div>
                ) : (
                  <Image
                    img={avatarUrl ?? IMAGES.Avatar}
                    alt={translate(IMAGE_ALT_LABELS.chat_window_avatar as TxKeyPath) ?? ''}
                    divStyle={
                      'flex items-center justify-center h-40 w-40 object-cover bg-lightBlue/[0.2] border border-gray-200-zinc-700 rounded-full overflow-hidden 2K:h-80 2K:w-80 4K:h-100 4K:w-100 8K:h-200 8K:w-200'
                    }
                    imgStyle="2K:h-80 2K:w-80 4K:h-100 4K:w-100 8K:h-200 8K:w-200"
                  />
                )}
              </div>
              <div className="flex grow shrink basis-0 flex-col justify-start items-start">
                <div className={`self-stretch justify-start items-center gap-[0.25rem] inline-flex ${isMinimized && 'max-w-[8.125rem]'}`}>
                  <MoreOption
                    isMuted={muteStatus === 2}
                    onClickMute={() =>
                      conversationID && conversationType && handleClickMute(muteStatus === 1 ? 2 : 1, conversationID, conversationType)
                    }
                    setOpenGroupPreferences={() => handleOpenGroupPreferences(isMinimized)}
                    chatType={conversationType === 2 ? 'group' : conversationType === 0 ? 'private' : 'private'}
                    origin={`${isMinimized ? 'minimized' : 'full'}`}
                    userType={conversationType !== 2 ? 'regular' : userAdmin === 1 ? 'admin' : 'regular'}
                    isIconDot={false}
                    isChatEmpty={isChatEmpty}
                    setOptionActionType={type => {
                      setOptionActionType(type);
                      if (type === 'report') {
                        setShowReportModal(true);
                        setIsChatWindowModalOpen?.(true);
                      } else {
                        setIsActionOptionOpen(true);
                        setIsChatWindowModalOpen?.(true);
                      }
                    }}
                    goToProfile={() => goToProfile(conversationID ?? '')}
                    onClickMoreOption={() =>
                      conversationID && conversationType ? handleClickMoreOption(conversationID, conversationType) : null
                    }
                    openFullWindow={() => navigate(AppRoutes.messages + `/${conversationID}/${conversationType}`)}
                    isUserMember={conversationType === 2 ? isUserMember : true}
                    isBlocking={isBlocking}
                    conversationID={conversationID}
                    unblock={unblock}
                    setInviteUsers={handleInviteUsers}
                    cta={
                      <div
                        className={`flex flex-row gap-[.1rem] text-secondary font-regular text-fs-body-small hover:cursor-pointer ${
                          isMinimized ? (groupCount > 0 ? 'max-w-[70%]' : 'max-w-[50%]') : ''
                        }`}
                      >
                        <p className="truncate">{displayName}</p>
                        {groupCount > 0 && <p>{`+${groupCount}`}</p>}
                      </div>
                    }
                  />
                </div>
              </div>
              <div className="flex flex-row gap-[.5rem] !content-start">
                {isMinimized && (
                  <ResponsiveIcon
                    name={IconNames.icon_chat_minimize_underline}
                    baseWidth={12}
                    baseHeight={12}
                    onClick={changeChatBubble}
                    className="transition-all fill-neutral-400-zinc-500 hover:fill-secondary flex flex-col justify-end hover:cursor-pointer"
                  />
                )}
                <ResponsiveIcon
                  name={isMinimized ? IconNames.icon_chat_maximize : IconNames.icon_chat_minimize}
                  baseWidth={isMinimized ? 15 : 20}
                  baseHeight={isMinimized ? 15 : 20}
                  onClick={changeChatSize}
                  className="transition-all fill-neutral-400-zinc-500 hover:fill-secondary hover:cursor-pointer"
                />
                <ResponsiveIcon
                  name={IconNames.close}
                  baseWidth={isMinimized ? 12 : 15}
                  baseHeight={isMinimized ? 12 : 15}
                  className="transition-all fill-neutral-400-zinc-500 hover:fill-secondary hover:cursor-pointer pt-[.1rem]"
                  onClick={() => onCloseChat()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isMinimized ? (
        <div
          className={`flex flex-col justify-between overflow-y-auto ${
            isMinimized ? 'h-[17rem]' : 'h-[70vh] border-[.1rem] rounded-b-[.5rem] border-secondary/25'
          }`}
        >
          <MessageContent />
          {chatRender}
        </div>
      ) : !isChatMinimized[conversationID ?? ''] ? (
        <div
          className={`flex flex-col justify-between overflow-y-auto ${
            isMinimized ? 'h-[17rem]' : 'h-[70vh] border-[.1rem] rounded-b-[.5rem] border-secondary/25'
          }`}
        >
          <MessageContent isMinimized={isMinimized} />
          {chatRender}
        </div>
      ) : null}
      <ReportModal
        setIsReportModalOpen={setShowReportModal}
        handleSubmitReportButton={handleSubmitReport}
        title={translate('chat.report_user.header_title', { name: displayName }) ?? ''}
        isOpen={showReportModal}
        subtitle={translate('chat.report_user.subtitle') ?? ''}
      />
    </>
  );
};

export default NoMessage;

export { CannotChat, ChatInput };
