import { ApolloProvider } from '@apollo/client';
import { PageErrorTypes } from '@fe-monorepo/store';
import { getEnvironment } from '@fe-web/constant/environment';
import { apolloClientUpload } from 'libs/data-access/src/graphql/api';
import { ReactElement, createContext, useCallback, useContext, useState } from 'react';

interface PageErrorProviderModel {
  pageError?: PageErrorTypes;
  setPageError?: React.Dispatch<React.SetStateAction<PageErrorTypes | undefined>>;
}

export const AppContext = createContext<PageErrorProviderModel>({});

interface CustomProviderProps {
  children: ReactElement;
}

export function CustomApolloProvider({ children }: CustomProviderProps) {
  const { pageError, setPageError } = useContext(AppContext);
  const onPageError = useCallback(
    (error: PageErrorTypes, isNetworkError?: boolean, isOnline?: boolean) => {
      if (typeof isOnline !== 'undefined' && !isOnline) {
        setPageError?.(PageErrorTypes.OFFLINE);
        return;
      }
      if (isNetworkError && isOnline) {
        setPageError?.(PageErrorTypes.SERVER_DOWN);
        return;
      }
      if (!pageError) {
        setPageError?.(error);
      }
    },
    [pageError],
  );
  return <ApolloProvider client={apolloClientUpload(getEnvironment, 'web', onPageError)}>{children}</ApolloProvider>;
}

export const useAppProvider = () => {
  const context = useContext(AppContext);
  if (!context) throw 'Please use a provider to use this hook';
  return context;
};

interface AppProviderProps {
  children: ReactElement;
}

export function AppProvider({ children }: AppProviderProps) {
  const [pageError, setPageError] = useState<PageErrorTypes>();
  return <AppContext.Provider value={{ pageError, setPageError }}>{children}</AppContext.Provider>;
}
