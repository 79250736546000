import { useLazyQuery } from '@apollo/client';
import { CHAT_SEARCH_QUERIES } from '@fe-monorepo/data-access';

import { useMemo } from 'react';
import { GetChatInfoResponse, GetSearchInfoParams } from './types';

export const useChatSearch = () => {
  const [getSearchInfoGql, { data: getSearchResponse, loading }] = useLazyQuery<GetChatInfoResponse>(CHAT_SEARCH_QUERIES.getSearch, {
    errorPolicy: 'all',
  });

  const { isSuccessful, errorMessage } = useMemo(() => ({
    isSuccessful: !!getSearchResponse?.getSearch?.is_successful,
    errorMessage: getSearchResponse?.getSearch?.error_msg
  }), [getSearchResponse]);

  const getSearch = async (chatInput: GetSearchInfoParams) => {
    const { data } = await getSearchInfoGql({
      variables: {
        details: chatInput,
      },
    });

    return data?.getSearch?.data;
  };

  return {
    getSearch,
    getSearchResponse,
    isSuccessful,
    isLoading: loading,
    errorMessage,
  };
};
