import { IconNames } from '@fe-monorepo/helper';
import { useToastMessage, useTranslate } from '@fe-monorepo/hooks';
import { RootState } from '@fe-monorepo/store';
import { getStyle } from '@fe-monorepo/themes';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import Image from '@fe-web/Atoms/Media/Image';
import ToastMessage from '@fe-web/Atoms/ToastMessage';
import { DROPZONE_ACCEPT_IMAGES, IMG_RES, MAX_IMG_CHAR, MAX_IMG_SIZE } from '@fe-web/constant/constants';
import { ReactNode, Ref, forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { DropEvent, useDropzone } from 'react-dropzone';
import { useSelector } from 'react-redux';

interface FileInputProps {
  type?: 'image';
  label?: ReactNode;
  showFileName?: boolean;
  imgContainerStyle?: string;
  imgStyle?: string;
  uploadContainerStyle?: string;
  displayContainerStyle?: string;
  resetButtonStyle?: string;
  iconStyle?: string;
  labelClassName?: string;
  buttonLabel?: ReactNode;
  resetIcon?: IconNames;
  customInput?: ReactNode;
  setValue?: (file: any) => void;
  setValueRender?: (fileRender: string | ArrayBuffer | null | undefined) => void;
  setReset?: (reset: boolean) => void;
  onDropAccepted?: (<T extends File>(files: T[], event: DropEvent) => void) | undefined;
}

const FileInput = (
  {
    type,
    label,
    buttonLabel,
    setValue,
    labelClassName,
    imgContainerStyle,
    imgStyle,
    uploadContainerStyle,
    displayContainerStyle,
    resetButtonStyle,
    iconStyle,
    showFileName = true,
    resetIcon,
    customInput,
    setReset,
    setValueRender,
    onDropAccepted,
  }: FileInputProps,
  ref: Ref<any>,
) => {
  const themes = useSelector((state: RootState) => state.app.themes);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [image, setImage] = useState<string | ArrayBuffer | null | undefined>();
  const [file, setFile] = useState<File>();
  const { errorToastMessage } = useToastMessage();
  const { translate } = useTranslate();

  type ErrorKey =
    | 'error_invalid_format'
    | 'error_invalid_format_description'
    | 'error_max_file_size_description'
    | 'error_max_file_size_title'
    | 'error_max_file_name_description';

  const showErrorToast = (descriptionKey?: ErrorKey, messageKey?: ErrorKey) => {
    errorToastMessage({
      message: (
        <ToastMessage type="error">
          {messageKey && <span>{translate(messageKey)}</span>}
          {descriptionKey && <span className="font-regular">{translate(descriptionKey)}</span>}
        </ToastMessage>
      ),
      className: 'flex gap-[0.5rem] w-[36.75rem] max-w-full',
      useDefaultClassNames: false,
    });
  };
  const onDropRejected = useCallback(() => {
    showErrorToast('error_invalid_format_description', 'error_invalid_format');
    return;
  }, []);

  const handleOnDrop = useCallback((acceptedFiles: Array<File>) => {
    const file = new FileReader();
    const imgFile = acceptedFiles[0];
    const size = imgFile?.size;
    const fileName = imgFile?.name;
    const nameWithoutExtension = fileName?.split('.').slice(0, -1).join('.');

    if (!size || size / IMG_RES > MAX_IMG_SIZE) {
      showErrorToast('error_max_file_size_description', 'error_max_file_size_title');
      return;
    }

    if (nameWithoutExtension.length > MAX_IMG_CHAR) {
      showErrorToast('error_max_file_name_description');
      return;
    }

    setFile(imgFile);

    file.onload = function () {
      const isValid = size / IMG_RES < MAX_IMG_SIZE && fileName.length < MAX_IMG_CHAR;
      if (isValid) setImage(file.result);
    };

    file.readAsDataURL(imgFile);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDropRejected,
    onDropAccepted: onDropAccepted ? onDropAccepted : handleOnDrop,

    accept: type === 'image' ? DROPZONE_ACCEPT_IMAGES : undefined,
  });

  const onReset = useCallback(() => {
    const current = fileInputRef.current;
    if (current) {
      current.value = '';
      setImage(null);
      setFile(undefined);
    }
    setReset && setReset(true);
  }, [fileInputRef]);

  const onClickInput = () => fileInputRef.current?.click();

  useImperativeHandle(ref, () => ({
    onReset,
  }));

  useEffect(() => {
    setValue?.(file);
  }, [file, setFile]);

  useEffect(() => {
    setValueRender?.(image);
  }, [image]);

  return (
    <div>
      {!!file && !customInput && (
        <div className={`${displayContainerStyle ?? ''} flex gap-[1.5rem] items-center`}>
          {type === 'image' && (
            <Image
              img={image}
              imgStyle={`${imgStyle ?? ''} w-full h-full`}
              divStyle={`${imgContainerStyle ?? ''} w-full h-full max-w-[6.75rem] max-h-[4rem] object-cover overflow-hidden`}
            />
          )}
          {showFileName && (
            <span
              style={{ color: getStyle(themes)?.textColor }}
              className="flex-1 text-left font-regular text-fs-body-small rtl:text-right"
            >
              {file?.name}
            </span>
          )}
          <button className={resetButtonStyle ?? ''} type="button" onClick={onReset}>
            <ResponsiveIcon
              name={resetIcon ?? IconNames.deleteOutline}
              baseWidth={16}
              baseHeight={16}
              iconClasses={`${iconStyle ?? ''} fill-sunset`}
            />
          </button>
        </div>
      )}
      <div
        className={`${uploadContainerStyle ?? ''} ${
          !customInput
            ? `flex flex-col w-full items-center gap-[0.5rem] relative py-[1.5rem] rounded-[0.25rem] ${!!file ? 'hidden' : ''}`
            : ''
        }`}
        {...getRootProps()}
        onClick={onClickInput}
      >
        {label && <span className={`${labelClassName ?? ''} font-regular text-fs-body-small text-zinc-600`}>{label}</span>}
        <input
          {...getInputProps()}
          multiple={false}
          type="file"
          accept={type === 'image' ? 'image/jpeg, image/png, image/bmp, image/tiff, image/gif, image/webp' : undefined}
          ref={fileInputRef}
          className="absolute w-0 h-0 opacity-0 pointer-events-none touch-events-none"
        />
        <div className="inline-flex justify-center">
          {customInput ?? (
            <>
              <button type="button" className="font-regular text-fs-body text-sunset">
                {buttonLabel ?? 'Browse files'}
              </button>
              <div className="absolute top-0 w-full h-full border-dashed border-[#5D5C5EB3] border-2 rounded-[0.2188rem]" />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default forwardRef(FileInput);
