import { isEqual } from 'lodash';
import { useEffect, useRef } from 'react';

const useDeepCompareEffect = (effect: any, dependencies: any) => {
  const previousDependenciesRef = useRef();

  if (!isEqual(previousDependenciesRef.current, dependencies)) {
    previousDependenciesRef.current = dependencies;
  }

  useEffect(effect, [previousDependenciesRef.current]);
};

export default useDeepCompareEffect;
