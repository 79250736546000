import { useMutation } from '@apollo/client';
import { REPORT_MUTATION } from '@fe-monorepo/data-access';
import { useCallback, useEffect, useState } from 'react';

import { CreateReportParamsModel } from './type';

export const useReport = () => {
  const [isSuccessful, setStatus] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [errorMSg, setErrorMsg] = useState<string>('');

  const [createReport, { data: createReportResponse, error: errorCreateReport }] = useMutation(REPORT_MUTATION.createReport, {
    errorPolicy: 'all',
  });

  useEffect(() => {
    if (createReportResponse) {
      const { is_successful, error_msg } = createReportResponse.createReport;
      setStatus(is_successful);
      setErrorMsg(error_msg || '');
    }
    setIsLoading(false);
  }, [createReportResponse]);

  useEffect(() => {
    if (errorCreateReport) {
      setError(errorCreateReport.message);
    }
    setIsLoading(false);
  }, [errorCreateReport]);

  const createTheReport = useCallback(async (params: CreateReportParamsModel) => {
    setIsLoading(true);
    try {
      const { data } = await createReport({ variables: { details: params } });
      return data;
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }, [createReport]);


  return {
    createTheReport,
    isSuccessful,
    isLoading,
    error,
    errorMSg,
  };
};
