import { useMutation } from '@apollo/client';
import { PASSWORD_MUTATION } from '@fe-monorepo/data-access';



import { ChangePasswordParamsModel, ChangePasswordResponse } from './type';

export const useChangePassword = () => {
  const [updatePasswordGql, { data, loading: isLoading, error }] = useMutation<ChangePasswordResponse>(PASSWORD_MUTATION.updatePassword, {
    errorPolicy: 'all',
  });

  const updateThePassword = async (params: ChangePasswordParamsModel) => {
    const { data } = await updatePasswordGql({ variables: { details: params } });
    return data?.updatePassword;
  };

  return {
    updateThePassword,
    isLoading,
    error,
  };
};