import { useLazyQuery, useMutation } from '@apollo/client';
import { DISCOUNT_MUTATION, GIFTCARD_QUERY, REDEEMGIFTCARD_MUTATION, WALLET_QUERY } from '@fe-monorepo/data-access';
import { FilterModel } from '@fe-monorepo/models';
import {
  RootState,
  resetFilterData,
  setError,
  setFilterData,
  setGiftCardInfo,
  setWallet,
  setWalletHistoryList,
  useAppDispatch,
  useAppSelector,
} from '@fe-monorepo/store';
import { useCallback, useEffect, useState } from 'react';

import { useAppState } from '../useAppState/useAppState';
import { useInvoice } from '../useShop/useInvoice';
import {
  ApplyWallet,
  DiscountWalletModel,
  DiscountWalletParams,
  GiftCardInfoParam,
  GiftCardInfoParamModel,
  GiftCardInfoRespose,
  RedeemGiftCardParams,
  RedeemGiftCardParamsModel,
  RedeemGiftCardRespose,
  RemoveWallet,
  WalletHistoryResponse,
  WalletParams,
  WalletParamsModel,
  WalletResponse,
} from './types';

export const useWallet = () => {
  const dispatch = useAppDispatch();
  const { changeLoadingState } = useAppState();
  const [isGiftCardInfoValid, setGiftCardInfoStatus] = useState<boolean>(false);
  const [isRedeemed, setRedeemStatus] = useState<boolean>(false);
  const [isSuccessful, setStatus] = useState<boolean>(false);
  const [isLoadingMore, setLoadMoreStatus] = useState<boolean>(false);
  const { invoiceInfo, getInvoice } = useInvoice(true);
  const [disableLoadingState, setDisableLoadingState] = useState<boolean>(false);

  const [getWalletHistory, { data: walletHistoryResponse, error: errorWalletHistory }] = useLazyQuery<WalletHistoryResponse, WalletParams>(
    WALLET_QUERY.getWalletHistory,
    { errorPolicy: 'all' },
  );

  const [getWallet, { data: walletResponse, error: errorWallet }] = useLazyQuery<WalletResponse>(WALLET_QUERY.getWallet, {
    errorPolicy: 'all',
  });

  const [fetchGiftCardInfo, { data: getGiftCardInfoResponse, error: errorgetGiftCardInfo }] = useLazyQuery<
    GiftCardInfoRespose,
    GiftCardInfoParam
  >(GIFTCARD_QUERY.getGiftCardInfo, { errorPolicy: 'all' });

  const [postRedeemGiftCard, { data: redeemGiftCardResponse, error: errorRedeemGiftCard }] = useMutation<
    RedeemGiftCardRespose,
    RedeemGiftCardParams
  >(REDEEMGIFTCARD_MUTATION.redeemGiftCard, { errorPolicy: 'all' });

  const [applyWallet, { data: applyWalletBalanceResponse, error: errorApplyWalletBalance }] = useMutation<
    ApplyWallet,
    DiscountWalletParams
  >(DISCOUNT_MUTATION.applyWallet, { errorPolicy: 'all' });

  const [removeWallet, { data: removeWalletBalanceResponse, error: errorRemoveWalletBalance }] = useMutation<
    RemoveWallet,
    DiscountWalletParams
  >(DISCOUNT_MUTATION.removeWallet, { errorPolicy: 'all' });

  useEffect(() => {
    if (walletHistoryResponse) {
      if (walletHistoryResponse.getWalletHistory?.is_successful) {
        dispatch(setWalletHistoryList({ data: walletHistoryResponse.getWalletHistory?.data, isLoadMore: isLoadingMore }));
        dispatch(setError(walletHistoryResponse.getWalletHistory?.error_msg));
      }
      setStatus(walletHistoryResponse.getWalletHistory?.is_successful);
      setRedeemStatus(walletHistoryResponse.getWalletHistory?.is_successful);
      changeLoadingState(false);
    }
  }, [walletHistoryResponse]);

  useEffect(() => {
    if (walletResponse) {
      if (walletResponse?.getWallet?.data && walletResponse?.getWallet?.data?.length > 0) {
        dispatch(setWallet(walletResponse.getWallet.data[0]));
        getWalletHistory({ variables: { details: { wallet_number: walletResponse.getWallet.data[0].wallet_number } } });
      }
      dispatch(setError(walletResponse.getWallet?.error_msg));
    }
    changeLoadingState(false);
  }, [walletResponse]);

  useEffect(() => {
    if (getGiftCardInfoResponse) {
      dispatch(setGiftCardInfo(getGiftCardInfoResponse.getGiftCardInfo?.data));
      dispatch(setError(getGiftCardInfoResponse.getGiftCardInfo?.error_msg));
      setGiftCardInfoStatus(getGiftCardInfoResponse.getGiftCardInfo?.is_successful);
    }
    changeLoadingState(false);
  }, [getGiftCardInfoResponse]);

  useEffect(() => {
    if (redeemGiftCardResponse) {
      if (redeemGiftCardResponse?.redeemGiftCard?.is_successful) {
        !disableLoadingState && getUserWallet(disableLoadingState);
      }
    }
    !disableLoadingState && changeLoadingState(false);
  }, [redeemGiftCardResponse, disableLoadingState]);

  useEffect(() => {
    if (applyWalletBalanceResponse) {
      if (applyWalletBalanceResponse?.applyWallet?.is_successful) {
        setStatus(true);
        callGetInvoice();
      }
      changeLoadingState(false);
    }
  }, [applyWalletBalanceResponse]);

  useEffect(() => {
    if (removeWalletBalanceResponse) {
      if (removeWalletBalanceResponse?.removeWallet?.is_successful) {
        setStatus(false);
        callGetInvoice();
      }
      changeLoadingState(false);
    }
  }, [removeWalletBalanceResponse]);

  const filterWalletHistory = (params: WalletParamsModel, isLoadMore?: boolean, loadingState?: boolean) => {
    //ADDED LOAD MORE FLAG
    //FOR CHECKING IF THIS FUNCTION IS FOR LOADMORE OR FOR FILTERING
    setLoadMoreStatus(isLoadMore === true);
    getWalletHistory({
      variables: { details: params },
    });
    if (loadingState !== undefined) {
      loadingState && changeLoadingState(true);
    } else {
      changeLoadingState(true);
    }
  };

  const getUserWalletHistory = async (params: WalletParamsModel) => {
    const { data } = await getWalletHistory({
      variables: { details: params },
    });

    return data?.getWalletHistory;
  };

  const getUserWallet = (loadingState?: boolean) => {
    getWallet();
    if (loadingState !== undefined) {
      loadingState && changeLoadingState(true);
    } else {
      changeLoadingState(true);
    }
  };

  const getGiftCardInfo = (params: GiftCardInfoParamModel, loadingState?: boolean) => {
    fetchGiftCardInfo({
      variables: { details: params },
    });
    if (loadingState !== undefined) {
      loadingState && changeLoadingState(true);
    } else {
      changeLoadingState(true);
    }
  };

  const redeemGiftCard = (params: RedeemGiftCardParamsModel, disableLoadingState?: boolean) => {
    postRedeemGiftCard({ variables: { details: params } });
    !disableLoadingState && changeLoadingState(true);
    disableLoadingState && setDisableLoadingState(true);
  };

  const applyWalletBalance = async (params: DiscountWalletModel) => {
    const { data } = await applyWallet({ variables: { details: params } });
    changeLoadingState(true);
    return data?.applyWallet;
  };

  const removeWalletBalance = async (params: DiscountWalletModel) => {
    const { data } = await removeWallet({ variables: { details: params } });
    changeLoadingState(true);
    return data?.removeWallet;
  };

  const callGetInvoice = () => {
    // if (invoiceInfo?.invoice_number) {
    //     getInvoice({details: {invoice_number: invoiceInfo?.invoice_number}})
    // }
  };

  return {
    isSuccessful,
    isGiftCardInfoValid,
    isRedeemed,
    error: useAppSelector((state: RootState) => state.wallet.error),
    userWallet: useAppSelector((state: RootState) => state.wallet.userWallet),
    walletHistoryList: useAppSelector((state: RootState) => state.wallet.walletHistoryList),
    giftCardInfo: useAppSelector((state: RootState) => state.wallet.giftCardInfo),
    filterData: useAppSelector((state: RootState) => state.wallet.filterWallet),
    getUserWallet,
    filterWalletHistory,
    getGiftCardInfo,
    redeemGiftCard,
    applyWalletBalance,
    removeWalletBalance,
    setFilterData: useCallback(
      (data: FilterModel) => {
        dispatch(setFilterData(data));
      },
      [dispatch],
    ),
    resetFilter: useCallback(() => {
      dispatch(resetFilterData());
    }, [dispatch]),
    setError: useCallback(
      (error: string) => {
        dispatch(setError(error));
      },
      [dispatch],
    ),
    getUserWalletHistory,
  };
};
