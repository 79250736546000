
interface ImageURISource {
  uri?: string | undefined;
  bundle?: string | undefined;
  method?: string | undefined;
  headers?: {[key: string]: string} | undefined;
  cache?: 'default' | 'reload' | 'force-cache' | 'only-if-cached' | undefined;
  body?: string | undefined;
  width?: number | undefined;
  height?: number | undefined;
  scale?: number | undefined;
}

type ImageSourcePropType = number | ImageURISource | ImageURISource[]
export * from './fonts';

type IImages = {
  [index: string]: ImageSourcePropType;
};

type SOCIALMEDIALINKTYPE = {
  [index: string]: string;
};

type LottieIconAnimationsType = {
  [index: string]: string;
};

const IMAGES_BASE = 'images/';
const PLACEHOLDER_BASE = `${IMAGES_BASE}placeholder/`;
const SHOP_BASE = `${IMAGES_BASE}shop/`;
const MATCHMAKING_BASE = `${IMAGES_BASE}matchmaking/`;
const EWC_BASE = `${IMAGES_BASE}ewc/`;
const HOME_BASE = `${IMAGES_BASE}home/`;
const MINIGAMES_BASE = `${IMAGES_BASE}minigame/`;
const SETTINGS_BASE = `${IMAGES_BASE}settings/`;

export const IMAGES: IImages = {
  AppleStore: require(`./${IMAGES_BASE}applestore.png`),
  Avatar: require(`./${IMAGES_BASE}avatar.png`),
  BannerPlaceholder: require(`./${IMAGES_BASE}Banner Placeholder.png`),
  ChatGamer: require(`./${IMAGES_BASE}ChatGamer.png`),
  Compete: require(`./${PLACEHOLDER_BASE}Compete.png`),
  ContentCreater: require(`./${IMAGES_BASE}ContentCreater.png`),
  Discover: require(`./${PLACEHOLDER_BASE}Discover.png`),
  FirstChallenger: require(`./${IMAGES_BASE}FirstChallenger.png`),
  GamerCard: require(`./${IMAGES_BASE}GamerCard.png`),
  GamingController: require(`./${IMAGES_BASE}GamingController.png`),
  GamingCup: require(`./${IMAGES_BASE}GamigCup.png`),
  GamingHeadphones: require(`./${IMAGES_BASE}GamingHeadphones.png`),
  GamingKeyboard: require(`./${IMAGES_BASE}GamingKeyboard.png`),
  GamingKeyboard2: require(`./${IMAGES_BASE}GamingKeyboard2.png`),
  GamingMouse: require(`./${IMAGES_BASE}GamingMouse.png`),
  Home: require(`./${PLACEHOLDER_BASE}Home.png`),
  HomePageBits: require(`./${PLACEHOLDER_BASE}placeHolder_homePage_bits.png`),
  HomePageCompete: require(`./${PLACEHOLDER_BASE}placeHolder_homePage_compete.png`),
  HomePageShop: require(`./${PLACEHOLDER_BASE}placeHolder_homePage_shop.png`),
  HomeMenuOutline: require(`./icons/outline/icon_home_outline.svg`),
  HomePageDashboard: require(`./${IMAGES_BASE}HomePage.png`),
  IosScreenSTCPlay: require(`./${IMAGES_BASE}IosScreenSTCPlay.png`),
  IosScreenSTCPlayAr: require(`./${IMAGES_BASE}IosScreenSTCPlayAr.png`),
  ItunesCard: require(`./${IMAGES_BASE}ItunesCard.png`),
  MobileGamer: require(`./${IMAGES_BASE}MobileGamer.png`),
  MohammedAdnann: require(`./${IMAGES_BASE}MohammedAdnann.png`),
  MysteryChallenger: require(`./${IMAGES_BASE}MysteryChallenger.png`),
  PlayStore: require(`./${IMAGES_BASE}playstore.png`),
  Ps5Console: require(`./${IMAGES_BASE}Ps5Console.png`),
  Ps5Controller: require(`./${IMAGES_BASE}Ps5Controller.png`),
  QrCode: require(`./${IMAGES_BASE}footerQrCode.png`),
  SecondChallenger: require(`./${IMAGES_BASE}SecondChallenger.png`),
  SekiroGif: require(`./${IMAGES_BASE}SekiroGif.gif`),
  Shop: require(`./${PLACEHOLDER_BASE}Shop.png`),
  SignUpBackground: require(`./${IMAGES_BASE}SignUpBackground.png`),
  SignInBackground: require(`./${IMAGES_BASE}SignInBackground.png`),
  SmileFace: require(`./${IMAGES_BASE}SmileFace.png`),
  VRGamer: require(`./${IMAGES_BASE}VRGamer.png`),
  XboxBlack: require(`./${IMAGES_BASE}XboxBlack.png`),
  DefaultPlaceholder: require(`./${PLACEHOLDER_BASE}DefaultPlaceholder.png`),
  Banner_Hero_Placeholder: require(`./${PLACEHOLDER_BASE}Shophome/Banner_Hero.png`),
  Banner_Medium_Placeholder: require(`./${PLACEHOLDER_BASE}Shophome/Banner_Medium.png`),
  Carousel_Header_Placeholder: require(`./${PLACEHOLDER_BASE}Shophome/Carousel_Header.png`),
  Carousel_Hotstuff_Placeholder: require(`./${PLACEHOLDER_BASE}Shophome/Carousel_Hotstuff.png`),
  Carousel_QRCode_Placeholder: require(`./${PLACEHOLDER_BASE}Shophome/Carousel_QRcode.png`),
  Carousel_Timed_Placeholder: require(`./${PLACEHOLDER_BASE}Shophome/Carousel_Timed.png`),
  Text_Brand_Placeholder: require(`./${PLACEHOLDER_BASE}Shophome/Text_Brand.png`),
  Text_Tailored_Placeholder: require(`./${PLACEHOLDER_BASE}Shophome/Text_Tailored.png`),
  PlayStation: require(`./${IMAGES_BASE}PlayStation.png`),
  Product_Placeholder: require(`./${PLACEHOLDER_BASE}Shophome/Product_Placeholder.png`),
  WalletNoise: require(`./${IMAGES_BASE}WalletNoise.png`),
  UserProfile_Header_Placeholder: require(`./${PLACEHOLDER_BASE}user_profile_header_bg.png`),
  internet_Connection: require(`./${IMAGES_BASE}internet_connection.png`),

  //Carousel Card Header
  Card_Chest: require(`./${IMAGES_BASE}chest.png`),
  Card_Diamond: require(`./${IMAGES_BASE}diamond.png`),
  Card_Controller: require(`./${IMAGES_BASE}controller.png`),
  Card_QRCode: require(`./${IMAGES_BASE}qrcode.png`),
  arrow_xup_yright_black: require(`./${IMAGES_BASE}arrow_upper_right_black.png`),
  arrow_xup_yright_white: require(`./${IMAGES_BASE}arrow_upper_right_white.png`),
  //Carousel Card Header Placeholder
  CCard_deals_en: require(`./${PLACEHOLDER_BASE}CCard_1.png`),
  CCard_new_en: require(`./${PLACEHOLDER_BASE}CCard_2.png`),
  CCard_treasure_en: require(`./${PLACEHOLDER_BASE}CCard_3.png`),
  CCard_qrcode_en: require(`./${PLACEHOLDER_BASE}CCard_4.png`),
  CCard_compete_en: require(`./${PLACEHOLDER_BASE}CCard_5.png`),

  CCard_deals_ar: require(`./${PLACEHOLDER_BASE}CCard_1_ar.png`),
  CCard_new_ar: require(`./${PLACEHOLDER_BASE}CCard_2_ar.png`),
  CCard_treasure_ar: require(`./${PLACEHOLDER_BASE}CCard_3_ar.png`),
  CCard_qrcode_ar: require(`./${PLACEHOLDER_BASE}CCard_4_ar.png`),
  CCard_compete_ar: require(`./${PLACEHOLDER_BASE}CCard_5_ar.png`),
  Component_1: require(`./${PLACEHOLDER_BASE}Component_1.png`),
  Component_2: require(`./${PLACEHOLDER_BASE}Component_2.png`),
  Component_3: require(`./${PLACEHOLDER_BASE}Component_3.png`),

  //Footer
  FooterGreet: require(`./${IMAGES_BASE}footer/subscription_confirmation.png`),

  //Tailored Section
  Icon_Bill: require('./icons/placeholder/bill.png'),
  Icon_Box: require('./icons/placeholder/box.png'),
  Icon_PriceTag: require('./icons/placeholder/pricetag.png'),
  Icon_Shipping: require('./icons/placeholder/shipping.png'),
  Icon_Bill_v2: require('./icons/placeholder/bill_v2.png'),

  //Brands Section
  Brand_Nintendo: require(`./${PLACEHOLDER_BASE}Brand_Nintendo.png`),
  Brand_PS4: require(`./${PLACEHOLDER_BASE}Brand_PS4.png`),
  Brand_PS5: require(`./${PLACEHOLDER_BASE}Brand_PS5.png`),
  Brand_Xbox_1: require(`./${PLACEHOLDER_BASE}Brand_XBox_One.png`),
  Brand_Xbox: require(`./${PLACEHOLDER_BASE}Brand_Xbox.png`),
  Brand_Razer: require(`./${PLACEHOLDER_BASE}Brand_Razer.png`),

  //Medium Banner Dummy Images
  StaticMediumBannerReferNowImage: require(`./${IMAGES_BASE}refer-female.png`),
  StaticMediumBannerPreOrderNowBGImage: require(`./${IMAGES_BASE}Dummy_MediumBanner_BG_OrderNow.png`),
  StaticMediumBannerPreOrderNowImage: require(`./${IMAGES_BASE}mass-effect-3.png`),
  ProductPlaceHolderImage: require(`./${IMAGES_BASE}robot.png`),

  //Payments
  CreditCard: require(`./${IMAGES_BASE}Payments_CreditCard.png`),
  Tabby: require(`./${IMAGES_BASE}Payments_TabbyPay.png`),
  ApplePay: require(`./${IMAGES_BASE}Payments_ApplePay.png`),
  STCPay: require(`./${IMAGES_BASE}Payments_STCPay_v2.png`),

  //Cart
  Confirmation: require(`./${SHOP_BASE}order.png`),
  ConfirmationStar1: require(`./${PLACEHOLDER_BASE}Confirmation_star1.png`),
  ConfirmationStar2: require(`./${PLACEHOLDER_BASE}Confirmation_star2.png`),
  ConfirmationStar3: require(`./${PLACEHOLDER_BASE}Confirmation_star3.png`),
  CartNoItem: require(`./${SHOP_BASE}empty.png`),
  CartNoItemHD: require(`./${PLACEHOLDER_BASE}CartNoItem_HD.png`),
  ReturnConfirmation: require(`./${SHOP_BASE}return.png`),

  //Create Tournament
  CreateTournament: require(`./${PLACEHOLDER_BASE}CreateTournament.png`),
  TournamentGreenCircleCheck: require(`./${IMAGES_BASE}tournament_check_circle_green.png`),
  TournamentTooltip: require(`./${IMAGES_BASE}tournament_tooltip.png`),

  //Matchmaking
  MatchmakingGuestImage: require(`./${MATCHMAKING_BASE}matchmaking_guest.png`),
  MatchmakingLoggedInImage: require(`./${MATCHMAKING_BASE}matchmaking_creation.png`),
  MatchmakingLoggedInEllipse: require(`./${PLACEHOLDER_BASE}matchmaking_logged_in_ellipse.png`),

  //Brackets
  MedalGold: require(`./${IMAGES_BASE}medal_gold.png`),
  MedalSilver: require(`./${IMAGES_BASE}medal_silver.png`),
  MedalBronze: require(`./${IMAGES_BASE}medal_bronze.png`),
  //Medals
  GoldMedalImage: require(`./${IMAGES_BASE}gold-medal.png`),
  SilverMedalImage: require(`./${IMAGES_BASE}silver-medal.png`),
  BronzeMedalImage: require(`./${IMAGES_BASE}bronze-medal.png`),

  // Profile
  ProfileTournamentImage: require(`./${PLACEHOLDER_BASE}placeholder_tournament.png`),
  DontBeAStrangerImage: require(`./${IMAGES_BASE}login/signup_popup.png`),

  //Contact Us
  MailUsImage: require(`./${IMAGES_BASE}Mail_Us.png`),
  ChatWithUsImage: require(`./${IMAGES_BASE}Chat_With_Us.png`),
  CallUsImage: require(`./${IMAGES_BASE}Call_Us.png`),
  VisitUsImage: require(`./${IMAGES_BASE}Visit_Us.png`),
  MailUsMobileImage: require(`./${IMAGES_BASE}Mail_Us_Mobile.png`),
  ChatWithUsMobileImage: require(`./${IMAGES_BASE}Chat_With_Us_Mobile.png`),
  CallUsMobileImage: require(`./${IMAGES_BASE}Call_Us_Mobile.png`),
  VisitUsMobileImage: require(`./${IMAGES_BASE}Visit_Us_Mobile.png`),

  //Connect
  GroupAvatar: require(`./${IMAGES_BASE}img_group_avatar.png`),

  //Blocked
  BlockedEmpty: require(`./${SETTINGS_BASE}blocked_empty_state.png`),

  //Mini Games
  HighlightPlaceHolder: require(`./${PLACEHOLDER_BASE}MiniGameBanner.png`),
  MiniGameIconPlaceHolder: require(`./${PLACEHOLDER_BASE}MiniGameIcon.png`),
  MiniGameDetailsBanner: require(`./${PLACEHOLDER_BASE}MiniGameDetailBannerPlaceholder.png`),
  MiniGameBannerIcon: require(`./${PLACEHOLDER_BASE}MiniGameBannerIcon.png`),
  MiniGameCardFallBack: require(`./${MINIGAMES_BASE}mini-game-card-fallback.png`),
  MiniGameStaticLayer1: require(`./${MINIGAMES_BASE}mini-game-static-layer1.png`),
  MiniGameStaticLayer2: require(`./${MINIGAMES_BASE}mini-game-static-layer2.png`),
  MiniGameMobileStaticLayer1: require(`./${MINIGAMES_BASE}mini-game-mobile-static-layer1.png`),
  MiniGameMobileStaticLayer2: require(`./${MINIGAMES_BASE}mini-game-mobile-static-layer2.png`),
  MiniGameBannerGlow: require(`./${MINIGAMES_BASE}banner-glow.png`),

  //Notifications
  NotificationUserAvatar: require(`./${PLACEHOLDER_BASE}notification/avatarGroupPlaceholder.png`),
  NotificationVs: require(`./${IMAGES_BASE}NotificationVs.png`),
  NotificationsNoProtection: require(`./${IMAGES_BASE}Notifications_no_protection.png`),

  // Home
  HomeBannerLight: require(`./${HOME_BASE}banner_light.png`),
  HomeBannerDark: require(`./${HOME_BASE}banner_dark.png`),
  HomeBannerLightMobile: require(`./${HOME_BASE}banner_light_mobile.png`),
  HomeBannerDarkMobile: require(`./${HOME_BASE}banner_dark_mobile.png`),
  HomeMatchmakingCard1: require(`./${IMAGES_BASE}homepage_matchmaking_card_1.png`),
  HomeMatchmakingCard2: require(`./${IMAGES_BASE}homepage_matchmaking_card_2.png`),
  HomeMatchmakingCard3: require(`./${IMAGES_BASE}homepage_matchmaking_card_3.png`),

  //My Wallet
  WalletTreasure: require(`./${SETTINGS_BASE}wallet_treasure.png`),
  WalletNoTransaction: require(`./${SETTINGS_BASE}wallet_no_transactions.png`),
  WalletCoin: require(`./${SETTINGS_BASE}wallet_coin.png`),

  //Search
  GlobalSearchEmptyStage: require(`./${IMAGES_BASE}search/empty_state.png`),
  NoRecentSearch: require(`./${IMAGES_BASE}search/no_recent_search.png`),

  // Ewc
  TbdAvatarDark: require(`./${EWC_BASE}tbd_dark.png`),
  TbdAvatarLight: require(`./${EWC_BASE}tbd_light.png`),
  TimerBombImage: require(`./${EWC_BASE}bomb.png`),
  EwcStandingsRank1: require(`./${EWC_BASE}rank_1.png`),
  EwcStandingsRank2: require(`./${EWC_BASE}rank_2.png`),
  EwcStandingsRank3: require(`./${EWC_BASE}rank_3.png`),
  EwcDontBeAStrangerImage: require(`./${EWC_BASE}stranger_image.png`),
  EsportsWorldCupBannerLight: require(`./${EWC_BASE}esports-wc-banner-light.png`),
  EsportsWorldCupBanner: require(`./${EWC_BASE}esports-wc-banner.png`),
  EsportsWorldCupPattern: require(`./${EWC_BASE}esports-wc-pattern.png`),
  EsportsWorldCupBGLive: require(`./${EWC_BASE}ewc_bg.png`),
  StcTVBGLive: require(`./${EWC_BASE}stc_tv_bg.png`),

  //Common
  EmptyStates: require(`./${IMAGES_BASE}common/empty_states.png`),

  NoContent: require(`./${IMAGES_BASE}bits/no-content.png`),
  NoFavouritesBG: require(`./${IMAGES_BASE}bits/no-fav-bg.png`),

  //Verify Motivation
  VerifyPhone: require(`./${IMAGES_BASE}illustration/verify_phone.png`),

  // Gen G
  GenG_GuestUser_Banner: require(`./${IMAGES_BASE}GenGuestBanner.png`),
  GenG_Success: require(`./${IMAGES_BASE}ewc/ActionSuccess.png`),
  GenG_Error: require(`./${IMAGES_BASE}ewc/ActionFailure.png`),

  //Banner
  StcplayBrand: require(`./${IMAGES_BASE}stcplayBrand.png`),
};

export const SOCIALMEDIALINK: SOCIALMEDIALINKTYPE = {
  facebook: 'https://www.facebook.com/stcplay.hub/',
  instagram: 'https://www.instagram.com/stcplay/?hl=en',
  linkedin: 'https://www.linkedin.com/company/stc-play/',
  twitter: 'https://twitter.com/stcplay?lang=en',
  youtube: 'https://www.youtube.com/channel/UCiTN5fHOYfUyl2yalDvZHmA?app=desktop',
};

export const LottieIconAnimations: LottieIconAnimationsType = {
  Icon_Cart: require('./icons/lottie/icon_Cart.json'),
  Icon_Checked: require('./icons/lottie/icon_Checked.json'),
  Icon_Van: require('./icons/lottie/icon_Van.json'),
  Icon_Envelope: require('./icons/lottie/icon_Envelope.json'),
  Icon_Wallet: require('./icons/lottie/icon_Wallet.json'),
  Icon_Loader: require('./icons/lottie/icon_Loader.json'),
  Icon_Approved: require('./icons/lottie/approved.svg'),
  Icon_HourGlass: require('./icons/lottie/icon_HourGlass.json'),
  Icon_Email: require('./icons/lottie/icon_Email.json'),
  Icon_Cash: require('./icons/lottie/icon_Cash.json'),
  Icon_Rejected: require('./icons/lottie/icon_Rejected.json'),
  Icon_approved: require('./icons/lottie/icon_Approved.json'),
  Icon_VanReturn: require('./icons/lottie/icon_VanReturn.json'),
  Mini_Games_Cover: require('./icons/lottie/minigame/mini_games_cover.json'),
  Mini_Games_Mobile_Cover: require('./icons/lottie/minigame/minigames_bg_mobile.json'),
};
