import { useLazyQuery } from '@apollo/client';
import { CURRENCY_QUERY } from '@fe-monorepo/data-access';
import { CurrencyData } from '@fe-monorepo/models';
import { AppStateSliceState, RootState } from '@fe-monorepo/store';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { useAppState } from '../index';
import { useFetchQuery } from '../useFetchQuery';

export const useCurrency = () => {
  const { changeLoadingState, changeCurrency } = useAppState();
  const code = useSelector((state: RootState) => state.app.currencyOBJ.code);

  const [getCurrency, { data: Currency, error: currencyError, loading }] = useLazyQuery(CURRENCY_QUERY.getCurrency, { errorPolicy: 'all' });

  const { currencyData, errorMessage, isSuccessful } = useMemo(() => {
    let currencyData;
    let errorMessage;
    let isSuccessful = false;

    if (Currency?.Currency?.data) {
      currencyData = Currency.Currency.data;
      errorMessage = Currency.Currency.error_msg;
      isSuccessful = !!Currency.Currency.is_successful;
    }

    return {
      currencyData,
      errorMessage,
      isSuccessful,
    };
  }, [Currency]);

  const getCurrencyUpdate = async () => {
    await getCurrency();
  };

  useEffect(() => {
    if (currencyData?.length > 0) {
      const selected = currencyData.find((c: any) => c.currency_code === code);
      if (selected) {
        changeCurrency({
          currency: selected.currency_code,
          currencyRate: selected.currency_rate,
          currencyOBJ: {
            rate: selected.currency_rate,
            code: selected.currency_code,
          },
        } as AppStateSliceState);
      }
    }
  }, [currencyData]);

  useEffect(() => {
    if (currencyError) {
      changeLoadingState(false);
    }
  }, [currencyError]);

  useEffect(() => {
    changeLoadingState(loading);
  }, [loading]);

  return { getCurrencyUpdate, currencyData, isSuccessful, errorMessage };
};

export const useCurrencyNew = () => {
  const currency = useFetchQuery<CurrencyData[]>(CURRENCY_QUERY.getCurrency, []);
  return currency;
};

export const useCurrencyWeb = () => {
  const [selectedCurrency, setSelectedCurrency] = useState<string>(() => {
    const savedCurrency = localStorage.getItem('selectedCurrency');
    return savedCurrency ? savedCurrency : 'SAR';
  });

  const currency = useCurrencyNew();
  const { changeCurrency } = useAppState();

  const selectCurrency = (code: string) => setSelectedCurrency(code);

  useEffect(() => {
    currency.fetch();
  }, []);

  useEffect(() => {
    const currencyData = currency.data;
    if (selectedCurrency && currencyData?.length > 0) {
      const selected = currencyData.find(currency => currency.currency_code === selectedCurrency);

      if (selected) {
        changeCurrency({
          currency: selected.currency_code,
          currencyRate: selected.currency_rate,
          currencyOBJ: {
            rate: selected.currency_rate,
            code: selected.currency_code,
          },
        } as AppStateSliceState);

        localStorage.setItem('selectedCurrency', selectedCurrency);
      }
    }
  }, [selectedCurrency, currency.data]);

  return {
    selectedCurrency,
    fetch: currency.fetch,
    currencyList: currency.data,

    selectCurrency,
  };
};
