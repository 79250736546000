import { useTranslate, useUserProfile } from '@fe-monorepo/hooks';
import { toastWrapper } from '@fe-web/Atoms/Toast';
import { Menu, Transition } from '@headlessui/react';
import { AppRoutes } from 'apps/fe-web/src/app/app.routes.enum';
import { isLoggedin } from 'apps/fe-web/src/quickplay-app/Helper/helper';
import quickplay from 'quickplay-shorts-js';
import { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { STATUS } from '../../../../constants';
import { useLoginModalContext } from '../../../../contexts/LoginModal';
import { useNotification } from '../../../../contexts/Notification';
import { ReactComponent as RepostIcon } from '../../Icons/RepostIcon.svg';
import { formatLargeNumber } from '../../helpers';

const feedModule = new quickplay.QuickPlayFeedModule();

const getRepostPayloadData = (video: any) => {
  return {
    userId: video.userId,
    contentType: video.contentType,
    taggedUsers: video.taggedUsers,
    s3Url: video.url,
    contentUrl: video.contentUrls,
    title: video.title,
    videoCoverImage: video.thumbnailUrl,
    thumbnailUrl: video.thumbnailUrl,
    description: video.description,
    follow: false,
    type: 'repost',
    categoryId: video.categoryId,
    categoryName: video.categoryId,
    soundId: video.soundId,
    filterId: '',
    filterDisplayName: '',
    metaKeys: '',
    businessId: '',
    projectId: '',
    loc: [],
    lang: [],
    labels: [],
    question: '',
    options: [
      {
        title: '',
      },
    ],
    localisedTitle: video.localisedTitle,
    localisedDescription: video.localisedDescription,
    repost: true,
    originalVideoId: video.videoId,
    commentEnabled: video.commentEnabled,
    likeEnabled: video.likeEnabled,
    shareEnabled: video.shareEnabled,
    soundName: video.soundName,
  };
};

function RepostButton({ video, setIsVerifyOpen, toggleRepostModal }: any) {
  const { setShowLoginModal } = useLoginModalContext();
  const { repost, repostCount, user } = video;
  const userData = JSON.parse(localStorage.getItem('userData') || '{}');
  const { userId } = user ?? {};
  const [repostCounts, setRepostCounts] = useState(repostCount);
  const [videoRepost, setVideoRepost] = useState(repost);

  const navigate = useNavigate();

  const [isReposting, setIsReposting] = useState(false);
  const [isRepostingWithThoughts, setIsRepostingWithThoughts] = useState(false);

  const { user: currentUser } = useUserProfile();

  const isPhoneNotAdded = !currentUser?.mobile;
  const isPhoneNotVerified = currentUser?.mobile && !currentUser?.is_mobile_verified;

  const { showNotification } = useNotification();
  const { translate } = useTranslate();

  const incrementCount = () => {
    setRepostCounts((prev: number) => prev + 1);
  };

  const handleRepost = async () => {
    const isUserLoggedIn = await isLoggedin();
    if (isUserLoggedIn) {
      if (isPhoneNotAdded || isPhoneNotVerified) {
        setIsVerifyOpen(true);
        return;
      }

      if (video.repost) {
        toastWrapper('error', '' + 'You can only repost once');
        return;
      }

      setIsReposting(true);
      const repostPayloadData = getRepostPayloadData(video);
      try {
        const {
          data: { success, status },
        } = await feedModule.uploadContent(repostPayloadData);
        if (success) {
          toastWrapper('success', '' + `${translate('quickplay.label-repost-successfully')}`);
          setVideoRepost(true);
          incrementCount();
        } else {
          if (status === STATUS.VIDEO_ALREADY_REPOSTED) {
            toastWrapper('error', '' + `${translate('quickplay.label-reposted')}`);
            showNotification({
              title: `${translate('quickplay.create-repost')}`,
              subTitle: `${translate('quickplay.label-reposted')}`,
            });
          }
        }
        setIsReposting(false);
      } catch (error) {
        console.error(error);
      }
    } else {
      setShowLoginModal(true);
    }
  };

  const handleRepostWithThoughts = async () => {
    const isUserLoggedIn = await isLoggedin();
    if (isUserLoggedIn) {
      if (isPhoneNotAdded || isPhoneNotVerified) {
        setIsVerifyOpen(true);
        return;
      }

      if (video.repost || videoRepost) {
        toastWrapper('error', '' + 'You can only repost once');
        return;
      } else {
        setIsRepostingWithThoughts(true);
        sessionStorage.setItem('videoUrls', JSON.stringify([video.url]));
        sessionStorage.setItem('blobUrls', JSON.stringify([video.url]));
        navigate(`${AppRoutes.bits}/create-post/step-2?repost=true&originalVideoId=${video?.videoId}`);
        incrementCount();
        setIsRepostingWithThoughts(false);
      }
    } else {
      setShowLoginModal(true);
    }
  };

  return (
    <>
      <div className="misc_options_wrapper">
        <Menu as="div" className="relative inline-block text-left">
          <Menu.Button>
            <button className="repost-icon" title="Repost">
              <div className="icon">
                <RepostIcon />
              </div>
              <div className="icon-count">{formatLargeNumber(repostCounts || 0)}</div>
            </button>
          </Menu.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="contextMenu absolute rounded-md cursor-pointer directionRtl">
              {userData.usedId !== userId && (
                <>
                  <Menu.Item as="div">
                    <button className="post-actions" onClick={handleRepostWithThoughts}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path
                          d="M4.16667 15.8333H5.35417L13.5 7.6875L12.3125 6.5L4.16667 14.6458V15.8333ZM2.5 17.5V13.9583L13.5 2.97917C13.6667 2.82639 13.8507 2.70833 14.0521 2.625C14.2535 2.54167 14.4653 2.5 14.6875 2.5C14.9097 2.5 15.125 2.54167 15.3333 2.625C15.5417 2.70833 15.7222 2.83333 15.875 3L17.0208 4.16667C17.1875 4.31944 17.309 4.5 17.3854 4.70833C17.4618 4.91667 17.5 5.125 17.5 5.33333C17.5 5.55556 17.4618 5.76736 17.3854 5.96875C17.309 6.17014 17.1875 6.35417 17.0208 6.52083L6.04167 17.5H2.5ZM12.8958 7.10417L12.3125 6.5L13.5 7.6875L12.8958 7.10417Z"
                          fill="var(--secondary-black)"
                        />
                      </svg>
                      <div>
                        {isRepostingWithThoughts
                          ? `${translate('quickplay.label-reposting-with-thoughts')}`
                          : `${translate('quickplay.label-reposting-your-thoughts')}`}
                      </div>
                    </button>
                  </Menu.Item>

                  <Menu.Item as="div">
                    <button className="post-actions" onClick={isRepostingWithThoughts ? handleRepostWithThoughts : handleRepost}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M4.16667 8.33307V9.99974H2.5L2.5 8.33307C2.5 6.03188 4.36548 4.1664 6.66667 4.1664H12.9882L11.0774 2.25566L12.2559 1.07715L16.1785 4.99974L12.2559 8.92233L11.0774 7.74381L12.9882 5.83307H6.66667C5.28595 5.83307 4.16667 6.95236 4.16667 8.33307Z"
                          fill="var(--secondary-black)"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M15.8333 11.6664V9.99974H17.5V11.6664C17.5 13.9676 15.6345 15.8331 13.3333 15.8331H7.01184L8.92259 17.7438L7.74408 18.9223L3.82149 14.9997L7.74408 11.0771L8.92259 12.2557L7.01184 14.1664L13.3333 14.1664C14.714 14.1664 15.8333 13.0471 15.8333 11.6664Z"
                          fill="var(--secondary-black)"
                        />
                      </svg>
                      <div>{isReposting ? `${translate('quickplay.label-reposting')}` : `${translate('quickplay.create-repost')}`}</div>
                    </button>
                  </Menu.Item>
                </>
              )}
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </>
  );
}

export default RepostButton;
