import { useAppState } from '@fe-monorepo/hooks';
import { RootState } from '@fe-monorepo/store';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { AppRoutes } from '../app.routes.enum';

const useHeaderState = () => {
  const { t, changeLanguage, changeHeaderColorState } = useAppState();
  const prefs = useSelector((state: RootState) => state?.app);
  const common_language = 'common_language';
  const dir = prefs?.language === 'en' ? `ltr` : `rtl`;
  const discoverRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [midSection, setMidSection] = useState('');
  const [isHeaderDark, setIsHeaderDark] = useState(true);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [innerWidth, setInnerWidth] = useState(window?.innerWidth);

  const signIn = () =>
    navigate(AppRoutes.authSignIn, {
      state: { from: 'landingPage' },
    });
  const changeSection = (section: string) => setMidSection(section);

  const changeLang = () => {
    changeLanguage(prefs.language === 'en' ? 'ar' : 'en', 'web');
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  useEffect(() => {
    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      entries.forEach(entry => {
        if (entry.target === discoverRef.current) {
          if (entry.isIntersecting) {
            changeHeaderColorState(true);
          } else {
            changeHeaderColorState(false);
          }
        }
      });
    };
    const observer = new IntersectionObserver(handleIntersection);
    if (discoverRef.current) {
      observer.observe(discoverRef.current);
    }
    return () => {
      if (discoverRef.current) {
        observer.unobserve(discoverRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setInnerWidth(window.innerWidth);
    };
    window.addEventListener('scroll', handleResize);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('scroll', handleResize);
      window.removeEventListener('resize', handleResize);
    };
  }, [innerWidth]);

  return {
    prefs,
    dir,
    midSection,
    isHeaderDark,
    sidebarOpen,
    common_language,
    signIn,
    t,
    changeSection,
    changeLang,
    toggleSidebar,
    innerWidth,
    discoverRef,
  };
};

export default useHeaderState;
