import { BrazeCustomEvents, MixPanelCustomEvents } from '@fe-monorepo/helper';
import { useTranslate, useUserProfile } from '@fe-monorepo/hooks';
import { toastWrapper } from '@fe-web/Atoms/Toast';
import brazeHelper from '@fe-web/helpers/brazeHelper';
import mixpanelHelper from '@fe-web/helpers/mixpanelHelper';
import { isLoggedin } from 'apps/fe-web/src/quickplay-app/Helper/helper';
import quickplay from 'quickplay-shorts-js';
import React, { useEffect, useRef, useState } from 'react';

import { useLoginModalContext } from '../../../../contexts/LoginModal';
import { useNotification } from '../../../../contexts/Notification';
import LoaderDark from '../../../common/LoaderDark/LoaderDark';
import { ReactComponent as CloseIcon } from '../../Icons/Close.svg';
import { ReactComponent as SubmitArrow } from '../../Icons/SubmitArrow.svg';
import { formatLargeNumber } from '../../helpers';
import Comment from './Comment';

const activityTimelineModule = new quickplay.QuickPlayActivityTimeline();

export default function Comments({
  setShowComments,
  video,
  videoRef,
  videoControls,
  setShowCommentReportModal,
  setCommentPostId,
  commentCount,
  setCommentCount,
  commentExpand,
  elementHeight,
}: any) {
  const inputRef: any = useRef(null);
  const [reply, setReply] = useState('');
  const [totalComments, setTotalComments] = useState(0);
  const [noMoreComments, setNoMoreComments] = useState(false);
  const [replyComment, setReplyComment] = useState(false);
  const [replyData, setReplyData]: any = useState(null);
  const [comments, setComments] = useState<any>([]);
  const [comment, setComment] = useState<any>('');
  const [commentToUpdate, setCommentToUpdate]: any = useState(null);
  const [replyToUpdate, setReplyToUpdate]: any = useState(null);
  const [rows, setRows] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState('Loading');
  const { translate } = useTranslate();
  const { showLoginModal, setShowLoginModal } = useLoginModalContext();
  const { showNotification } = useNotification();
  const [commentError, setCommentError] = useState(false);
  const [replyError, setReplyError] = useState(false);
  const [showReply, setShowReply] = useState(false);
  const [page, setPage] = useState(1);
  const commentsLimit = 8;
  const [isTyping, setIsTyping] = useState(true);
  const { user: currentUser } = useUserProfile();

  const incrementCount = () => {
    setCommentCount((prev: number) => prev + 1);
  };

  const decrementCount = (count = 1) => {
    setCommentCount((prev: number) => prev - count);
  };

  const handleViewMoreComments = () => {
    // eslint-disable-next-line prefer-const
    let offset = page + 1;
    setPage(page + 1);
    fetchComments(commentsLimit, offset);
  };

  useEffect(() => {
    if (!isLoading) {
      setLoadingText(`${translate('quickplay.label-loading')}`);
    }
  }, [isLoading]);

  useEffect(() => {
    fetchComments(commentsLimit, page);
  }, []);

  useEffect(() => {
    if (video.deeplinkData && video.deeplinkData?.commentId) fetchDeeplinkedComments();
  }, [video.deeplinkData?.commentId]);

  async function fetchDeeplinkedComments() {
    try {
      // eslint-disable-next-line prefer-const
      let { commentId, replyId } = video.deeplinkData;
      const params: any = { parentCommentId: commentId };
      if (replyId) params['replyCommentId'] = replyId;
      const response = await activityTimelineModule.getCommentById(params);
      if (response?.status === 200) {
        let parentComment: any = {};
        if (response.data?.result?.parentComment) {
          parentComment = response.data?.result?.parentComment;
          parentComment['highlighted'] = true; // Assign 'highlighted' property to the comment object
        }
        fetchComments(commentsLimit, 1, [parentComment]);
      }
    } catch (err) {
      console.error(err);
    }
  }

  async function fetchComments(commentsLimit: any, page: any, deeplinkedComment: any = []) {
    setIsLoading(true);
    try {
      const response = await activityTimelineModule.getActivityTimelineCommentList({
        limit: commentsLimit,
        offset: page,
        topicId: video.videoId,
      });
      if (response?.status === 200) {
        let responseData = response?.data?.result;
        if (video.deeplinkData?.commentId) {
          responseData = responseData.filter((c: any) => c.postId !== video.deeplinkData?.commentId);
        }
        const commentUpdates = [...deeplinkedComment, ...comments, ...responseData];
        setComments(commentUpdates);
        setTotalComments(response?.data?.total);
        if (response?.data?.result.length === 0) {
          setNoMoreComments(prev => !prev);
        } else {
          setNoMoreComments(false);
        }
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  }

  const handleCreateComment = async (event: any) => {
    event.preventDefault();
    if (!comment) {
      toastWrapper('error', '' + translate('quickplay.comment-required'));
      return;
    }
    if (comment.length > 200) {
      toastWrapper('error', '' + translate('quickplay.comment-limit'));
      return;
    }

    if (handleCommentValidation()) {
      const accessToken = await new quickplay.QuickPlayAuthModule().getAccessToken();
      if (!accessToken) {
        setShowLoginModal(true);
        return;
      }
      setIsLoading(true);
      setLoadingText(`${translate('quickplay.posting-comment')}`);
      try {
        const activityTimelineModuleObj = new quickplay.QuickPlayActivityTimeline();
        const userPostedComment = await activityTimelineModuleObj.activityTimelineAddComment({
          assetId: video.videoId,
          description: comment,
          postId: '',
        });
        if (userPostedComment?.data?.success) {
          const commentResponse = userPostedComment?.data?.result;
          const username = JSON.parse(localStorage.getItem('userData') || '{}')?.fullName;
          const profileImageUrl = JSON.parse(localStorage.getItem('userData') || '{}')?.profileImageUrl;
          const commentData = {
            ...commentResponse,
            createdAt: commentResponse.created_at,
            updatedAt: commentResponse.updated_at,
            fullName: username,
            profileImageUrl: profileImageUrl,
          };
          setComments([commentData, ...comments]);
          incrementCount();
          setShowReply(true);
          setComment('');
          const eventPayload = {
            'Bit ID': video?.videoId,
            'Bit Title': video?.title,
            Username: currentUser?.username,
            'User Comment': true,
            'Bits Publisher Username': video?.user?.userName,
          };
          mixpanelHelper.trackEvent(MixPanelCustomEvents.BitsCommented, eventPayload);
          brazeHelper.logCustomBrazeEvent(BrazeCustomEvents.BitsCommented, eventPayload);
        }
        setIsTyping(true);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error(error);
      }
    } else {
      showNotification({
        title: 'Post Comment',
        subTitle: 'Comment can not be empty',
      });
      return;
    }
  };

  const handleCreateReply = async (event: any, ids: any) => {
    if (!reply) {
      toastWrapper('error', '' + translate('quickplay.reply-required'));
      return;
    }
    if (reply.length > 200) {
      toastWrapper('error', '' + translate('quickplay.comment-limit'));
      return;
    }

    if (handleReplyValidation()) {
      setComment('');
      setLoadingText(`${translate('quickplay.posting-reply')}`);

      let data: any = {};
      if (ids.postId) {
        data = comments.find(({ postId }: any) => postId === ids.postId);
      } else {
        data = comments.find(({ commentRefId }: any) => commentRefId === ids.commentRefId);
      }
      event.preventDefault();
      const accessToken = await new quickplay.QuickPlayAuthModule().getAccessToken();
      if (!accessToken) {
        setShowLoginModal(true);
        return;
      }

      setIsLoading(true);
      try {
        // eslint-disable-next-line prefer-const
        let dataToSend: any = {
          assetId: video.videoId,
          description: reply,
        };
        if (ids.postId) {
          dataToSend.postId = ids.postId;
        } else {
          dataToSend.postId = ids.commentRefId;
        }
        const activityTimelineModuleObj = new quickplay.QuickPlayActivityTimeline();
        const userPostedComment = await activityTimelineModuleObj.activityTimelineAddComment(dataToSend);
        if (userPostedComment?.data?.success) {
          const commentResponse = userPostedComment?.data?.result;
          const username = JSON.parse(localStorage.getItem('userData') || '{}')?.fullName;
          const profileImageUrl = JSON.parse(localStorage.getItem('userData') || '{}')?.profileImageUrl;
          const commentData = {
            ...commentResponse,
            fullName: username,
            profileImageUrl: profileImageUrl,
          };
          if (data['replys']) {
            data['replys'] = data['replys'].concat(commentData);
          } else {
            data['replys'] = [commentData];
          }
          if (data['limit']) {
            data['limit']++;
          } else {
            data['limit'] = 1;
          }
          setReplyComment(false);
          setIsLoading(false);
          setShowReply(true);
          incrementCount();
          setReply('');
          inputRef?.current?.focus();
          inputRef.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center',
          });
          const eventPayload = {
            'Bit ID': video?.videoId,
            'Bit Title': video?.title,
            Username: currentUser?.username,
            'User Comment': true,
            'Bits Publisher Username': video?.user?.userName,
          };
          mixpanelHelper.trackEvent(MixPanelCustomEvents.BitsCommented, eventPayload);
          brazeHelper.logCustomBrazeEvent(BrazeCustomEvents.BitsCommented, eventPayload);
        }
        setIsTyping(true);
      } catch (error) {
        setIsLoading(false);
        console.error(error);
      }
    } else {
      showNotification({
        title: 'Post Reply',
        subTitle: 'Reply can not be empty',
      });
      return;
    }
  };

  // onChange Textarea Handler - Case 1: the textarea height increases when the user manually enters input //
  const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const comment = e.target.value;
    if (comment.length > 0) {
      setIsTyping(false);
    } else {
      setIsTyping(true);
    }

    if (commentError) {
      setCommentError(false); // Clear error when user starts typing again
    }
    let textAreaRows;
    if (e.target.scrollHeight < 72) {
      textAreaRows = Math.floor(e.target.scrollHeight / 36);
    } else {
      textAreaRows = Math.ceil(e.target.scrollHeight / 36);
    }
    if (rows < 4) {
      setRows(textAreaRows);
    }
    setComment(comment);
  };

  // onKeyDown Textarea Handler - Case 2: the textarea height decreases when the user manually removes input //
  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (showLoginModal) {
      event.preventDefault();
    } else {
      const isBackspaceOrDelete = event.key === 'Backspace' || event.key === 'Delete';
      if (event.nativeEvent instanceof KeyboardEvent) {
        if (rows === 1) {
          return;
        }
        if (isBackspaceOrDelete && event.currentTarget.scrollTop === 0) {
          setRows(rows - 1);
        }
      }

      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault();
        handleCommentValidation();
      }
    }
  };

  // onInput Textarea Handler - Case 3: the textarea's height increases or decreases due to copy-pasting text or removing text with select all //
  const handleInput = (e: React.FormEvent<HTMLTextAreaElement>) => {
    if (
      e.currentTarget.value === '' &&
      e.currentTarget.selectionStart === 0 &&
      e.currentTarget.selectionEnd === e.currentTarget.value.length
    ) {
      setRows(1);
    }
  };

  const handleEditComment = async ({ id, refId }: any) => {
    let commentToEdit: any = {};
    if (id) {
      commentToEdit = comments.find(({ postId }: any) => postId === id);
    } else {
      commentToEdit = comments.find(({ commentRefId }: any) => commentRefId === refId);
    }
    const { description }: any = commentToEdit ?? {};
    setComment(description);
    setCommentToUpdate(commentToEdit);
    inputRef?.current?.focus();
    inputRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    });
  };

  // Reply Edit Handler //
  const handleEditReply = async ({ commentId, commentRef, replyId, replyRefId }: any) => {
    let replyToEdit: any = {};
    let commentData: any = {};
    if (commentId) {
      commentData = comments.find(({ postId }: any) => postId === commentId);
    } else {
      commentData = comments.find(({ commentRefId }: any) => commentRefId === commentRef);
    }

    replyToEdit = commentData.replys.find(({ commentRefId }: any) => commentRefId === replyRefId);
    const { description }: any = replyToEdit ?? {};
    if (commentId) {
      replyToEdit['commentId'] = commentId;
    } else {
      replyToEdit['commentRef'] = commentRef;
    }

    setReplyComment(true);
    setReply(description);
    setReplyToUpdate(replyToEdit);
    inputRef?.current?.focus();
    inputRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    });
  };

  const handleUpdateComment = async ({ event, id, refId }: any) => {
    if (handleCommentValidation()) {
      setIsLoading(true);
      setLoadingText(`${translate('quickplay.updating-comment')}`);
      try {
        const feedModuleObj = new quickplay.QuickPlayFeedModule();
        const response = await feedModuleObj.UpdateComment({
          assetId: video.videoId,
          description: comment,
          ...(id ? { postId: id } : { commentRefId: refId }),
        });
        if (response?.data?.success) {
          const commentResponse = response?.data?.result;
          const modifiedCommentsList = comments.map((data: any) => {
            if ((id && data.postId === id) || (refId && data.commentRefId === refId)) {
              return {
                ...data,
                description: commentResponse.description,
              };
            }
            return data;
          });
          setComments(modifiedCommentsList);
          setCommentToUpdate(null);
          setComment('');
        }
        setIsTyping(true);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error(error);
      }
    } else {
      showNotification({
        title: 'Post Comment',
        subTitle: 'Comment can not be empty',
      });
      return;
    }
  };

  // Reply Update Handler //
  const handleUpdateReply = async ({ event, commentId, commentRef, replyId, replyRefId }: any) => {
    setIsLoading(true);
    setLoadingText(`${translate('quickplay.updating-reply')}`);
    try {
      const feedModuleObj = new quickplay.QuickPlayFeedModule();
      const response = await feedModuleObj.UpdateComment({
        assetId: video.videoId,
        description: reply,
        ...(replyId ? { postId: replyId } : { commentRefId: replyRefId }),
      });
      if (response?.data?.success) {
        const commentResponse = response?.data?.result;

        const updatedComments = comments.map((comment: any) => {
          if ((commentId && comment.postId === commentId) || (commentRef && comment.commentRefId === commentRef)) {
            const updatedReplies = comment.replys.map((reply: any) => {
              if (replyRefId && reply.commentRefId === replyRefId) {
                return { ...reply, description: commentResponse.description };
              }
              return reply;
            });
            return { ...comment, replys: updatedReplies };
          }
          return comment;
        });
        setComments(updatedComments);
        setReply('');
        setReplyToUpdate('');
        setReplyComment(false);
        setCommentToUpdate(null);
      }
      setIsTyping(true);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const handleDeleteComment = async ({ id, refId }: any) => {
    setIsLoading(true);
    setLoadingText(`${translate('quickplay.deleting-comment')}`);
    try {
      const activityTimelineModuleObj = new quickplay.QuickPlayActivityTimeline();
      const response = await activityTimelineModuleObj.activityTimelineDeleteComment({
        assetId: video.videoId,
        ...(id ? { postId: id } : { commentRefId: refId }),
      });
      if (response.status === 201) {
        let deletedCommentIndex: any;
        if (id) {
          deletedCommentIndex = comments.findIndex(({ postId }: any) => postId === id);
        } else {
          deletedCommentIndex = comments.findIndex(({ commentRefId }: any) => commentRefId === refId);
        }
        const deletedComment = comments[deletedCommentIndex];
        if (deletedComment) {
          decrementCount(1 + (deletedComment?.replyCount ?? 0));
        } else {
          decrementCount();
        }
        comments.splice(deletedCommentIndex, 1);
        setComments([...comments]);
        setComment('');
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
    // reFetchComments();
  };

  // Reply Delete Handler //
  const handleDeleteReply = async ({ commentId, commentRef, replyId, replyRefId }: any) => {
    setIsLoading(true);
    setLoadingText(`${translate('quickplay.deleting-reply')}`);
    try {
      const activityTimelineModuleObj = new quickplay.QuickPlayActivityTimeline();
      const response = await activityTimelineModuleObj.activityTimelineDeleteComment({
        assetId: video.videoId,
        ...(replyId ? { postId: replyId } : { commentRefId: replyRefId }),
      });
      if (response.status === 201) {
        let deletedReplyIndex: any;
        let commentData: any = {};
        if (commentId) {
          commentData = comments.find(({ postId }: any) => postId === commentId);
        } else {
          commentData = comments.find(({ commentRefId }: any) => commentRefId === commentRef);
        }

        // eslint-disable-next-line prefer-const
        deletedReplyIndex = commentData.replys.findIndex(({ commentRefId }: any) => commentRefId === replyRefId);
        const deletedComment = commentData.replys[deletedReplyIndex];
        if (deletedComment) {
          decrementCount(1 + (deletedComment?.replyCount ?? 0));
        } else {
          decrementCount();
        }
        commentData.replys.splice(deletedReplyIndex, 1);
        const updatedComments = comments.map((comment: any) => {
          if ((commentId && comment.postId === commentId) || (commentRef && comment.commentRefId === commentRef)) {
            return { ...comment, replys: commentData.replys };
          }
          return comment;
        });
        setComments(updatedComments);
        setReply('');
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const handleReplyComment = async ({ id, refId }: any) => {
    const isUserLoggedIn = await isLoggedin();
    if (isUserLoggedIn) {
      let replyToEdit: any = {};
      if (id) {
        replyToEdit = comments.find(({ postId }: any) => postId === id);
      } else {
        replyToEdit = comments.find(({ commentRefId }: any) => commentRefId === refId);
      }
      setReplyData(replyToEdit);

      // Use LRM and RLM to handle bidirectional text issues
      const LRM = '\u200E';
      const RLM = '\u200F';
      const replyText = `${LRM}@${replyToEdit?.userName ? replyToEdit?.userName : replyToEdit?.userId}${RLM} `;
      setReply(replyText);
      setReplyComment(true);
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
          inputRef.current.setSelectionRange(replyText.length, replyText.length); // Place cursor at the end
        }
      }, 0);
    } else {
      setShowLoginModal(true);
    }
  };

  const handleReplyChange = (e: any) => {
    const comment = e.target.value;
    if (comment.length > 0) {
      setIsTyping(false);
    } else {
      setIsTyping(true);
    }

    if (replyError) {
      setReplyError(false); // Clear error when user starts typing again
    }
    let textAreaRows;
    if (e.target.scrollHeight < 72) {
      textAreaRows = Math.floor(e.target.scrollHeight / 36);
    } else {
      textAreaRows = Math.ceil(e.target.scrollHeight / 36);
    }
    if (rows < 4) {
      setRows(textAreaRows);
    }
    setReply(comment);
  };

  const handleCommentValidation = () => {
    if (!isCommentValid(comment)) {
      setCommentError(true);
      return false;
    }
    return true;
  };

  const isCommentValid = (comment: any) => comment.trim().length > 0;

  const handleReplyValidation = () => {
    if (!isReplyValid(reply)) {
      setCommentError(true);
      return false;
    }
    return true;
  };

  const isReplyValid = (reply: any) => reply.trim().length > 0;

  return (
    <div
      data-loading={isLoading}
      className={`comment_wrapper pointer-events-auto data-[loading=true]:pointer-events-none ${commentExpand && 'commentExpanded'}`}
      style={{
        height: `calc(100% - ${commentExpand ? elementHeight + 'px' : '100%'})`,
      }}
    >
      {isLoading && (
        <div className="absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2">
          <div className="flex flex-col gap-5">
            <LoaderDark />
            <div>{loadingText ?? 'Loading'}...</div>
          </div>
        </div>
      )}
      <div data-loading={isLoading} className="comment_wrapper_inner opacity-100 data-[loading=true]:opacity-25 h-full">
        <div className="top_content">
          <h5>
            {formatLargeNumber(commentCount)}{' '}
            {commentCount > 1 ? translate('quickplay.label-comments') : translate('quickplay.label-comment')}
          </h5>
          <button
            className="close_button"
            onClick={() => {
              setShowComments(false);
              if (videoRef.current) {
                videoRef.current.classList.remove('video-player-radius');
                videoControls.current.classList.remove('video-player-radius');
              }
            }}
          >
            <CloseIcon />
          </button>
        </div>
        <div className="comments_list">
          {comments.map((comment: any) => (
            <Comment
              video={video}
              comment={comment}
              key={comment.postId}
              onDelete={handleDeleteComment}
              onEdit={handleEditComment}
              handleReplyComment={handleReplyComment}
              setShowCommentReportModal={setShowCommentReportModal}
              setCommentPostId={setCommentPostId}
              showReply={showReply}
              onReplyEdit={handleEditReply}
              onReplyDelete={handleDeleteReply}
              setShowHighlightedComment={comment.highlighted}
            />
          ))}
          {totalComments > 8 && !noMoreComments && (
            <button className="view-more-comments" onClick={() => handleViewMoreComments()} role="link">
              <span className="comment-count ">{translate('quickplay.view-more-comments')}</span>
            </button>
          )}
        </div>
        <div className="comment_input">
          <div className="input_field">
            <form
              action=""
              onSubmit={event => {
                event.preventDefault();
              }}
            >
              <div className="input_row">
                {!replyComment && (
                  <textarea
                    ref={inputRef}
                    placeholder={`${translate('quickplay.start-typing')}...`}
                    aria-label="comment"
                    aria-describedby="comment"
                    value={comment}
                    onChange={handleCommentChange}
                    className="comment-textarea"
                    rows={rows}
                    onKeyDown={handleKeyDown}
                    onInput={handleInput}
                    maxLength={200}
                  ></textarea>
                )}
                {replyComment && (
                  <textarea
                    ref={inputRef}
                    placeholder="Write a reply"
                    aria-label="comment"
                    aria-describedby="comment"
                    value={reply}
                    onChange={handleReplyChange}
                    className="comment-textarea"
                    rows={rows}
                    onKeyDown={handleKeyDown}
                    onInput={handleInput}
                    maxLength={200}
                  />
                )}
                <button
                  type="submit"
                  id="button-addon2"
                  className={isTyping ? '' : 'themeColorBtn'}
                  disabled={isTyping}
                  onClick={event => {
                    setRows(1);
                    if (commentToUpdate && (commentToUpdate.postId || commentToUpdate.commentRefId)) {
                      handleUpdateComment({
                        event,
                        id: commentToUpdate.postId,
                        refId: commentToUpdate.commentRefId,
                      });
                    } else {
                      if (replyComment && replyData && !replyToUpdate) {
                        handleCreateReply(event, replyData);
                      } else if (replyToUpdate && (replyToUpdate.postId || replyToUpdate.commentRefId)) {
                        handleUpdateReply({
                          event,
                          commentId: replyToUpdate.commentId,
                          commentRef: replyToUpdate.commentRef,
                          replyId: replyToUpdate.postId,
                          replyRefId: replyToUpdate.commentRefId,
                        });
                      } else {
                        handleCreateComment(event);
                      }
                    }
                  }}
                >
                  {isLoading ? <LoaderDark /> : <SubmitArrow />}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
