import { PREF_2FA_CHANNEL } from '@fe-monorepo/hooks';
import { store } from '@fe-monorepo/store';

import { stringToHash256 } from './function';

// Nothing should be stringified
// General events
// If an event does not have an applicable trigger, we can descope

export type PageView = {
  name: 'pageview';
  properties: {};
};

export type ScreenView = {
  name: 'screen_view';
  properties: {};
};

export type ClickedAppStoreEvent = {
  name: 'clicked_app_link';
  properties: {
    store_name: 'Apple' | 'Google';
  };
};

export type AuthMethod = 'Google' | 'Facebook' | 'Apple' | 'Twitter' | 'Email';

export const socialAuthMethods = ['google', 'facebook', 'apple', 'twitter'];

export const isValidSocialAuthMethod = (text: string): boolean => {
  return socialAuthMethods.includes(text.toLocaleLowerCase());
};

// Auth events
export type SignupEvent = {
  name: 'signup';
  properties: {
    method: AuthMethod;
    referral_code: 'Yes' | 'No';
  };
};

export type SignupFailedEvent = {
  name: 'failed_signup';
  properties: {
    step_name: string;
    step_number: number;
    error_code: string;
    error_message: string;
  };
};

export type LoginEvent = {
  name: 'login';
  properties: {
    method: AuthMethod;
  };
};

export type LoginFailedEvent = {
  name: 'failed_login';
  properties: {
    step_name: string;
    step_number: number;
    error_code: string;
    error_message: string;
  };
};

export type OTPConfirmationEvent = {
  name: 'confirmed_otp';
  properties: {
    otp_method: PREF_2FA_CHANNEL;
  };
};

export type OTPFailedEvent = {
  name: 'failed_otp';
  properties: {
    method: PREF_2FA_CHANNEL;
    error_code: string;
    error_message: string;
  };
};

export type SearchEvent = {
  name: 'site_search';
  properties: {
    search_term: string;
    // This represents the total amount of results found
    results: number;
  };
};

export type SubscribedNewsletterEvent = {
  name: 'subscribed_newsletter';
  properties: {};
};

// This is the Item from Google's document
// https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag
export type ProductItem = {
  // The product categories on GTM go up to 5 levels
  item_category: string;
  item_category2: string;
  item_id: string;
  item_name: string;
  item_brand?: string;
  affiliation: string;
  price: number;
  quantity?: number;
  stock?: boolean;
  // Send a stringified list
  item_variant?: string;
  location_id?: string;
  coupon?: string;
  discount?: number;
  index?: number;
};

// TODO: these view item events are vague, needs to be elaborated
// TODO: Are these events for every list or just shop items?
// GA4 standard naming for product is "item"
// Trigger this event when the user lands on the shop category page
// Pagination?? send seperate lists of products but with the same id
export type ViewItemListEvent = {
  name: 'view_item_list';
  properties: {
    item_list_id: string;
    item_list_name: string;
    currency?: string;
    items: ProductItem[];
  };
};

// TODO: again, what is this exactly???
// Trigger this when the user visits the product detailed page
export type ViewItemEvent = {
  name: 'view_item';
  properties: {
    currency: string;
    // TODO: what is this?
    // This is fine, send an array of one product
    items: ProductItem[];
  };
};

// Shop events
export type AddToCartEvent = {
  name: 'add_to_cart';
  properties: {
    currency: string;
    value: number;
    items: ProductItem[];
  };
};

export type ViewCartEvent = {
  name: 'view_cart';
  properties: {
    currency: string;
    value: number;
    items: ProductItem[];
  };
};

// TODO: Only one item is removed, why is the items property an array?
export type RemoveFromCartEvent = {
  name: 'remove_from_cart';
  properties: {
    currency: string;
    value: number;
    items: ProductItem[];
  };
};

// TODO: Should the event be called after the promo code is entered or when the pay button is clicked?

// TODO:
// why is the coupon property repeated?
// what if the user enters the coupon before clicking the pay button?

// If the user doesnt enter a coupon, send an empty string
export type BeginCheckoutEvent = {
  name: 'begin_checkout';
  properties: {
    currency: string;
    value: number;
    coupon?: string;
    items: ProductItem[];
  };
};

// TODO: when is this called?
export type AddShippingInfoEvent = {
  name: 'add_shipping_info';
  properties: {
    currency: string;
    value: number;
    items: ProductItem[];
    coupon?: string;
    // TODO: what is this???
    // Delivery, pickup (it's just delivery, pass it as is or remove the property)
    shipping_tier: 'delivery';
  };
};

export type AddPaymentInfoEvent = {
  name: 'add_payment_info';
  properties: {
    // TODO: Does marketing want to know the selected currency?
    currency: string;
    value: number;
    items: ProductItem[];
    coupon?: string;
    // TODO: what are the payment types?
    // Wallet, Card, ApplePay, Tabby, STCPay
    // Pass the values from the backend as is
    // "Wallet" | "Card" | "ApplePay" | "Tabby" | "STCPay"
    payment_type: string;
  };
};

// We should only execute this if the backend returns a successful purchase
// with the necessary info...

// TODO: is the transaction_id the same as the order ID??? Yup
export type PurchaseEvent = {
  name: 'purchase';
  properties: {
    currency: string;
    value: number;
    coupon?: string;
    shipping: number;
    tax: number;
    transaction_id: string;
    items: ProductItem[];
    payment_type: string;
  };
};

export type PurchaseFailedEvent = {
  name: 'failed_purchase';
  properties: {
    error_code: string;
    error_message: string;
    currency: string;
    value: number;
    coupon?: string;
    shipping: number;
    tax: number;
    transaction_id: string;
    items: ProductItem[];
  };
};

// TODO: is this done when the user returns the order or requests a refund for the order?
// Is this before the rejection of the product?
// There is a difference
export type RefundEvent = {
  name: 'refund';
  properties: {
    currency: string;
    value: number;
    coupon?: string;
    shipping: number;
    tax: number;
    transaction_id: string;
    items: ProductItem[];
  };
};

// TODO: I need examples
// Does this fire when the app crashes or stops responding?
// This is a generic event for any error. It is used to moniter the trend of errors after each release
export type ErrorEncounteredEvent = {
  name: 'encountered_error';
  properties: {
    error_code: string;
    error_page: string;
    error_message: string;
    error_API: string;
  };
};

type TournamentProperties = {
  tournament_name: string;
  tournament_registration_start_time: string;
  tournament_registration_end_time: string;
  tournament_start_time: string;
  tournament_game: string;
  tournament_platform: string[];
  tournament_total_players: number;
  tournament_category: string;
  tournament_type: string;
  tournament_creator: string;
  // TODO: elaborate these three
  // According to Sultan, these are "Free" for now
  tournament_registration: 'Paid' | 'Free';
  tournament_format: string;
  tournament_region: string;
};

// Tournament detailed page
export type ViewedTournamentEvent = {
  name: 'viewed_tournament';
  properties: TournamentProperties;
};

export type JoinedTournamentEvent = {
  name: 'joined_tournament';
  properties: TournamentProperties;
};

// TODO: what does "completed" mean here?
// TODO: we don't have a trigger for this yet
export type CompletedTournamentEvent = {
  name: 'completed_tournament';
  properties: TournamentProperties;
};

// TODO: since only stcplay can create tournaments, what do we need this for?
// TODO: we don't have a trigger for this because only stc play can create tournaments
export type CreatedTournamentEvent = {
  name: 'created_tournament';
  properties: TournamentProperties;
};

export type MatchmakingProperties = {
  game_type: string;
  game_platform: string;
  game_language: string;
};

export type StartedMatchmakingEvent = {
  name: 'started_matchmaking';
  properties: MatchmakingProperties;
};

// If both users find each other
// TODO: needs to be elaborated
export type MatchFoundEvent = {
  name: 'found_match';
  properties: MatchmakingProperties;
};

// TODO: does this mean that no matches were found??? exactly
export type MatchmakingFailedEvent = {
  name: 'failed_matchmaking';
  properties: {
    game_type: string;
    game_platform: string;
    game_language: string;
    error_code: string;
    error_message: string;
  };
};

//MiniGames
export type SelectMiniGameEvent = {
  name: 'selected_minigame';
  properties: {
    minigame_name: string;
    minigame_id: string;
  };
};

export type StartedMiniGameEvent = {
  name: 'started_minigame';
  properties: {
    minigame_name: string;
    minigame_id: string;
    start_time: string;
  };
};

export type EndedMiniGameEvent = {
  name: 'ended_minigame';
  properties: {
    minigame_name: string;
    minigame_id: string;
    end_time: string;
  };
};

type AuthEvents = SignupEvent | SignupFailedEvent | LoginEvent | LoginFailedEvent;

type OTPEvents = OTPConfirmationEvent | OTPFailedEvent;

export type CartEvents = AddToCartEvent | ViewCartEvent | RemoveFromCartEvent;

type TournamentEvents = ViewedTournamentEvent | JoinedTournamentEvent | CompletedTournamentEvent | CreatedTournamentEvent;

type MatchmakingEvents = StartedMatchmakingEvent | MatchFoundEvent | MatchmakingFailedEvent;

type MiniGamesEvents = SelectMiniGameEvent | StartedMiniGameEvent | EndedMiniGameEvent;

export type EventObject =
  | PageView
  | ScreenView
  | ClickedAppStoreEvent
  | AuthEvents
  | OTPEvents
  | SearchEvent
  | SubscribedNewsletterEvent
  | ViewItemListEvent
  | ViewItemEvent
  | CartEvents
  | BeginCheckoutEvent
  | AddShippingInfoEvent
  | AddPaymentInfoEvent
  | PurchaseEvent
  | PurchaseFailedEvent
  | RefundEvent
  | ErrorEncounteredEvent
  | TournamentEvents
  | MatchmakingEvents
  | MiniGamesEvents;

export const getLoginStatus = (isSignup?: boolean) => {
  if (isSignup) return 'New';
  const persona = store.getState().user.persona ?? '';
  return ['new', 'existing'].includes(persona) ? 'Returning' : 'Guest';
};

export interface CommonUserProperties {
  user_id?: string;
  user_email_hashed?: string;
  user_phone_hashed?: string;
}

export const getCommonUserProperties = () => {
  const { email, mobile_code, mobile, username } = store.getState().user.userContext;
  // TODO: don't forget about country code here
  const mobileNumber = mobile_code && mobile ? (mobile?.startsWith(mobile_code) ? mobile : `${mobile_code} ${mobile}`) : null;
  // TODO: only pass available user properties
  // TODO: if one or more are missing, just omit them
  const commonProperties: CommonUserProperties = {};
  if (username) commonProperties.user_id = username;
  if (email) commonProperties.user_email_hashed = stringToHash256(email);
  if (mobileNumber) commonProperties.user_phone_hashed = stringToHash256(mobileNumber);
  return commonProperties;
};

export const getAllUserProperties = (isSignup?: boolean) => {
  const loginStatus = getLoginStatus(isSignup);
  const commonProperties = getCommonUserProperties();
  const userProperties = {
    login_status: loginStatus,
    ...commonProperties,
  };
  return userProperties;
};

export const getCommonEventProperties = (platform: string) => {
  const language = store.getState().app.language;
  const commonUserProperties = getCommonUserProperties();
  const commonProperties = {
    platform: platform,
    language: language === 'en' ? 'English' : 'Arabic',
    ...commonUserProperties,
  };
  return commonProperties;
};
