import { ReactComponent as CloseSmallComponent } from 'apps/fe-web/src/assets/src/icons/close_small.svg';
import { ReactComponent as CreditCardComponent } from 'apps/fe-web/src/assets/src/icons/credit_card.svg';
import { ReactComponent as HappyFaceComponent } from 'apps/fe-web/src/assets/src/icons/happy_face.svg';
import { ReactComponent as RechargeComponent } from 'apps/fe-web/src/assets/src/icons/recharge.svg';

export enum WebIconNames {
  closeSmall = 'closeSmall',
  creditCard = 'creditCard',
  happyFace = 'happyFace',
  recharge = 'recharge',
}

export interface IconProps {
  name?: WebIconNames;
  iconClasses?: string;
  width?: number;
  height?: number;
  fill?: string;
  theme?: string;
  color?: string;
  platform: 'web';
  viewBox?: string;
  preserveAspectRatio?: string;
  title?: string;
}

let iconsObj: {
  readonly [key in keyof typeof WebIconNames]: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string | undefined }>;
};

iconsObj = {
  closeSmall: CloseSmallComponent,
  creditCard: CreditCardComponent,
  happyFace: HappyFaceComponent,
  recharge: RechargeComponent,
};

export const getWebSvgXml = (props: IconProps) => {
  const { name, width = 24, height = 24, fill, iconClasses } = props;
  if (!name) return null;

  const Icon = iconsObj[name];
  return <Icon className={iconClasses} width={width} height={height} fill={fill} />;
};
