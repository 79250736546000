interface Checkbox {
  isChecked?: boolean;
  id?: string;
  label?: string;
  classNames?: string;
  onClick?: () => void;
  disabled?: boolean;
  width: number;
  height: number;
  defaultClassName?: string;
  imageWidth?: string;
  imageHeight?: string;
  boxBorderStyle?: string;
}

const Checkbox = (props: Checkbox) => {
  const { isChecked, classNames, onClick, disabled, width, height, defaultClassName, imageWidth, imageHeight, boxBorderStyle, id } = props;
  return (
    <div className={`relative h-fit w-full grid items-center ${classNames}`}>
      <label className="flex items-center cursor-pointer">
        <input type="checkbox" checked={isChecked} className="hidden" onChange={onClick} disabled={disabled} id={id} />
        <div
          className={`relative w-[${width}rem] h-[${height}rem] border-[0.125rem] ${
            isChecked ? boxBorderStyle ?? 'border-sunset' : 'border-secondary/50'
          } ${defaultClassName ?? 'rounded-[0.375rem] mr-2 flex items-center justify-center'}`}
        >
          {isChecked && (
            <svg
              width={`${imageWidth ?? '1.5rem'}`}
              height={`${imageHeight ?? '1.5rem'}`}
              viewBox="0 0 18 18"
              fill="#FFFFFF"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="16" height="16" fill="white" />
              <path
                d="M16 0H2C0.89 0 0 0.9 0 2V16C0 17.1 0.89 18 2 18H16C17.11 18 18 17.1 18 16V2C18 0.9 17.11 0 16 0ZM7 14L2 9L3.41 7.59L7 11.17L14.59 3.58L16 5L7 14Z"
                fill="#E95F2A"
              />
            </svg>
          )}
        </div>
      </label>
    </div>
  );
};

export default Checkbox;
