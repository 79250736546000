// @ts-nocheck
import { BrazeCustomEvents, MixPanelCustomEvents } from '@fe-monorepo/helper';
import { useTranslate, useUserProfile } from '@fe-monorepo/hooks';
import { toastWrapper } from '@fe-web/Atoms/Toast';
import mixpanelHelper from '@fe-web/helpers/mixpanelHelper';
import { AppRoutes } from 'apps/fe-web/src/app/app.routes.enum';
import quickplay from 'quickplay-shorts-js';
import { useEffect, useState } from 'react';

import { CONTENT_TYPE, EVENTS } from '../../../../constants';
import { useNotification } from '../../../../contexts/Notification';
import useAnalytics from '../../../../hooks/useAnalytics';
import CustomModal from '../../../common/CustomModal/CustomModal';
import { ReactComponent as CopyLink } from '../../Icons/CopyLink.svg';
import { ReactComponent as Facebook } from '../../Icons/Facebook.svg';
import { ReactComponent as LinkedIn } from '../../Icons/LinkedIn.svg';
import { ReactComponent as Twitter } from '../../Icons/Twitter.svg';

export default function Share({ video, setShareCount, show, handleClose }: any) {
  const [isRtl, setIsRtl] = useState(false);
  const { trackEvent } = useAnalytics();
  const { showNotification, closeNotification } = useNotification();
  const { translate } = useTranslate();
  const { user: currentUser } = useUserProfile();

  const usedHashTagTitle = Array.isArray(video.hashtagTitles) ? video.hashtagTitles.join(',') : '';

  const websiteUrl = `${window.location.origin}${AppRoutes.bitsPlay}/content/${video?.videoId}?contentType=${CONTENT_TYPE.SHARE}`;

  const SHARE_PLATFORMS = [
    {
      id: 'facebook',
      name: `${translate('quickplay.facebook')}`,
      icon: <Facebook />,
      url: `https://www.facebook.com/sharer.php?u=${websiteUrl}`,
    },
    {
      id: 'twitter',
      name: `${translate('quickplay.twitter')}`,
      icon: <Twitter />,
      url: `https://twitter.com/intent/tweet?url=${websiteUrl}&text=${video.description}&hashtags=${usedHashTagTitle}&`,
    },
    {
      id: 'linkedin',
      name: `${translate('quickplay.linkedin')}`,
      icon: <LinkedIn />,
      url: `https://www.linkedin.com/sharing/share-offsite/?url=${websiteUrl}`,
    },
    {
      id: 'copy-link',
      name: `${translate('quickplay.copy-link-text')}`,
      icon: <CopyLink />,
      url: ``,
    },
  ];

  const handleCopy = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      toastWrapper('success', '' + translate('toast_success.linkCopyToClipboard'));
    } catch (err) {
      console.error('Failed to copy text:', err);
    } finally {
      setTimeout(() => closeNotification(), 3000);
    }
  };

  const incrementCount = () => {
    setShareCount((prev: number) => prev + 1);
  };

  const handleIconClick = (platform: any) => {
    const { url, id } = platform;
    trackEvent({ event: EVENTS.CONTENT_SHARE, content: video, button_type: id })
      .then(res => {
        const eventPayload = {
          Username: currentUser?.username,
          'Bit ID': video.videoId,
          'Bit Title': video.title,
          'Share Method': id,
          'Bits Publisher Username': video.user.userName,
        };
        mixpanelHelper.trackEvent(MixPanelCustomEvents.BitsShared, eventPayload);
        brazeHelper.logCustomBrazeEvent(BrazeCustomEvents.BitsShared, eventPayload);
      })
      .catch(error => {
        console.error('Error tracking event:', error);
      });
    try {
      const activityTimelineModuleObj = new quickplay.QuickPlayActivityTimeline();
      activityTimelineModuleObj
        .activityTimelineShare({
          assetId: video.videoId,
        })
        .then((res, err) => {
          if (res?.data?.success) {
            incrementCount();
          }
        });
    } catch (err) {}
    if (!url) {
      handleCopy(websiteUrl);
    } else {
      window.open(url, '_blank');
    }
    handleClose();
  };

  useEffect(() => {
    const directionElement = document.getElementById('direction');
    if (directionElement && directionElement.getAttribute('dir') === 'rtl') {
      setIsRtl(true);
    }
  }, []);

  return (
    <CustomModal isOpen={show} close={handleClose}>
      <div className={`share_wrapper ${isRtl ? 'share_modal-rtl' : ''}`}>
        <h2 className="modal-title">{translate('quickplay.label-share')}</h2>
        <div className="share_content">
          {SHARE_PLATFORMS.map(platform => (
            <div key={platform.name} className={`${platform.name.toLowerCase()}_icon`}>
              <button className="share-icon-button" onClick={() => handleIconClick(platform)}>
                {platform.icon}
              </button>
              <span>{platform.name}</span>
            </div>
          ))}
        </div>
      </div>
    </CustomModal>
  );
}
