import { AppRoutes } from 'apps/fe-web/src/app/app.routes.enum';
import quickplay from 'quickplay-shorts-js';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as DiscoverIcon } from '../../assets/icons/DiscoverIcon.svg';
import { ReactComponent as HomeIcon } from '../../assets/icons/HomeIcon.svg';
import { ReactComponent as NotificationIcon } from '../../assets/icons/NotificationIcon.svg';
import { ReactComponent as ProfileIcon } from '../../assets/icons/ProfileIcon.svg';
import CreatorButtonIcon from '../../assets/img/images/creator-button.svg';
import { useLoginModalContext } from '../../contexts/LoginModal';

function Bottom() {
  const navigate = useNavigate();
  const [isLoggedin, setIsLoggedin] = useState(false);
  const { setShowLoginModal } = useLoginModalContext();

  useEffect(() => {
    const accessToken = new quickplay.QuickPlayAuthModule().getAccessToken();
    accessToken
      .then((data: any) => {
        // handle success case
        if (data) {
          setIsLoggedin(true);
        }
      })
      .catch((error: any) => {
        // handle error case
        console.error(error);
      });
  }, []);

  const _navigateToProfile = () => {
    const accessToken = new quickplay.QuickPlayAuthModule().getAccessToken();
    accessToken
      .then((data: any) => {
        // handle success case
        if (!data) {
          // Show Login Modal
          setShowLoginModal(true);
        } else {
          navigate(`${AppRoutes.bits}/my-profile`);
        }
      })
      .catch((error: any) => {
        // handle error case
        console.error(error);
      });
  };
  const _navigateToNotification = () => {
    const accessToken = new quickplay.QuickPlayAuthModule().getAccessToken();
    accessToken
      .then((data: any) => {
        // handle success case
        if (!data) {
          // Show Login Modal
          setShowLoginModal(true);
        } else {
          navigate(`${AppRoutes.bits}/notification`);
        }
      })
      .catch((error: any) => {
        // handle error case
        console.error(error);
      });
  };

  return (
    <div className="res-bootom-bar">
      <ul className="res-bootom-bar-ul">
        <li>
          <a href="/vertical-player">
            <HomeIcon />
          </a>
        </li>
        <li>
          <a href="/discover">
            <DiscoverIcon />
          </a>
        </li>
        {isLoggedin && (
          <li className="creator-button">
            <a href="/create-post" className="go-to-page-create-content">
              <img src={CreatorButtonIcon} />
            </a>
          </li>
        )}
        <li>
          <a href="javascript:void(0)" className="go-to-notification" onClick={_navigateToNotification}>
            <NotificationIcon />
          </a>
        </li>
        <li>
          <a href="javascript:void(0)" className="go-to-user-profile" onClick={_navigateToProfile}>
            <ProfileIcon />
          </a>
        </li>
      </ul>
    </div>
  );
}

export default Bottom;
