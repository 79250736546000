import { useMutation } from '@apollo/client';
import { CONTENT_MUTATION } from '@fe-monorepo/data-access';
import { useEffect, useState } from 'react';
import { useMutateQuery } from './useMutateQuery';

import axios from 'axios';
import _ from 'lodash';

/*** LOGIN ***/
export type GeneratedLinksResponse = {
    getGenerateLinks: {
        is_successful: boolean;
        error_code: string;
        error_msg: string;
        data: string[];
    };
};

type GeneratedLinksParams = {
    details: {
        type_code: string;
        ref_id: string;
        ref_type: ContentType;
    };
};

type UploadMediaResponse = {
    uploadMedia: {
        is_successful: boolean;
        error_code: string;
        error_msg: string;
    };
};

export enum ContentType {
    avatar = 'user_avatar',
    banner = 'user_banner',
    screenshot = 'match_screenshot',
    group_avatar = 'group_chat',
    connect = 'connect',
    return = 'return',
}

type LinkGenerationInput = {
    type_code: string;
    ref_id: string;
    ref_type: ContentType;
};

type MediaUploadInput = {
    details: string;
    file: File;
};

const getFileExtensions = (media: File[]) => {
    const mediaExtensions: string[] = [];

    media.forEach(file => {
        const extension = file.type.substring(file.type.indexOf('/') + 1);

        if (extension) {
            mediaExtensions.push(extension);
        }
    });

    return mediaExtensions;
};

const extractMediaURL = (url: string, extension: string) => {
    const fromIndex = url.indexOf('.com/') + 5;

    const toIndex = url.indexOf('.%20');

    const newURL = url.substring(fromIndex, toIndex);

    return `https://${newURL}${extension}`;
};

type Media = { files: File[]; paths: string[]; extensions: string[] };

// TODO: refactor this
const useMediaUpload = () => {
    const [isUploadLoading, setIsUploadLoading] = useState<boolean>(false);
    const [media, setMedia] = useState<Media>({ files: [], paths: [], extensions: [] });
    const [mediaLinks, setMediaLinks] = useState<string[]>([]);

    const linksGeneration = useMutateQuery<string[], LinkGenerationInput>(CONTENT_MUTATION.getGenerateLinks, []);

    const uploadMedia = async (url: string, media: { file: File; path: string; extension: string }) => {
        setIsUploadLoading(true);

        const res = await axios.put(url, media.file, { headers: { 'Content-Type': media?.file?.type } });

        setIsUploadLoading(false);

        if (res.status === 200) {
            const fileExtension = media.extension === 'svg+xml' ? 'svg%20xml' : media.extension;
            const extensionIndex = url.lastIndexOf(`.${fileExtension}`) + fileExtension?.length + 1;
            setMediaLinks(oldMediaLinks => [...oldMediaLinks, url.substring(0, extensionIndex)]);
        }
    };

    const getGeneratedLinks = async (media: { file: File; path: string }[], ref_id: string, ref_type: ContentType) => {
        const files = media.map(item => item.file);
        const paths = media.map(item => item.path);

        const extensions = getFileExtensions(files);

        setMedia({ files, paths, extensions });

        return await linksGeneration.mutate({
            type_code: JSON.stringify(extensions),
            ref_id: ref_id,
            ref_type: ref_type,
        });
    };

    const getGeneratedLinksReturn = async (media: { file: File; path: string }[], ref_id: string, ref_type: ContentType) => {
        const files = media.map(item => item.file);
        const paths = media.map(item => item.path);

        const extensions = getFileExtensions(files);

        setMedia({ files, paths, extensions });

        const result = await linksGeneration.mutate({
            type_code: JSON.stringify(extensions),
            ref_id: ref_id,
            ref_type: ref_type,
        });

        return result;
    };

    const generateLink = async (media: { file: File; path: string }[], ref_id: string, ref_type: ContentType) => {
        const files = media.map(item => item.file);
        const extensions = getFileExtensions(files);

        const res = await linksGeneration.mutate({
            type_code: JSON.stringify(extensions),
            ref_id,
            ref_type,
        });

        const linkGenerated = res.data?.getGenerateLinks?.data[0];
        const transformedLink = linkGenerated?.split('?')[0];
        return transformedLink;
    };

    useEffect(() => {
        if (linksGeneration.isValid && !linksGeneration.isLoading) {
            const generatedLinks = linksGeneration.data;

            generatedLinks?.forEach((generatedLink, index) => {
                uploadMedia(generatedLink, { file: media.files[index], path: media.paths[index], extension: media.extensions[index] });
            });
        }
    }, [linksGeneration.isValid, linksGeneration.isLoading]);

    return {
        mediaLinks,
        generatedLinks: linksGeneration.data,
        getGeneratedLinks,
        generateLink,
        isLoading: linksGeneration.isLoading || isUploadLoading,
        getGeneratedLinksReturn,
    };
};

export { useMediaUpload };

export const useUploadMedia = () => {
    // const [uploadLinks, setUploadLinks] = useState<string[]>([]);
    // const [mediaLinks, setMediaLinks] = useState<string[]>([]);
    // const [media, setMedia] = useState<any[]>([]);
    // const [extensions, setExtensions] = useState<any[]>([]);

    const [uploading, setUploading] = useState<boolean>(false);

    const [generateLinksGql] = useMutation<GeneratedLinksResponse, GeneratedLinksParams>(CONTENT_MUTATION.getGenerateLinks, {
        errorPolicy: 'all',
    });

    const [uploadMediaFile] = useMutation<void>(CONTENT_MUTATION.uploadMedia, {
        errorPolicy: 'all',
    });

    const getGeneratedLinks = async ({ type_code, ref_type }: Omit<GeneratedLinksParams['details'], 'ref_id'>) => {
        const ref_id = String(_.random(10000, 90000));

        const { data } = await generateLinksGql({
            variables: {
                details: {
                    type_code,
                    ref_id,
                    ref_type,
                },
            },
        });

        return data;
    };

    const uploadImageFile = async ({ imageType, ref_type, file }: { imageType: string; ref_type: ContentType; file: any }) => {
        setUploading(true);

        const generatedLinks = await getGeneratedLinks({
            type_code: imageType,
            ref_type,
        });

        if (!generatedLinks || generatedLinks?.getGenerateLinks?.data.length === 0) {
            setUploading(false);

            return false;
        }

        const linkForUpload = generatedLinks.getGenerateLinks.data[0];

        await uploadMediaFile({ variables: { details: linkForUpload, file } });

        setUploading(false);
    };

    return {
        getGeneratedLinks,
        uploadImageFile,
        uploading,
    };
};

//NOTE - Generate links content type
// {
//     "tournament" => "tournament",
//     "bit" => "post",
//     "user_logo" => "user_logo",
//     "user_banner" => "user_banner",
//     "tournment_report" => "tournment_report",
//     "contact_report" => "contact"
// }
