import { useMutation } from '@apollo/client';
import { REGISTER_DEVICE_MUTATION } from '@fe-monorepo/data-access';

import { RegisterDeviceParams, RegisterDeviceResponse } from './type';

export const useRegisterDevice = () => {
  const [registerDeviceParams, { data: registerDeviceParamsResponse, error, loading: isLoading }] = useMutation<RegisterDeviceResponse>(
    REGISTER_DEVICE_MUTATION.registerDevice,
    {
      errorPolicy: 'all',
    },
  );

  const registerDevice = async (params: RegisterDeviceParams) => {
    const { data } = await registerDeviceParams({ variables: { ...params } });
    return data?.registerDevice;
  };

  return {
    registerDevice,
    isLoading,
    error,
  };
};