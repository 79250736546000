import quickplay from 'quickplay-shorts-js';

const defaultLanguage: any = localStorage.getItem('defaultLanguage');

export const getLocalisedText = (obj: any, defaultKey: string) => {
  const localisedValue = obj?.[`localised${defaultKey.charAt(0).toUpperCase()}${defaultKey.slice(1)}`]?.[defaultLanguage];
  return localisedValue ?? obj?.[defaultKey] ?? '';
};

export const isLoggedin = async () => {
  const accessToken = await new quickplay.QuickPlayAuthModule().getAccessToken();
  return Boolean(accessToken);
};
