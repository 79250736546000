import { MixPanelCustomEvents } from '@fe-monorepo/helper';
import Button from '@fe-web/Atoms/Buttons/Button';
import mixpanelHelper from '@fe-web/helpers/mixpanelHelper';
import useCookieConsent from '@fe-web/hooks/useCookieConsent';
import useHeaderState from '@fe-web/hooks/useHeaderState';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

const CookiesPopup = () => {
  const { t } = useTranslation();
  const { dir } = useHeaderState();
  const { acceptCookies, rejectCookies } = useCookieConsent();

  const handleAccept = () => {
    acceptCookies();
    mixpanelHelper.trackEvent(MixPanelCustomEvents.CookiesPopupAcceptAll, {
      'Session ID': uuidv4(),
      Timestamp: new Date().toISOString().slice(0, 16).replace('T', ' '),
    });
  };

  const handleDecline = () => {
    rejectCookies();
    mixpanelHelper.trackEvent(MixPanelCustomEvents.CookiesPopupRejectAll, {
      'Session ID': uuidv4(),
      Timestamp: new Date().toISOString().slice(0, 16).replace('T', ' '),
    });
  };

  return (
    <div className="fixed bottom-0 left-0 right-0 flex z-50 justify-center px-4 sm:px-8 m-16">
      <div
        role="dialog"
        aria-labelledby="cookie-modal-title"
        aria-describedby="cookie-modal-description"
        className="w-full max-w-[1200px] bg-primary p-[1rem] rounded-[0.5rem] mb-4"
      >
        <h2 id="cookie-modal-title" className="font-medium text-secondary sm:text-2xl text-fs-body-large">
          {t('cookie.cookie_notice')}
        </h2>
        <p id="cookie-modal-description" className="text-secondary mt-8 sm:text-sm text-xs">
          {t('cookie.cookie_concent')}
        </p>

        <div className="flex md:justify-end justify-center mt-8">
          <div className="flex flex-row gap-16 max-w-[568px] w-full">
            <Button
              text={t('cookie.reject_all') ?? 'Reject all'}
              style={`text-base not-italic h-[2.5rem] flex-1 font-medium
                border-[0.063rem] border-sunset text-sunset rounded-sm`}
              normalStyle={`hover:border-sunset hover:text-primary
                ${dir === 'ltr' ? 'after:bg-primary before:bg-sunset' : 'before:bg-primary after:bg-sunset'}`}
              onClick={handleDecline}
            />
            <Button
              text={t('cookie.allow_all')}
              style={`text-base not-italic h-[2.5rem] flex-1 font-medium
                border-[0.063rem] border-sunset text-white bg-sunset rounded-sm`}
              normalStyle={`hover:border-sunset hover:text-sunset
                ${dir === 'ltr' ? 'after:bg-sunset before:bg-primary' : 'before:bg-sunset after:bg-primary'}`}
              onClick={handleAccept}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookiesPopup;
