import { BrazeCustomEvents, MixPanelCustomEvents } from '@fe-monorepo/helper';
import { useTranslate, useUserProfile } from '@fe-monorepo/hooks';
import { toastWrapper } from '@fe-web/Atoms/Toast';
import { CONST_NUM_1, CONST_NUM_10 } from '@fe-web/constant/constants';
import brazeHelper from '@fe-web/helpers/brazeHelper';
import mixpanelHelper from '@fe-web/helpers/mixpanelHelper';
import { AppRoutes } from 'apps/fe-web/src/app/app.routes.enum';
import Hls from 'hls.js';
import quickplay from 'quickplay-shorts-js';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { ReactComponent as SoundIcon } from '../../../assets/icons/SoundIcon.svg';
import { ReactComponent as VideoFullScreenIcon } from '../../../assets/icons/VideoFullScreenIcon.svg';
import { ReactComponent as VideoMuteIcon } from '../../../assets/icons/VideoMuteIcon.svg';
import { ReactComponent as VideoPauseIcon } from '../../../assets/icons/VideoPauseIcon.svg';
import { ReactComponent as VideoPlayIcon } from '../../../assets/icons/VideoPlayIcon.svg';
import { ReactComponent as VideoUnmuteIcon } from '../../../assets/icons/VideoUnmuteIcon.svg';
import { TOAST_TYPES } from '../../../constants';
import { useNotification } from '../../../contexts/Notification';
import CustomModal from '../../common/CustomModal/CustomModal';
import Loader from '../../common/Loader';
import LoaderDark from '../../common/LoaderDark/LoaderDark';
import Input from '../Input';
import InputWithChips from '../InputWithChips';
import SidePanel from '../SidePanel';
import Stepper from '../Stepper';
import './StepOne.css';

function PostCreateStepTwo() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isRepost = searchParams.get('repost') === 'true';
  const isHashtag = searchParams.get('hashtag') === 'true';
  const originalVideoId = searchParams.get('originalVideoId');
  const { translate } = useTranslate();
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [currentVideoBlobList, setCurrentVideoBlobList] = useState<any>([]);
  const [currentVideoList, setCurrentVideoList] = useState<any>([]);
  const [categoryOptions, setCategoryOptions] = useState<any>([]);
  const [showTagPanel, setShowTagPanel] = useState({
    sound: false,
    hashtag: false,
    user: false,
  });
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const { showNotification } = useNotification();
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const hls = useRef<Hls>();
  const [show, setShow] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const progressRef = useRef<HTMLDivElement>(null);
  const { user: currentUser } = useUserProfile();
  const videoList = isRepost ? currentVideoList : currentVideoBlobList;
  const [videoLength, setVideoLength] = useState<number>(0);

  const [formData, setFormData] = useState({
    title: '',
    description: '',
    visibility: 'public',
    follow: true,
    type: 'camera-upload',
    categoryId: 'web',
    categoryName: 'web',
    contentUrl: [],
    s3Url: '',
    commentEnabled: true,
    likeEnabled: true,
    shareEnabled: true,
    repost: false,
    soundId: '',
    soundName: '',
    taggedUsers: [],
    hashtags: Array(0),
    sounds: [],
    ...(originalVideoId && { originalVideoId }), // Include only if originalVideoId exists
  });

  useEffect(() => {
    const useHashtag = localStorage.getItem('hashtag');

    async function fetchData() {
      if (isHashtag) {
        try {
          const hashTagModuleObj = new quickplay.QuickPlayHashTag();
          const limit = CONST_NUM_10;
          const offset = CONST_NUM_1;
          const hashTagResponse = await hashTagModuleObj.getHashTagDetails({
            limit: limit,
            offset: offset,
            name: useHashtag,
          });
          if (hashTagResponse?.status === 200)
            if (useHashtag) {
              const hashtagObj = hashTagResponse.data.result.hashtag;
              setFormData(prevFormData => ({
                ...prevFormData,
                hashtags: [hashtagObj],
              }));
            }
        } catch (error) {
          console.error(error);
        }
      }
    }
    fetchData();
  }, [isHashtag]);

  useEffect(() => {
    const storedVideosBlobs: any = sessionStorage.getItem('blobUrls');
    const storedVideoUrls = sessionStorage.getItem('videoUrls');
    if (!storedVideosBlobs && !isRepost) {
      navigate(`${AppRoutes.bits}/create-post/step-1`);
      return;
    }
    const blobsList = JSON.parse(storedVideosBlobs);
    const videosList = JSON.parse(storedVideoUrls!);
    setCurrentVideoList(videosList);
    setCurrentVideoBlobList(blobsList);

    // Calculate video length
    const calculateVideoLength = () => {
      const videoUrl = blobsList[0];
      const video = document.createElement('video');
      video.src = videoUrl;
      video.addEventListener('loadedmetadata', () => {
        setVideoLength(video.duration);
      });
    };

    if (videosList.length > 0) {
      calculateVideoLength();
    }

    setFormData((prevFormData: any) => ({
      ...prevFormData,
      contentUrl: { type: 'video', urls: videosList },
      s3Url: videosList[0],
    }));
  }, []);

  useEffect(() => {
    const videoElement = videoRef.current as never as HTMLVideoElement;
    if (!videoElement || !videoList.length) return;

    // Check file extension to determine video type
    const fileExtension = videoList[0].split('.').pop()?.toLowerCase();

    if (fileExtension === 'm3u8') {
      // HLS video
      if (Hls.isSupported()) {
        // Initialize HLS.js
        hls.current = new Hls();
        hls.current.loadSource(videoList[0]);
        hls.current.attachMedia(videoElement);

        // Cleanup
        return () => {
          if (hls.current) {
            hls.current.destroy();
          }
        };
      } else {
        console.error('HLS is not supported in this browser.');
      }
    } else if (['mp4', 'mov', 'm4v'].includes(fileExtension)) {
      // MP4 video
      videoElement.src = videoList[0];
    } else {
      console.error('Unsupported video format.');
    }
  }, [videoList, videoRef]);

  useEffect(() => {
    async function fetchCategories() {
      try {
        const feedModuleObj = new quickplay.QuickPlayFeedModule();
        const feedModuleResponse = await feedModuleObj.getCategoryList({
          search: '',
          limit: 100,
          offset: 1,
        });
        if (feedModuleResponse.status === 200) {
          const categoryList = feedModuleResponse.data.result;
          if (categoryList && categoryList.length) {
            setCategoryOptions(categoryList);
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchCategories();
  }, []);

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;

    // Prevent spacebar input when the field is empty
    if (/^\s*$/.test(value)) {
      setFormData(prevFormData => ({ ...prevFormData, [name]: '' }));
    } else {
      setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    }
  };

  const handleFileUploadChange = async (event: any) => {
    const fileUploaded = event.target.files[0];
    if (fileUploaded.type !== 'video/mp4' && fileUploaded.type !== 'video/mpeg') {
      toastWrapper('error', '' + `${translate('quickplay.label-type-error')}`);
    } else {
      const fileSize = fileUploaded.size / 1024 / 1024;
      if (fileSize > 50) {
        setIsLoading(false);
        toastWrapper('error', '' + `${translate('quickplay.label-size-error')}`);
        videoRef.current = null;
        return;
      }
      const videoElement = document.createElement('video');
      videoElement.preload = 'metadata';
      videoElement.onloadedmetadata = () => {
        window.URL.revokeObjectURL(videoElement.src);
        const duration = videoElement.duration;
        setVideoLength(duration);
        if (duration > 60 || duration < 6) {
          toastWrapper('error', '' + `${translate('quickplay.label-duration-error')}`);
          videoRef.current = null;
          return;
        } else {
          readFile(fileUploaded);
        }
      };

      videoElement.onerror = () => {
        setIsLoading(false);
        toastWrapper('error', '' + `${translate('quickplay.label-default-error')}`);
        videoRef.current = null;
      };

      videoElement.src = URL.createObjectURL(fileUploaded);
    }
    event.target.value = null;
  };

  const readFile = (inputFile: any) => {
    if (inputFile) {
      setIsLoading(true);
      if (inputFile.type !== 'video/mp4' && inputFile.type !== 'video/mpeg') {
        return false;
      }

      const reader = new FileReader();
      reader.onload = async function (e) {
        const signedUrlResponse = await getSignedUrl();
        if (signedUrlResponse.status === 200) {
          const signedUrl = signedUrlResponse.data.result.uploadUrl;
          const videoUrl = signedUrlResponse.data.result.url;
          const file = inputFile;
          const requestOptions: any = {
            method: 'PUT',
            body: file,
            redirect: 'follow',
          };
          fetch(signedUrl, requestOptions)
            .then(response => {
              setIsLoading(false);
              const file1 = inputFile;
              const videoUrlBlob = URL.createObjectURL(file1);
              sessionStorage.setItem('videoUrls', JSON.stringify([videoUrl]));
              sessionStorage.setItem('blobUrls', JSON.stringify([videoUrlBlob]));

              const newVideoList = [videoUrl];
              setCurrentVideoBlobList([videoUrlBlob]);
              setCurrentVideoList(newVideoList);
              setFormData((prevFormData: any) => ({
                ...prevFormData,
                contentUrl: { type: 'video', urls: newVideoList },
              }));
            })
            .then(result => {
              setIsLoading(false);
            })
            .catch(error => {
              setIsLoading(false);
            });
        } else {
          if (signedUrlResponse.status === 401) {
            localStorage.clear();
            sessionStorage.clear();
            navigate('/sign-in');
          }
        }
      };

      reader.readAsDataURL(inputFile);
    }
  };

  const getSignedUrl = async () => {
    const feedModuleObj = new quickplay.QuickPlayFeedModule();
    const feedModuleResponse = await feedModuleObj.getSignedUrl({
      type: 'video',
    });
    return feedModuleResponse;
  };

  const validateForm = () => {
    const newErrors: any = {};
    if (currentVideoList.length === 0) {
      newErrors.contentVideo = translate('quickplay.media-valid');
    }
    if (!formData.title) {
      newErrors.title = translate('quickplay.title-valid');
    }

    if (!formData.description) {
      newErrors.description = translate('quickplay.description-valid');
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handlePostSubmit = async (event: any) => {
    event.preventDefault();
    if (validateForm()) {
      if (isRepost) {
        formData.repost = true;
      }
      const { sounds, ...restFormData } = formData ?? {};
      const payload = { ...restFormData };
      setIsLoading(true);
      const feedModuleObj = new quickplay.QuickPlayFeedModule();
      const feedModuleResponse = await feedModuleObj.uploadContent(payload);
      if (feedModuleResponse.status === 200 || feedModuleResponse.status === 201) {
        sessionStorage.removeItem('videoUrl');
        localStorage.removeItem('hashtag');
        setIsLoading(false);
        showNotification({
          title: 'Create Content',
          subTitle: feedModuleResponse.data.statusMessage,
          type: TOAST_TYPES.SUCCESS,
        });
        navigate(`${AppRoutes.bits}/create-post/step-3`);
        const eventPayload = {
          Username: currentUser?.username,
          'Bit ID': feedModuleResponse.data.result.videoId,
          'Bit Length': videoLength,
          'Bit Title': feedModuleResponse.data.result.title,
        };
        mixpanelHelper.trackEvent(MixPanelCustomEvents.BitSubmitted, eventPayload);
        brazeHelper.logCustomBrazeEvent(BrazeCustomEvents.BitSubmitted, eventPayload);
      } else {
        setIsLoading(false);
        showNotification({
          title: 'Create Content',
          subTitle: feedModuleResponse?.data?.statusMessage,
          type: TOAST_TYPES.ERROR,
        });
      }
    }
  };

  const togglePanel = (type: any) => {
    setShowTagPanel(prev => ({
      ...prev,
      ...(type === 'sound' && { sound: !prev.sound }),
      ...(type === 'user' && { user: !prev.user }),
      ...(type === 'hashtag' && { hashtag: !prev.hashtag }),
    }));
  };

  const handleClose = () => setShow(false);

  const handleCategoryChange = (event: any) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      categoryId: event.target.value,
    }));
  };

  const commentToggleHandler = () => {
    setFormData(prevFormData => ({
      ...prevFormData,
      commentEnabled: !prevFormData.commentEnabled,
    }));
  };

  const likeToggleHandler = () => {
    setFormData(prevFormData => ({
      ...prevFormData,
      likeEnabled: !prevFormData.likeEnabled,
    }));
  };

  const shareToggleHandler = () => {
    setFormData(prevFormData => ({
      ...prevFormData,
      shareEnabled: !prevFormData.shareEnabled,
    }));
  };

  const handleShow = () => setShow(true);

  const handleRemoveHashtag = (hashtag: any) => {
    const indexToRemove = formData.hashtags.findIndex((taggedHashtag: any) => taggedHashtag.id === hashtag.id);
    if (indexToRemove !== -1) {
      formData.hashtags.splice(indexToRemove, 1);
    }
    setFormData((prev: any) => ({
      ...prev,
      hashtags: [...formData.hashtags],
    }));
  };

  const handleRemoveUser = (user: any) => {
    const indexToRemove = formData.taggedUsers.findIndex((taggedUser: any) => taggedUser.id === user.id);
    if (indexToRemove !== -1) {
      formData.taggedUsers.splice(indexToRemove, 1);
    }
    setFormData((prev: any) => ({
      ...prev,
      taggedUsers: [...formData.taggedUsers],
    }));
  };

  useEffect(() => {
    const video: any = videoRef.current;
    if (video) {
      video.addEventListener('timeupdate', handleTimeUpdate);
      video.addEventListener('loadedmetadata', handleLoadedMetadata);
      video.addEventListener('ended', handleVideoEnded);
    }
    return () => {
      if (video) {
        video.removeEventListener('timeupdate', handleTimeUpdate);
        video.removeEventListener('loadedmetadata', handleLoadedMetadata);
        video.removeEventListener('ended', handleVideoEnded);
      }
    };
  }, []);

  const handlePlayPause = () => {
    const video: any = videoRef.current;
    if (video) {
      if (isPlaying) {
        video.pause();
      } else {
        video.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleMuteUnmute = () => {
    const video: any = videoRef.current;
    if (video) {
      video.muted = !isMuted;
      setIsMuted(!isMuted);
    }
  };

  const handleTimeUpdate = () => {
    const video: any = videoRef.current;
    if (video) {
      setCurrentTime(video.currentTime);
    }
  };

  const handleLoadedMetadata = () => {
    const video: any = videoRef.current;
    if (video) {
      setDuration(video.duration);
    }
  };

  const handleFullScreen = () => {
    const video: any = videoRef.current;
    video?.requestFullscreen();
  };

  const handleVideoEnded = () => {
    setIsPlaying(false);
  };

  const handleProgressClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const progressBar = progressRef.current;
    const video: any = videoRef.current;
    if (progressBar && video) {
      const rect = progressBar.getBoundingClientRect();
      const clickX = e.clientX - rect.left;
      const newTime = (clickX / rect.width) * video.duration;
      video.currentTime = newTime;
      setCurrentTime(newTime);
    }
  };

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  return (
    <>
      {isLoading && <Loader />}
      <Stepper />
      <div className="creat-full uploadInputWrapper">
        <div className="creat-box-02">
          <div className="img-sec">
            <div className="img-box upload-content">
              <video ref={videoRef} src={videoList[0]} className="full"></video>
            </div>
            <div className="videoControls">
              <div className="videoControlRow">
                <div className="videoControl_playpause">
                  <button onClick={handlePlayPause}>{isPlaying ? <VideoPauseIcon /> : <VideoPlayIcon />}</button>
                  <span className="videoTime">
                    {formatTime(currentTime)} / {formatTime(duration)}
                  </span>
                </div>

                <div className="videoControl_muteunmute">
                  <button onClick={handleMuteUnmute}>{isMuted ? <VideoUnmuteIcon /> : <VideoMuteIcon />}</button>
                  <button onClick={handleFullScreen}>
                    <VideoFullScreenIcon />
                  </button>
                </div>
              </div>
              <div className="progress-bar" ref={progressRef} onClick={handleProgressClick}>
                <div className="progress" style={{ width: `${(currentTime / duration) * 100 || 0}%` }}>
                  <div className="progress-handle"></div>
                </div>
              </div>
            </div>
            <div className="mt-8 creat-add-btn">
              <form action="" method="POST" encType="multipart/form-data">
                <ul>
                  {videoList.map((videoUrl: string) => (
                    <li key={videoUrl}>
                      <div className="inner-box">
                        <video src={videoUrl}></video>
                      </div>
                    </li>
                  ))}
                  {!isRepost && (
                    <li className="upload-content-block">
                      <svg
                        id="editIcon"
                        className="edit-profile"
                        xmlns="http://www.w3.org/2000/svg"
                        width="17.023"
                        height="17.023"
                        viewBox="0 0 17.023 17.023"
                      >
                        <path
                          id="Path_18515"
                          data-name="Path 18515"
                          d="M15.057,9.225l-1.34-1.34L4.895,16.709v1.34h1.34Zm1.34-1.34,1.34-1.34L16.4,5.206l-1.34,1.34ZM7.019,19.943H3V15.924L15.727,3.2a.947.947,0,0,1,1.34,0l2.679,2.679a.947.947,0,0,1,0,1.34Z"
                          transform="translate(-3 -2.919)"
                          fill="#E95F2A"
                        />
                      </svg>
                      <input
                        type="file"
                        name=""
                        className="file-upload-btn-input dropzone"
                        accept=".mp4,.m3u8"
                        onChange={handleFileUploadChange}
                      />
                    </li>
                  )}
                </ul>
              </form>
            </div>

            <button className="btn_sound btn_transparent" onClick={() => togglePanel('sound')}>
              {formData.soundId ? (
                <div>{formData.soundName}</div>
              ) : (
                <>
                  <SoundIcon />
                  {translate('quickplay.label-tag-sound')}
                </>
              )}
            </button>
          </div>

          <div className="content-sec">
            <h3>{translate('quickplay.details-heading')}</h3>
            <form className="flex flex-col gap-[3rem]" onSubmit={handlePostSubmit}>
              <Input
                type="text"
                name="title"
                placeholder={translate('quickplay.add-title-placeholder')}
                maxLength={80}
                value={formData.title}
                onChange={handleInputChange}
                error={errors.title}
              />
              <Input
                type="text"
                rows={1}
                maxLength={500}
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                placeholder={translate('quickplay.add-desc-placeholder')}
                error={errors.description}
              />
              <InputWithChips
                type="text"
                name="taggedUsers"
                value={formData.taggedUsers.reduce((acc, item: any, index) => `${acc}${index !== 0 ? ',' : ''} @${item.subTitle}`, '')}
                values={formData.taggedUsers}
                onRemoveValue={handleRemoveUser}
                onClick={() => togglePanel('user')}
                placeholder={translate('quickplay.label-tag-friends')}
              />
              <InputWithChips
                type="text"
                name="hashtags"
                value={formData.hashtags.reduce((acc, item: any, index) => `${acc}${index !== 0 ? ',' : ''} #${item.title}`, '')}
                values={formData.hashtags}
                onRemoveValue={handleRemoveHashtag}
                onClick={() => togglePanel('hashtag')}
                placeholder={translate('quickplay.label-add-hashtags')}
              />

              <div className="input-grp">
                {/* Comments Action */}
                <div className={`toggle-container ${isRepost ? 'opacity-50 cursor-not-allowed' : ''}`}>
                  <h4 className="toggle-title">{translate('quickplay.label-post-comment')}</h4>
                  <label className="toggle-switch">
                    <input type="checkbox" checked={formData.commentEnabled} onChange={commentToggleHandler} disabled={isRepost} />
                    <span className={`slider round ${isRepost ? 'cursor-not-allowed' : ''}`}></span>
                  </label>
                </div>

                {/* Share Action */}
                <div className={`toggle-container ${isRepost ? 'opacity-50 cursor-not-allowed' : ''}`}>
                  <h4 className="toggle-title">{translate('quickplay.label-post-share')}</h4>
                  <label className="toggle-switch">
                    <input type="checkbox" checked={formData.shareEnabled} onChange={shareToggleHandler} disabled={isRepost} />
                    <span className={`slider round ${isRepost ? 'cursor-not-allowed' : ''}`}></span>
                  </label>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <footer className="flex items-center justify-end px-20 md:px-64 lg:px-120 2K:px-160 4K:px-320 8K:px-655 stepTwoFooter">
        <div className="flex items-center justify-between">
          <button
            type="submit"
            className="flex items-center justify-center gap-2 new-custom-btn-b upload-content disabled:opacity-50"
            disabled={isLoading}
            onClick={handlePostSubmit}
          >
            {isLoading && <LoaderDark />}
            {translate(isRepost ? 'quickplay.btn-upload-repost' : 'quickplay.label-post-bits')}
          </button>
        </div>
      </footer>

      {showTagPanel.sound && (
        <SidePanel
          title={translate('quickplay.label-sound') ?? ''}
          open={showTagPanel.sound}
          onClose={() => togglePanel('sound')}
          fetchOptions={{
            itemKeys: {
              id: 'soundId',
              thumbnail: 'thumbnail',
              title: 'title',
              subTitle: 'artist',
            },
          }}
          handleTag={(sound: any) =>
            setFormData((prev: any) => ({
              ...prev,
              sounds: [...prev.sounds, sound],
              soundId: sound.soundId,
              soundName: sound.title,
            }))
          }
          handleRemove={(sound: any) => {
            const indexToRemove = formData.sounds.findIndex((taggedSound: any) => taggedSound.id === sound.id);
            if (indexToRemove !== -1) {
              formData.sounds.splice(indexToRemove, 1);
            }
            setFormData((prev: any) => ({
              ...prev,
              sounds: [...formData.sounds],
              soundId: null,
              soundName: null,
            }));
          }}
          taggedItems={formData.sounds}
        />
      )}

      {showTagPanel.hashtag && (
        <SidePanel
          title={translate('quickplay.label-hashtag') ?? ''}
          open={showTagPanel.hashtag}
          onClose={() => togglePanel('hashtag')}
          fetchOptions={{
            itemKeys: {
              id: 'hashtagId',
              thumbnail: 'image',
              title: 'title',
              subTitle: 'status',
            },
          }}
          handleTag={(hashtag: any) =>
            setFormData((prev: any) => ({
              ...prev,
              hashtags: [...prev.hashtags, hashtag],
            }))
          }
          handleRemove={handleRemoveHashtag}
          taggedItems={formData.hashtags}
          multiple
        />
      )}

      {showTagPanel.user && (
        <SidePanel
          title={translate('quickplay.label-friend') ?? ''}
          open={showTagPanel.user}
          onClose={() => togglePanel('user')}
          fetchOptions={{
            itemKeys: {
              id: 'userId',
              thumbnail: 'profileImageUrl',
              title: 'fullName',
              subTitle: 'userName',
            },
          }}
          handleTag={(user: any) =>
            setFormData((prev: any) => ({
              ...prev,
              taggedUsers: [...prev.taggedUsers, user],
            }))
          }
          handleRemove={handleRemoveUser}
          taggedItems={formData.taggedUsers}
          multiple
        />
      )}

      <CustomModal isOpen={show} close={handleClose}>
        <h2 className="modal-titl">Post Setting Info</h2>
        <p>If you disable the like, comment, share and download toggle then the user can't access these features.</p>
      </CustomModal>
    </>
  );
}

export default PostCreateStepTwo;
