import { IconNames, getSvgXml } from '@fe-monorepo/helper';
import { WebIconNames, getWebSvgXml } from 'apps/fe-web/src/lib/webIcons';
import { forwardRef } from 'react';

export interface IAppProps {
  name?: IconNames;
  webName?: WebIconNames;
  className?: string;
  iconClasses?: string;
  width?: number;
  height?: number;
  fill?: string;
  srAltText?: string;
  onClick?: () => void;
  onTouchStart?: (e: React.TouchEvent<HTMLDivElement>) => void;
}

const Icon = forwardRef<HTMLDivElement, IAppProps>((props, ref) => {
  return (
    <div ref={ref} className={props?.className} onClick={props?.onClick}>
      {Boolean(props.webName)
        ? getWebSvgXml({
            name: props?.webName,
            platform: 'web',
            height: props?.height,
            width: props?.width,
            fill: props?.fill,
            iconClasses: props?.iconClasses,
          })
        : getSvgXml({
            name: props.name,
            platform: 'web',
            height: props?.height,
            width: props?.width,
            fill: props?.fill,
            iconClasses: props?.iconClasses,
          })}
      {props?.srAltText && props.srAltText.length > 0 && <span className="sr-only">{props.srAltText}</span>}
    </div>
  );
});

export default Icon;
